import { useState, useRef, useEffect } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import DetectableOverflow from 'react-detectable-overflow';
import { useTheme } from '@emotion/react';
import { Icon } from '@iconify/react';
import folderOpenIcon from '@iconify/icons-material-symbols/folder-open';
import folderIcon from '@iconify/icons-material-symbols/folder';
import documentRemoveIcon from '@iconify/icons-mdi/file-document-remove';
import {checkTypeFile} from "./ReactFlow/utils/checkTypeFile"
// "mdi:file-document-remove"

import { baseURLFile } from '../service/api';
import { checkIfFileIsImage } from '../utils/checkIfFileIsImage';

export default function RepositoryItem({
  index,
  setRepositoryFilesContextMenu,
  item,
  moveDirectoryHandler,
  directory,
  setDirectory,
  setNavigatedDirectory,
  isSearchingItems,
  setFilterItems,
  contextMenuItemPosition,
  setContextMenuItemPosition,
  selectedItems,
  setSelectedItems,
  itemsRef,
  iconsSize,
  loggedUser,
  isAdmin,
  openModalHandler,
}) {
  // eslint-disable-next-line no-unused-vars
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const itemRef = useRef(null);

  const theme = useTheme();

  const ICON_SIZE = iconsSize;

  useEffect(() => {
    const arrayOfSelectedItemsNames = () => {
      const arrayOfNames = [];
      selectedItems.forEach((selectedItem) => arrayOfNames.push(selectedItem.name));
      return arrayOfNames.includes(item.name);
    };
    setIsSelected(arrayOfSelectedItemsNames());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  // eslint-disable-next-line no-unused-vars
  const [collected, drag, dragPreview] = useDrag({
    type: item?.type,
    item,
    canDrag: isAdmin,
  });

  // eslint-disable-next-line no-unused-vars
  const [{ isOver }, drop] = useDrop({
    accept: item?.type === 'file' ? '' : ['file', 'directory'],
    drop: (droppedItem) => {
      moveDirectoryHandler(item, droppedItem);
    },
  });

  const defineItemIcon = (fileObj) => {
    let icon;
    if (fileObj.type === 'directory') {
      if (item.isEmpty === false) {
        icon = <Icon icon={folderOpenIcon} height={ICON_SIZE.height} width={ICON_SIZE.width} />;
      } else {
        icon = <Icon height={ICON_SIZE.height} width={ICON_SIZE.width} icon={folderIcon} />;
      }
    }
    if (fileObj.type === 'file') {
      icon = (
        <img
          src={`${checkTypeFile(fileObj.path)}`}
          alt={fileObj.filename}
          height={ICON_SIZE.height}
          style={{ objectFit: 'cover' }}
        />
      );;

      if (fileObj.status === 'O')
        icon = <Icon height={ICON_SIZE.height} width={ICON_SIZE.width} icon={documentRemoveIcon} />;
      if (checkIfFileIsImage(fileObj.extension)) {
        icon = (
          <img
            src={`${baseURLFile}/${fileObj.path}`}
            alt={fileObj.filename}
            height={ICON_SIZE.height}
            style={{ objectFit: 'cover' }}
          />
        );
      }
    }
    return icon;
  };

  const onSingleClickHandler = () => {
    if (!isSelected) {
      if (!isAdmin && (item.status === 'O' || item.status === 'P')) return;
      if (!isAdmin && (item.status !== 'O' || item.status !== 'P'))
        setSelectedItems((prevState) => [...prevState, item]);
      if (isAdmin) setSelectedItems((prevState) => [...prevState, item]);
    }

    if (isSelected)
      setSelectedItems((prevState) => prevState.filter((selectedItem) => selectedItem.name !== item.name));
  };

  const onDoubleClickHandler = () => {
    if (item.type === 'file') {
      openModalHandler(item.pathpdf, item.filename, String(item.id), item.status);
    }

    if (item.type === 'directory') {
      setFilterItems('');
      setDirectory(item.path);
      setNavigatedDirectory((prevState) => [...prevState, item.path]);
    }
  };

  // Removove a seleção de itens caso o menu de contexto seja desabilitado
  useEffect(() => {
    if (!contextMenuItemPosition) setIsSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenuItemPosition]);

  const checkPathAndPrivacy = (currentItem, settedPath, userId) => {
    const splittedSettedPath = settedPath?.split('/');
    const splittedPathItem = currentItem?.path.split('/');

    if (isSearchingItems) {
      if (splittedPathItem.at(splittedSettedPath.length - 1) === splittedSettedPath.at(-1)) {
        if (currentItem.privacidade) {
          if (currentItem.privacidade.userslinked.length === 0) return true;
          if (!currentItem.privacidade?.userslinked?.includes(userId)) return false;
        }
        return true;
      }
    }

    if (
      splittedSettedPath?.length + 1 === splittedPathItem?.length &&
      splittedSettedPath?.at(-1) === splittedPathItem?.at(splittedPathItem?.length - 2)
    ) {
      if (currentItem.privacidade) {
        if (currentItem.privacidade.userslinked.length === 0) return true;
        if (!currentItem.privacidade?.userslinked?.includes(userId)) return false;
      }
      return true;
    }
    return false;
  };

  const onContextMenuHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isAdmin && (item.status === 'O' || item.status === 'P')) return;
    if (!isSelected) {
      setSelectedItems([]);
      setSelectedItems([item]);
    }
    setRepositoryFilesContextMenu(null);
    openContextMenuHandler(event);
  };

  const openContextMenuHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const viewBounds = itemRef.current.parentElement.parentElement.parentElement.getBoundingClientRect();

    const x = event.clientX - viewBounds.left;
    const y = event.clientY - viewBounds.top;

    setContextMenuItemPosition({ x, y });

    setRepositoryFilesContextMenu(null);
  };

  return (
    <Box
      onContextMenu={(event) => onContextMenuHandler(event)}
      ref={(node) => {
        itemRef.current = node;
        itemsRef.current[index] = node;
      }}
      sx={{ display: !checkPathAndPrivacy(item, directory, loggedUser) && 'none' }}
    >
      <Button
        sx={{
          display: !checkPathAndPrivacy(item, directory, loggedUser) && 'none',
          justifyContent: 'start',
          flexDirection: 'column',
          height: 170,
          width: 147.25,
          '& .MuiButton-startIcon': { marginRight: '0px', marginLeft: '0px' },
          '&:hover': {
            backgroundColor: isSelected && theme.palette.grey[400],
          },
          backgroundColor: isSelected && theme.palette.grey[400],
          opacity: (item.status === 'O' || item.status === 'P') && 0.55,
          [theme.breakpoints.down(1920)]: {
            height: 155,
            width: 134.03,
          },
          [theme.breakpoints.down(1745)]: {
            height: 145,
            width: 125.38,
          },
          [theme.breakpoints.down(1536)]: {
            height: 135,
            width: 125.38,
          },
        }}
        ref={(node) => drag(drop(node))}
        disableRipple
        startIcon={defineItemIcon(item)}
        color="greyButton"
        onClick={onSingleClickHandler}
        onDoubleClick={onDoubleClickHandler}
      >
        <Tooltip title={isOverflowing && (item.type === 'file' ? item.filename : item.name)}>
          <Typography
            component="span"
            sx={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              textTransform: 'none',
              textDecoration: item.status === 'O' && 'line-through',
            }}
          >
            <DetectableOverflow onChange={() => setIsOverflowing(true)}>
              {item.type === 'file' ? item.filename : item.name}
            </DetectableOverflow>
          </Typography>
        </Tooltip>
      </Button>
    </Box>
  );
}
