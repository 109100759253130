import i18next from 'i18next';
import api from '../../../service/api';
import managementNode, { moduleProperties } from './functionsManagementNode';
import { propertiesStandardForm } from '../creation/components/Modal/propertiesStandardForm';

const saveInitialModule = async (idprojeto, initialNodes) => {
  const node = initialNodes.find((nds) => nds.type === 'customnode');
  const module = {
    idprojeto,
    posx: node.position.x,
    posy: node.position.y,
    stageid: node.parentNode,
    nodeid: node.id,
    category: 'CONTROL',
    type: 'INICIAL',
    modulotxt: node.modulotxt,
    modulodesc: node.modulodesc,
    properties: node.properties,
    alertalista: node.alertalista,
  };

  await api.post(`modules`, module);
};

const saveModule = async (idprojeto, node) => {
  const module = {
    idprojeto,
    posx: node.position.x,
    posy: node.position.y,
    stageid: node.parentNode,
    nodeid: node.id,
    category: node.categoryNode,
    type: node.typeNode,
    modulotxt: node.modulotxt,
    modulodesc: node.modulodesc,
    properties: node.properties,
    alertalista: node.alertalista,
    privacidade: node.privacidade,
    tempolimite: node.tempolimite,
    status: node.status,
  };

  await api.post(`modules`, module);
};

const copyModule = async (idprojeto, idmodulecurrent, idmoduletocopy) => {
  const body = {
    idprojeto,
    idmodulecurrent,
    idmoduletocopy,
  };

  await api.put(`modules/copy`, body);
};

const updateModule = async (idprojeto, node) => {
  const module = {
    idprojeto,
    posx: node.position.x,
    posy: node.position.y,
    stageid: node.parentNode,
    nodeid: node.id,
    category: node.categoryNode,
    type: node.typeNode,
    modulotxt: node.modulotxt,
    modulodesc: node.modulodesc,
    properties: node.properties,
    alertalista: node.alertalista,
    tempolimite: node.tempolimite,
    status: node.status || 0,
  };
  if (node.typeNode === 'FORM') {
    module.form = JSON.stringify(node.form);
  }

  if (node.typeNode === 'INICIAL') {
    const props = node.properties;

    if (props !== null && props !== undefined && props.FORM.standard === 'ON') {
      if (node.form) {
        module.form = JSON.stringify(node.form);
      } else {
        module.form = JSON.stringify(propertiesStandardForm);
      }
    } else {
      module.form = null;
    }
  }

  if (node.categoryNode === 'ACAO') {
    module.moduloresp = node.moduloresponsaveis;
    module.privacidade = node.privacidade;
  }

  await api.put(`modules`, module);
};

const deleteModule = async (nodeid, idprojeto, edgesDeleted) => {
  const edges = JSON.stringify(edgesDeleted);
  await api.delete(`modules/${nodeid}/${idprojeto}/${edges}`);
};

const saveEdge = async (idprojeto, edge) => {
  edge.idprojeto = idprojeto;

  await api.post(`modulesrelation`, edge);
};

const deleteEdge = async (idprojeto, edge) => {
  edge.idprojeto = idprojeto;

  await api.delete(`modulesrelation`, { data: edge });
};

const findAllModules = async (idprojeto, drag) => {
  const response = await api.get(`modules/${idprojeto}`);
  const { modules } = response.data.data;
  const nodes = modules.map((module) => {
    const type = moduleProperties.find((element) => element.typeBack === module.modulotipo);
    if (module.modulonodeid === 'a' && module.moduloexecutor !== null && module.moduloexecutor !== undefined) {
      const node = managementNode.createNode(
        module.modulonodeid,
        { x: module.moduloposx, y: module.moduloposy },
        {
          label: 'Inicio do projeto',
          imagem: type.img,
          status: module.modulostatus,
          executer: module.moduloexecutor.usuarionome,
          executerIcon: module.moduloexecutor.usuarioicon,
          dateExecuted: module.moduloexecutor.moduloacaodata,
          name: module.modulotxt,
          category: module.modulocategoria,
          type: module.modulotipo,
        },
        `${module.moduloestagio}`,
        module.modulocategoria,
        module.modulotipo,
        module.modulotxt,
        module.modulodesc,
        module.modulopropriedades,
        module.moduloalertalista,
        module.modulotempolimite,
        drag,
        module.modulostatus,
        module.moduloresponsavel
      );
      return node;
    }
    if (module.moduloexecutor !== null && module.moduloexecutor !== undefined) {
      const node = managementNode.createNode(
        module.modulonodeid,
        { x: module.moduloposx, y: module.moduloposy },

        {
          label: null,
          imagem: type.img,
          status: module.modulostatus,
          executer: module.moduloexecutor.usuarionome,
          executerIcon: module.moduloexecutor.usuarioicon,
          dateExecuted: module.moduloexecutor.moduloacaodata,
          returnNode: module.modulosetaretorno,
          name: module.modulotxt,
          category: module.modulocategoria,
          type: module.modulotipo,
        },
        `${module.moduloestagio}`,
        module.modulocategoria,
        module.modulotipo,
        module.modulotxt,
        module.modulodesc,
        module.modulopropriedades,
        module.moduloalertalista,
        module.modulotempolimite,
        drag,
        module.modulostatus,
        module.moduloresponsavel
      );
      return node;
    }
    const node = managementNode.createNode(
      module.modulonodeid,
      { x: module.moduloposx, y: module.moduloposy },
      {
        label: null,
        imagem: type.img,
        status: module.modulostatus,
        returnNode: module.modulosetaretorno,
        name: module.modulotxt,
      },
      `${module.moduloestagio}`,
      module.modulocategoria,
      module.modulotipo,
      module.modulotxt,
      module.modulodesc,
      module.modulopropriedades,
      module.moduloalertalista,
      module.modulotempolimite,
      drag,
      module.modulostatus,
      module.moduloresponsavel
    );
    return node;
  });
  let idStage = 0;
  let xPosition = 120;
  let stage;
  const stages = nodes
    .map((node, _, nodes) => {
      if (node.parentNode === String(idStage)) {
        if (node.parentNode === '0') {
          stage = managementNode.createStage(
            node.parentNode,
            { x: 0, y: 0 },
            { label: `${i18next.t('stages-Flow.stageInit')}` },
            { width: 100, height: 150 }
          );
          idStage += 1;
        } else {
          stage = managementNode.createStage(
            node.parentNode,
            { x: xPosition, y: 0 },
            { label: `${i18next.t('stages-Flow.stage')} ${idStage}` },
            { width: 100, height: 150 }
          );
          xPosition += 120;
          idStage += 1;
          const customNodesInGroup = nodes.filter((custom) => custom.parentNode === node.parentNode);
          if (customNodesInGroup.length > 2) {
            const alturaPlus = customNodesInGroup.length + 1;
            const novaAltura = alturaPlus * 60;
            stage.style.height = novaAltura;
          }
        }
        return stage;
      }
      return undefined;
    })
    .filter((element) => element !== undefined);
  const nodesInStages = stages.concat(nodes);
  return nodesInStages;
};

const findAllEdges = async (idprojeto) => {
  const response = await api.get(`modulesrelation/${idprojeto}`);
  const { edges } = response.data.data;
  return edges;
};

const integrationsAPI = {
  saveInitialModule,
  saveModule,
  saveEdge,
  findAllModules,
  findAllEdges,
  updateModule,
  deleteEdge,
  deleteModule,
  copyModule,
};

export default integrationsAPI;
