import { useState, useEffect } from 'react';
// @mui
import { Typography, Stack, Button, Container, Box } from '@mui/material';
import { toast } from 'react-hot-toast';
// components
import { useTranslation } from 'react-i18next';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import Page from '../components/Page';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// sections
import { AppNewsAllUpdate } from '../sections/@dashboard/app';
// api
import api from '../service/api';
import Scrollbar from '../components/Scrollbar';

export default function News() {
  // const { t } = useTranslation();
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const [listNews, setListNews] = useState([]);

  const [pageatual, setPageAtual] = useState(0);

  const [visibleButtonMaisNoticias, setVisibleButtonMaisNoticias] = useState(false);

  const [newsLoading, setNewsLoading] = useState(true);

  const { t } = useTranslation();

  async function requestNews() {
    try {
      const qtd = 5;
      const response = await api.get(`/projectsnewspaginado/${qtd}/${pageatual}`);
      // eslint-disable-next-line prefer-destructuring
      if (response.status === 200) {
        if (listNews.length === 0) {
          const news = response.data.data.counterProjectsLastNews;
          setListNews(news);
          setPageAtual(pageatual + qtd);
          setNewsLoading(false);
        } else {
          const news1 = response.data.data.counterProjectsLastNews;
          if (news1.length > 0) {
            const news = [...listNews, ...news1];
            setListNews(news);
            setPageAtual(pageatual + qtd);
            if (news1.length < qtd) setVisibleButtonMaisNoticias(true);
          } else {
            setVisibleButtonMaisNoticias(true);
          }
          setNewsLoading(false);
        }
      } else {
        console.log('erro');
        setNewsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setNewsLoading(false);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  useEffect(() => {
    requestNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setListNews]);

  return (
    <Scrollbar>
      <Page title="News" sx={{ height: '100vh' }}>
        <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
        <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} navigateBack={'/'} />
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 8,
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ zIndex: 10, marginBottom: 3 }}>
              {t('news.heading')}
            </Typography>
          </Stack>
          <Box sx={{ width: '100%' }}>
            <Box xs={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AppNewsAllUpdate
                newsLoading={newsLoading}
                list={listNews}
                sx={{ minWidth: 770, maxWidth: 770, display: 'flex', justifyContent: 'center' }}
              />
            </Box>

            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: 2 }}>
              {!newsLoading && (
                <Button
                  variant="contained"
                  to="#"
                  // startIcon={<Icon icon={plusIcon} />}
                  onClick={() => requestNews()}
                  sx={{ alignSelf: 'center' }}
                  align="center"
                  disabled={visibleButtonMaisNoticias}
                >
                  {t('news.more-news')}
                </Button>
              )}
            </Box>
          </Box>
        </Container>
      </Page>
    </Scrollbar>
  );
}
