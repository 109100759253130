import React, { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Icon } from '@iconify/react';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import { toast } from 'react-hot-toast';

import { NodeSelectTimeline } from '../BasicModalTimeline';
import api from '../../../../../service/api';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#161C24' : '#DCDCD0',
  color: theme.palette.text.secondary,
}));
const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#161C24' : '#DCDCD0',
  color: theme.palette.text.secondary,
}));
const DialogActionStyled = styled(DialogActions)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#161C24' : '#DCDCD0',
  color: theme.palette.text.secondary,
}));

const emailSchema = yup.string().email().required();

export default function ModalFormulario({ fieldDisable }) {
  const [open, setOpen] = useState(false);
  const [arrPropertiesForm, setArrPropertiesForm] = React.useState([]);
  const nodeSelectTimeline = useContext(NodeSelectTimeline);
  const { node, idprojeto } = nodeSelectTimeline;

  useEffect(() => {
    const loadForm = async () => {
      const responseProject = await api.get(`modules/form/${node.id}/${idprojeto}`);
      const { form } = responseProject.data.data;
      if (form === null) {
        //
      } else {
        const arrForm = JSON.parse(form.form);
        arrForm.sort((a, b) => a.position - b.position);
        setArrPropertiesForm(arrForm);
      }
    };

    loadForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadValueAnswer = (field) => {
    if (field.answer !== null && field.answer !== undefined && field.answer !== '') {
      return field.answer;
    }
    return '';
  };

  const handleChangeTextBox = (event) => {
    const { id } = event.target;
    const newPropertiesArr = arrPropertiesForm.map((prop) => {
      if (id === prop.id) {
        prop.answer = event.target.value;
      }
      return prop;
    });
    setArrPropertiesForm(newPropertiesArr);
  };

  const loadValueOption = (prop) => {
    if (prop.answer === null || prop.answer === undefined) return '';
    return prop.answer;
  };

  const loadValueOptions = (prop) => {
    if (prop.answer === null) return [];
    return prop.answer;
  };

  const handleChangeOption = async (event) => {
    const {
      target: { value, name },
    } = event;

    const newPropertiesArr = await Promise.all(
      arrPropertiesForm.map((prop) => {
        if (name === prop.id) {
          prop.answer = value;
        }
        return prop;
      })
    );
    setArrPropertiesForm(newPropertiesArr);
  };

  const textbox = (field) => field && (
    <Card key={`card-textbox-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-textbox-${field.id}`}
          id={field.id}
          label={i18next.t('flow-Modals-form.answer')}
          fullWidth
          margin="dense"
          required={field.required[0]}
          value={loadValueAnswer(field)}
          onChange={handleChangeTextBox}
          disabled={fieldDisable || false}
        />
      </CardContent>
    </Card>
  );
  const textboxmultiline = (field) => field && (
    <Card key={`card-textboxmultiline-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-textboxmultiline-${field.id}`}
          id={field.id}
          label={i18next.t('flow-Modals-form.answer')}
          fullWidth
          margin="dense"
          required={field.required[0]}
          multiline
          rows={4}
          value={loadValueAnswer(field)}
          onChange={handleChangeTextBox}
          disabled={fieldDisable || false}
        />
      </CardContent>
    </Card>
  );
  const multiselect = (field) => field && (
    <Card key={`card-multiselect-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <FormControl required={field.required[0]} sx={{ m: 1, width: "100%", height:"300px" }}>
          <InputLabel id="demo-multiple-name-label">{i18next.t('flow-Modals-form.optionsAnswer')}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id={field.id}
            name={field.id}
            multiple
            multiline
            key={`textfield-multiselect-${field.id}`}
            value={loadValueOptions(field)}
            onChange={handleChangeOption}
            input={<OutlinedInput label={i18next.t('flow-Modals-form.optionsAnswer')} />}
            MenuProps={MenuProps}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            disabled={fieldDisable || false}
          >
            {field?.options[0]?.map((option) => (
              <MenuItem key={`position-${option}`} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
  const combobox = (field) => field && (
    <Card key={`card-combobox-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <FormControl required={field.required[0]} sx={{ m: 1, width: "100%" }}>
          <InputLabel id="demo-multiple-name-label">{i18next.t('flow-Modals-form.optionsAnswer')}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id={field.id}
            name={field.id}
            key={`textfield-combobox-${field.id}`}
            value={loadValueOption(field)}
            onChange={handleChangeOption}
            input={<OutlinedInput label={i18next.t('flow-Modals-form.optionsAnswer')} />}
            MenuProps={MenuProps}
            disabled={fieldDisable || false}
          >
            {field?.options[0].map((option) => (
              <MenuItem key={`position-${option}`} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
  const email = (field) => field && (
    <Card key={`card-email-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-email-${field.id}`}
          id={field.id}
          label={i18next.t('flow-Modals-form.answer')}
          fullWidth
          margin="dense"
          required={field.required[0]}
          type="email"
          value={loadValueAnswer(field)}
          onChange={handleChangeTextBox}
          disabled={fieldDisable || false}
        />
      </CardContent>
    </Card>
  );

  const date = (field) => field && (
    <Card key={`card-date-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-date-${field.id}`}
          id={field.id}
          label=""
          margin="dense"
          required={field.required[0]}
          type="date"
          value={loadValueAnswer(field)}
          onChange={handleChangeTextBox}
          disabled={fieldDisable || false}
        />
      </CardContent>
    </Card>
  );

  const filterField = (field) => {
    if (field.able) {
      if (field.type === 'textbox') return textbox(field);
      if (field.type === 'textboxmultiline') return textboxmultiline(field);
      if (field.type === 'multiselect') return multiselect(field);
      if (field.type === 'combobox') return combobox(field);
      if (field.type === 'email') return email(field);
      if (field.type === 'date') return date(field);
    }
    return null;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendSaveForm = async () => {
    let emailIsValid = false;
    const prefilledFields = arrPropertiesForm.filter((field) => field.able === true);
    const hasNullAnswer = prefilledFields.some((field) => field.answer === null && field.required[0] === true);
    const hasEmptyAnswer = prefilledFields.some((field) => field.answer === '' && field.required[0] === true);
    const hasEmptyemail = prefilledFields.some((field) => field.type === 'email' && field.required[0] === true);
    const hasEmptyemailNotRequired = prefilledFields.some((field) => field.type === 'email');
    const hasEmail = prefilledFields.some((field) => field.type === 'email');

    // eslint-disable-next-line no-restricted-syntax
    for (const field of prefilledFields) {
      if (field.answer !== null && field.type === 'email' && field.answer.length > 0) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await emailSchema.validate(field.answer);
          emailIsValid = true;
        } catch (err) {
          toast.error(`${i18next.t('flow-Modals-form-toast.emailNotValid')}`);
          emailIsValid = false;
          console.log(err);
        }
      } else if (!hasEmptyemail && (field.answer === null || field.answer === '' || !hasEmail)) {
        emailIsValid = true;
      } else if ((!hasEmptyemail && field.answer.length === 0) || !hasEmail) {
        emailIsValid = true;
      }
    }

    if (hasNullAnswer || hasEmptyAnswer) {
      toast.error(`${i18next.t('flow-Modals-form-toast.emptyAnswer')}`);
    }

    if (emailIsValid && (hasEmptyemail || hasEmptyemailNotRequired || !hasEmail) && !hasNullAnswer && !hasEmptyAnswer) {
      node.form = arrPropertiesForm;
      await integrationsAPI.updateModule(idprojeto, node);
      setOpen(false);
      toast.success(`${i18next.t('flow-Modals-form-toast.success')}`);
    }
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-form.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={fileTextFill} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitleStyled>{i18next.t('flow-Modals-form.fillForm')}</DialogTitleStyled>
          <DialogContentStyled>
            {arrPropertiesForm.map((field) => filterField(field))}
            {arrPropertiesForm.length <= 0 && (
              <Typography variant="h3">{i18next.t('flow-Modals-form.emptyForm')}</Typography>
            )}
          </DialogContentStyled>
          <DialogActionStyled>
            <Button variant="contained" onClick={handleClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            {arrPropertiesForm.length > 0 && !fieldDisable && (
              <Button variant="contained" onClick={handleSendSaveForm} color="success">
                {i18next.t('flow-Modals-form.send')}
              </Button>
            )}
          </DialogActionStyled>
        </>
      </Dialog>
    </Box>
  );
}
