import { toast } from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';

import { Grid, Modal, Typography, Box, Button, Stack, TextField, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-circle-outline';
import { ProjectStatus } from '../../../../../enum/ProjectStatusEnum';
import { ModuleStatus } from '../../../../../enum/ModuleStatusEnum';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));

export const ConfirmApproveModal = ({
  isModalOpen,
  setModalOpen,
  moduleApproved,
  idprojeto,
  propertiesConfirm,
  onClose,
  nodesNotActionForChangeStatus,
}) => {
  const [argument, setArgument] = useState('');
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();


  const handleChangeStatus = async () => {
    setLoading(true);
    try {
      moduleApproved.status = ModuleStatus.executed;
      await integrationsAPI.updateModule(idprojeto, moduleApproved);

      if (nodesNotActionForChangeStatus.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const nodeNotAction of nodesNotActionForChangeStatus) {
          nodeNotAction.status = ModuleStatus.executed;
          await integrationsAPI.updateModule(idprojeto, nodeNotAction);
        }
      }
      setTimeout(() => {
        queryClient.invalidateQueries(['projectFlow', idprojeto]);
        setModalOpen(false);
        setLoading(false);
        onClose();
      }, 1500);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${i18next.t('flow-Modals-approve.conflict')}`);
        setTimeout(() => {
          queryClient.invalidateQueries(['projectFlow', idprojeto]);
          setModalOpen(false);
          setLoading(false);
          onClose();
        }, 2000);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
        setTimeout(() => {
          queryClient.invalidateQueries(['projectFlow', idprojeto]);
          setModalOpen(false);
          setLoading(false);
          onClose();
        }, 2000);
      }
      console.log(error);
      setModalOpen(false);
      setLoading(false);
      onClose();
    }
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const textfieldCanceledProject = () => {
    if (ProjectStatus.Paused === 0) {
      return (
        <TextField
          key="textfield-canceled-project"
          id="textfield-canceled-project"
          label={i18next.t('flow-Modals-approve.args-cancel')}
          fullWidth
          margin="dense"
          required
          multiline
          rows={4}
          value={argument}
          onChange={(e) => setArgument(e.target.value)}
        />
      );
    }

    return null;
  };

  return (
    <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
      <GridStyle sx={{ position: 'relative', height: 'max-content' }}>
        <Typography variant="h3" align="center">
          {propertiesConfirm.title}
        </Typography>
        <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
          <IconButton size="large" onClick={() => closeModalHandler()}>
            <Icon icon={closeIcon} />
          </IconButton>
        </Box>
        <Typography variant="h5" align="center">
          {propertiesConfirm.content}
        </Typography>
        {textfieldCanceledProject()}
        {loading && <CircularProgress size={70} sx={{ position: 'absolute', top: '50%', left: '50%', zIndex: '99' }} />}

        <Stack sx={{ margin: '5% auto ', flexDirection: 'row', justifyContent: 'center', gap: '3rem' }}>
          <Button onClick={closeModalHandler} variant="contained">
            {i18next.t('flow-Modals.exit')}
          </Button>
          <Button variant="contained" onClick={handleChangeStatus} color="success">
            {i18next.t('flow-Modals.confirm-button')}
          </Button>
        </Stack>
      </GridStyle>
    </ModalStyle>
  );
};
