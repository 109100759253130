import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import i18next from 'i18next';
import { Icon } from '@iconify/react';
import quickReference from '@iconify/icons-material-symbols/quick-reference';
import { Typography, CardMedia } from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import toast from 'react-hot-toast';
import { moduleProperties } from '../../../utils/functionsManagementNode';
import { NodeSelect } from '../BasicModal';
import api from '../../../../../service/api';
import ModalConfigReferencia from './ModalConfigReferencia';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';

export default function ModalReferencia() {
  const [open, setOpen] = useState(false);
  const [prevNodes, setPrevNodes] = useState([]);
  const [nodeAnnexSelected, setNodeAnnexSelected] = useState([]);
  const nodeSelect = useContext(NodeSelect);
  const { nodeSelectCheck, idprojeto } = nodeSelect;
  const emptyArray = [];
  const cardRef = useRef([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setNodeAnnexSelected([...emptyArray]);
    setOpen(false);
  };

  const loadArryNodesHasFile = async () => {
    const response = await api.get(`modulesreference/${nodeSelectCheck.id}/${idprojeto}`);
    setPrevNodes(response.data.data.modules);
  };

  const nodesSelected = () => {
    if (nodeSelectCheck.properties !== null && nodeSelectCheck.properties !== undefined) {
      if (nodeSelectCheck.properties.REFS !== undefined && nodeSelectCheck.properties.REFS !== null) {
        setNodeAnnexSelected(nodeSelectCheck.properties.REFS);
      }
    }
  };

  useEffect(() => {
    loadArryNodesHasFile();
    nodesSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSelectCheck, open]);

  const checkFileIsSelected = (module) => nodeAnnexSelected.some((nds) => Number(nds) === Number(module.modulonodeid));

  const checkNodeisSelected = (node) => {
    if (checkFileIsSelected(node)) return '3px solid #FECD0E';
    return 'nonde';
  };

  const selectModules = (node, i, event) => {
    const tagHtml = event.target.tagName;
    const newArrayFileSelected = [...nodeAnnexSelected];
    const clickedOnModal = cardRef.current[i].contains(event.target);

    if (
      !checkFileIsSelected(node) &&
      clickedOnModal &&
      tagHtml !== 'BUTTON' &&
      tagHtml !== 'path' &&
      tagHtml !== 'svg'
    ) {
      setNodeAnnexSelected((array) => array.concat(node.modulonodeid));
    }

    if (
      checkFileIsSelected(node) &&
      clickedOnModal &&
      tagHtml !== 'BUTTON' &&
      tagHtml !== 'path' &&
      tagHtml !== 'svg'
    ) {
      const indexFile = newArrayFileSelected.filter((idnode) => Number(idnode) !== Number(node.modulonodeid));
      setNodeAnnexSelected(indexFile);
    }
  };

  const sendModulesRefs = async () => {
    nodeSelectCheck.properties = { ...nodeSelectCheck.properties, REFS: [...nodeAnnexSelected] };
    await integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
    toast.success(`${i18next.t('selectedFieldRef.refsConfigSuccess')}`);
    setOpen(false);
    handleClickClose();
  };
  const deleteModulesRefs = async () => {
    const emptyArray = [];
    nodeSelectCheck.properties = { FIELDS: [...emptyArray], REFS: [...emptyArray] };
    await integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
    toast.success(`${i18next.t('selectedFieldRef.deleteAllRef')}`);
    setOpen(false);
    handleClickClose();
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('selectedFieldRef.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={quickReference} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClickClose}
        open={open}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: '60%' }}
      >
        <>
          <IconButton
            onClick={handleClickClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('selectedFieldRef.title')}</DialogTitle>
          <DialogContent>
            <Typography variant="h4" sx={{ textAlign: 'center', marginTop: 1 }}>
              {i18next.t('selectedFieldRef.subTitle')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginTop: '1rem' }}>
              {prevNodes.map((node, i) => {
                cardRef.current[i] = React.createRef();
                return (
                  <Card
                    key={`node_ref-${i}`}
                    // eslint-disable-next-line no-return-assign
                    ref={(el) => (cardRef.current[i] = el)}
                    sx={{
                      width: '22%',
                      maxHeight: '100%',
                      border: checkNodeisSelected(node),
                    }}
                    onClick={(event) => selectModules(node, i, event)}
                  >
                    <Typography sx={{ textAlign: 'center' }} variant="h6">
                      {i18next.t('stages-Flow.stage')} {node.moduloestagio}
                    </Typography>
                    <CardMedia
                      sx={{ width: 50, height: 50, margin: '0 auto' }}
                      image={`${(() => moduleProperties.find((nds) => nds.typeBack === node.modulotipo).img)()}`}
                      alt={`icone do modulo de ${(() =>
                        moduleProperties.find((nds) => nds.typeBack === node.modulotipo).categ)()}`}
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        sx={{ width: '80%', fontWeight: 'bold' }}
                      >
                        {node.modulotxt}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {node.modulodesc === null || node.modulodesc.length <= 0
                          ? `${i18next.t('selectedFieldRef.notDescriptionNode')}`
                          : `${node.modulodesc.slice(0, 40)} ...`}
                      </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                      {node.modulotipo === 'FORM' && <ModalConfigReferencia module={prevNodes[i]} />}
                    </Box>
                  </Card>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                deleteModulesRefs();
              }}
              variant="contained"
              color="error"
            >
              {i18next.t('selectedFieldRef.deleteAllRefs')}
            </Button>
            <Button
              onClick={() => {
                sendModulesRefs();
              }}
              variant="contained"
              color="success"
            >
              {i18next.t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
