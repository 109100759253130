import { useState } from 'react';
import { Box, CardMedia, Typography, MenuItem, Menu } from '@mui/material';
import { baseURLFile } from '../../../../../service/api';
import { checkIfFileIsImage } from '../../../../../utils/checkIfFileIsImage';
import { checkTypeFile } from '../../../utils/checkTypeFile';

export default function AnnexWithDownload({ index, onDoubleClickHandler, imagem }) {
  const [contextMenu, setContextMenu] = useState(null);

  const downloadFile = (baseURLFile, fileObj) => {
    fetch(`${baseURLFile}/${fileObj.moduloanexocaminho}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileObj.moduloanexonome;
        alink.click();
      });
    });
  };
  const downloadFilePDF = (baseURLFile, fileObj) => {
    fetch(`${baseURLFile}/${fileObj.moduloanexocaminhopdf}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${fileObj.moduloanexonome.split('.')[0]}.pdf`;
        alink.click();
      });
    });
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Box
      key={`box-image${index}`}
      onContextMenu={handleContextMenu}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '15%',
        cursor: 'pointer',
      }}
    >
      <CardMedia
        key={`image-${index}`}
        component="img"
        height="140"
        sx={{ borderRadius: '5px', maxWidth: 140, opacity: imagem.moduloanexostatus === '0' ? 0.55 : 1 }}
        onDoubleClick={() => onDoubleClickHandler(imagem)}
        image={
          !checkIfFileIsImage(imagem.moduloanexocaminho)
            ? checkTypeFile(imagem.moduloanexocaminho)
            : `${`${baseURLFile}/${imagem.moduloanexocaminho}`}`
        }
        alt={imagem.moduloanexonome}
      />
      <Typography
        key={`checkbox-title-${index}`}
        sx={{
          overflowWrap: 'break-word',
          textAlign: 'center',
          maxWidth: 140,
          textDecoration: imagem.moduloanexostatus === '0' && 'line-through',
        }}
      >
        {imagem.moduloanexonome.replaceAll('_', '-')}
      </Typography>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem key={`${imagem.moduloanexocaminho}${index}`} onClick={() => downloadFile(baseURLFile, imagem)}>
          Download Original
        </MenuItem>
        {imagem.moduloanexocaminhopdf !== null && (
          <MenuItem key={`${imagem.moduloanexocaminhopdf}${index}`} onClick={() => downloadFilePDF(baseURLFile, imagem)}>
            Download Pdf
          </MenuItem>
        )}
        {/* <MenuItem onClick={handleClose}>Print</MenuItem> */}
      </Menu>
    </Box>
  );
}
