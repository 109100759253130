import React from 'react';
import i18next from 'i18next';
import actionsFlow from './functionsActionsFlow';
import integrationsAPI from './functionsIntegrationAPI';
import { moduleProperties } from './functionsManagementNode';
import BasicModal from '../creation/components/BasicModal';
import BasicModalType from '../creation/components/ModalFlowType';
import ModalConfirmDeleteNode from '../creation/components/ModalConfirmDeleteNode';
import { ProjectStatus } from '../../../enum/ProjectStatusEnum';

const nextStageExists = (nodeCurrent, nodes) => {
  const nextStage = nodes.find((node) => node.id === `${Number(nodeCurrent.parentNode) + 1}`);
  if (nextStage === undefined) return false;
  return true;
};

export const addModuleProps = (desc, name, args, props, responsaveis) => {
  const { nodeSelectCheck, setNodes, idprojeto } = args;
  nodeSelectCheck.modulodesc = desc;
  nodeSelectCheck.modulotxt = name;
  if (nodeSelectCheck.typeNode === 'UNIAO') {
    nodeSelectCheck.properties = props;
  }

  if (nodeSelectCheck.typeNode === 'INICIAL') {
    nodeSelectCheck.properties = props;
  }

  nodeSelectCheck.moduloresponsaveis = responsaveis;
  setNodes((nds) => nds.filter((nds) => nds.id !== nodeSelectCheck.id));
  setNodes((nds) => nds.concat(nodeSelectCheck));
  integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
};

const findLabelParent = (parentNode, nodes) => {
  const parentNodeFind = nodes.find((node) => node.id === parentNode);
  if (parentNodeFind !== undefined) return parentNodeFind.data.label;
  return '';
};

const information = (args) => {
  const { nodeSelectCheck, nodes } = args;
  return [
    {
      name: i18next.t('infos-flow.stage-node'),
      value: findLabelParent(nodeSelectCheck.parentNode, nodes),
    },
    {
      name: i18next.t('infos-flow.category-node'),
      value: (() => moduleProperties.find((node) => node.typeBack === nodeSelectCheck.typeNode).categ)(),
    },
    {
      name: i18next.t('infos-flow.module-type'),
      value: (() => moduleProperties.find((node) => node.typeBack === nodeSelectCheck.typeNode).type)(),
    },
  ];
};

const changeTypeNode = (type, args) => {
  const { nodeSelectCheck, setNodes, idprojeto } = args;
  nodeSelectCheck.typeNode = type.typeBack;
  nodeSelectCheck.categoryNode = type.categBack;
  nodeSelectCheck.data.imagem = type.img;
  setNodes((nds) => nds.filter((nds) => nds.id !== nodeSelectCheck.id));
  setNodes((nds) => nds.concat(nodeSelectCheck));
  integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
};

const getModalProperties = (args) => {
  const { nodeSelectCheck } = args;
  return nodeSelectCheck !== null && nodeSelectCheck.type !== 'customnodeGroup' ? (
    <BasicModal
      addModuleProps={addModuleProps}
      name={nodeSelectCheck.modulotxt}
      desc={nodeSelectCheck.modulodesc}
      modal={(() => moduleProperties.find((node) => node.typeBack === nodeSelectCheck.typeNode).modal)()}
      infos={information(args)}
      args={args}
    />
  ) : (
    <></>
  );
};
const getModalConfirmDelete = (args) => {
  const { nodeSelectCheck } = args;
  return nodeSelectCheck !== null && nodeSelectCheck.type !== 'customnodeGroup' ? (
    <ModalConfirmDeleteNode
      addModuleProps={addModuleProps}
      name={nodeSelectCheck.modulotxt}
      args={args}
    />
  ) : (
    <></>
  );
};

const getModalType = (args) => {
  const { nodeSelectCheck } = args;
  return nodeSelectCheck !== null && nodeSelectCheck.type !== 'customnodeGroup' ? (
    <BasicModalType
      tipo={nodeSelectCheck.typeNode}
      categoria={(() => moduleProperties.find((node) => node.typeBack === nodeSelectCheck.typeNode).categ)()}
      changeType={changeTypeNode}
      typeBack={(() => moduleProperties.find((node) => node.typeBack === nodeSelectCheck.typeNode).type)()}
      args={args}
    />
  ) : (
    <></>
  );
};

export const optionsMenuContext = (args) => {
  const { nodeSelectCheck, nodes, project } = args;

  const optionsMenu = [
    {
      menu: {
        name: 'nextModule',
        label: i18next.t('flow-options.nextModule'),
        effect: actionsFlow.updateNode,
        options: null,
        args,
      },
    },
    {
      menu: { name: 'prevModule', label: i18next.t('flow-options.prevModule'), effect: actionsFlow.createBack, options: null, args },
    },
    { menu: { name: 'connectionFree', label: i18next.t('flow-options.connetionFree'), effect: actionsFlow.connect, options: null, args } },
    { menu: { name: 'deleteModule', label:getModalConfirmDelete(args), effect: null, options: null, args } },
    // { menu: { name: 'deleteModule', label: i18next.t('flow-options.deleteModule'), effect: actionsFlow.deleteNode, options: null, args } },
    {
      menu: {
        name: 'optionsProperties',
        label: '',
        effect: null,
        options: [{ label: getModalProperties(args), effect: null }],
        args,
      },
    },
    {
      menu: {
        name: 'optionsPropertiesType',
        label: '',
        effect: null,
        options: [
          { label: getModalProperties(args), effect: null },
          { label: getModalType(args), effect: null },
        ],
        args,
      },
    },
  ];

  if (nodeSelectCheck === null ||
    project.projetostatus === ProjectStatus.Paused ||
    project.projetostatus === ProjectStatus.Canceled ||
    project.projetostatus === ProjectStatus.Complete
  ) {
    return [];
  }
  if (nodeSelectCheck.id === 'a' && nextStageExists(nodeSelectCheck, nodes)) {
    return optionsMenu
      .map((option) => {
        const { name } = option.menu;
        if (name !== 'optionsPropertiesType' && name !== 'deleteModule' && name !== 'prevModule') {
          return option.menu;
        }
        return undefined;
      })
      .filter((result) => result !== undefined);
  }

  if (nodeSelectCheck.id === 'a' && nodeSelectCheck.fakeNode !== undefined) {
    return optionsMenu
      .map((option) => {
        const { name } = option.menu;
        if (
          name !== 'optionsPropertiesType' &&
          name !== 'deleteModule' &&
          name !== 'prevModule' &&
          name !== 'connectionFree' &&
          name !== 'optionsProperties'
        ) {
          return option.menu;
        }
        return undefined;
      })
      .filter((result) => result !== undefined);
  }

  if (nodeSelectCheck.id === 'a') {
    return optionsMenu
      .map((option) => {
        const { name } = option.menu;
        if (
          name !== 'optionsPropertiesType' &&
          name !== 'deleteModule' &&
          name !== 'prevModule' &&
          name !== 'connectionFree'
        ) {
          return option.menu;
        }
        return undefined;
      })
      .filter((result) => result !== undefined);
  }

  if (nodeSelectCheck.type === 'customnode') {
    if (nextStageExists(nodeSelectCheck, nodes)) {
      return optionsMenu
        .map((option) => {
          const { name } = option.menu;
          if (name !== 'optionsProperties') {
            return option.menu;
          }
          return undefined;
        })
        .filter((result) => result !== undefined);
    }
    return optionsMenu
      .map((option) => {
        const { name } = option.menu;
        if (name !== 'optionsProperties' && name !== 'connectionFree') {
          return option.menu;
        }
        return undefined;
      })
      .filter((result) => result !== undefined);
  }
  return [];
};
