import { format } from 'date-fns';
import i18next from 'i18next';
import { toast } from 'react-hot-toast';
import api from '../service/api';
import { userStore } from '../store/userStore';

const selectedLanguage = () => {
  if (i18next.language === 'pt') return 'pt-BR';
  if (i18next.language === 'en') return 'en-US';
  if (i18next.language === 'es') return 'es-ES';
  return 'es-ES';
};

const selectKey = () =>  window.env.REACT_APP_ADOBE_PDF_API;

class ViewSDKClient {
  constructor() {
    this.alerts = [];
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId, viewerConfig, url, name, pdfId, readOnly) {
    const now = format(new Date(), 'T');
    const newURL = url.concat(`?hora=${now}`);

    const config = {
      clientId: selectKey(),
      locale: selectedLanguage(),
    };
    if (divId) {
      config.divId = divId;
    }
    this.adobeDCView = new window.AdobeDC.View(config);
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: newURL,
          },
        },
        metaData: {
          fileName: name,
          id: pdfId,
          hasReadOnlyAccess: readOnly,
          // hasReadOnlyAccess: isFromRepository,
        },
      },
      viewerConfig
    );
    return previewFilePromise;
  }

  registerSaveApiHandler(idModuleAnnex) {
    const postAlerts = async (alertObj) => {
      try {
        const alertResponse = await api.post('modulesanexo/annotations', alertObj);

        return alertResponse;
      } catch (error) {
        return error;
      }
    };

    const updatePDF = async (newPdf, idModuleAnnex) => {
      try {
        const formData = new FormData();
        formData.append('moduleanexopdf', newPdf);
        const responseUpdatePDF = await api.put(`/modulesanexo/${idModuleAnnex}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        return responseUpdatePDF;
      } catch (error) {
        return error;
      }
    };

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      (metaData, content) => {
        try {
          const unit8Array = new Uint8Array(content);
          const blob = new Blob([unit8Array], { type: 'application/pdf' });

          updatePDF(blob, idModuleAnnex);

          this.alerts.forEach(async (alert) => {
            await postAlerts(alert);
          });

          toast.success(i18next.t('adobe-embed-pdf-api.save-success'));
        } catch (error) {
          console.log(error);
          toast.error(i18next.t('adobe-embed-pdf-api.save-error'));
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            const response = {
              code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
              data: {
                metaData: Object.assign(metaData, {
                  updatedAt: new Date().getTime(),
                }),
              },
            };
            resolve(response);
          }, 2000);
        });
      },
      {}
    );
  }

  async setUserApiHandler() {
    // const currentUser = JSON.parse(localStorage.getItem('User'));
    const userPermissions = await userStore.getState().getUser();

    // const splittedUserName = currentUser.username.split(' ');
    const splittedUserName = userPermissions.usuarionome.split(' ');

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
      () =>
        new Promise((resolve) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              userProfile: {
                name: userPermissions.usuarionome,
                firstName: splittedUserName[0],
                lastName: splittedUserName[-1],
                email: userPermissions.usuarioemail,
              },
            },
          });
        }),
      {}
    );
  }

  registerEventsHandler(idModule, idModuleAnnex, idProject) {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (event) => {
        const alertObj = { idmodulo: idModule, idmoduloanexo: idModuleAnnex, idprojeto: idProject };
        if (event.data) {
          alertObj.comment = event.data.bodyValue;
        }
        if (event.type === 'ANNOTATION_ADDED') {
          alertObj.type = event.data.motivation;
        }
        if (event.type === 'ANNOTATION_DELETED') {
          alertObj.type = 'deleting';
        }
        if (event.type === 'ANNOTATION_UPDATED') {
          alertObj.type = 'updating';
        }
        if (alertObj.type) this.alerts = [...this.alerts, alertObj];
      },
      {
        enableAnnotationEvents: true,
      }
    );
  }
}
export default ViewSDKClient;
