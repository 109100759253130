import api from './api';

const validations = async (sessao) => {
  function getTokenLocalstorage() {
    return localStorage.getItem('token');
  }
  const token = getTokenLocalstorage() || [];
  const language = localStorage.getItem('i18nextLng');
    // eslint-disable-next-line dot-notation
    api.defaults.headers.common['Authorization'] = token;
    api.defaults.headers.common['Accept-Language'] = `${language}`;
    const response = await api.get(`/settings/${sessao}`);
    return response.data.data.settings;
};
export default validations;
