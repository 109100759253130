import * as React from 'react';
import i18next from 'i18next';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { style } from '@mui/system';
import { moduleProperties } from '../../utils/functionsManagementNode';

export default function BasicSelect({ tipo, changeType, args }) {
  // eslint-disable-next-line no-unused-vars
  const [type, setType] = React.useState(tipo);
  const handleChange = (event) => {
    const nodeType = moduleProperties.find((elem) => elem.type === event.target.value);
    setType(event.target.value);
    changeType(nodeType, args);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{i18next.t('flow-Modals.type')}</InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Tipo"
          onChange={handleChange}
          defaultValue=""
          sx={{ overflow: 'hidden' }}
        >
          {moduleProperties.map((type) => {
            if (type.typeBack !== 'INICIAL') {
              return (
                <MenuItem
                  key={type.type}
                  value={`${type.type}`}
                  sx={{
                    ...style,
                    backgroundImage: `url(${type.img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left',
                    backgroundSize: '2rem',
                    textIndent: '2rem',
                  }}
                >
                  {type.type}
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
