import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = ({ loading }) => {
  if (!loading) return <></>;

  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: '99',
      }}
    >
      <CircularProgress loading={loading} size="3rem" />
    </Box>
  );
};

export default Loading;
