import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQueryClient } from 'react-query';

export default function FilterStatusProjects({ areaGroupName, setAreaGroupName, setPage }) {
  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['areas']);

  const handleChange = (event) => {
    setPage(0);
    setAreaGroupName(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 250 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Squad</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={areaGroupName}
          label="Squad"
          onChange={handleChange}
          // defaultValue={filterProjectByStatus}
        >
          <MenuItem value={0}>All</MenuItem>
          {accountCache?.map((area) => (
            <MenuItem key={`filterByAreaGroup_${area.areaagrupamento}`} value={area.areaagrupamento}>
              {area.areaagrupamento}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
