/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useRef, useEffect, useCallback, createContext, useContext } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Handle,
  MiniMap,
  Controls,
  useOnViewportChange,
  ReactFlowProvider,
  useViewport,
} from 'reactflow';
import 'reactflow/dist/style.css';
import '../index.css';
import i18next from 'i18next';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import { Stack, Typography, Card, Box, Badge, Avatar, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import api from '../../../service/api';
import { StyledButton, StyledContainer } from '../../styled-global';
import integrationsAPI from '../utils/functionsIntegrationAPI';
import managementNode, { moduleProperties } from '../utils/functionsManagementNode';
import { optionsMenuContext, addModuleProps } from '../utils/optionsMenu';
import TabsTimeline from './components/TabsTimeline';
import BasicModalTimeline from './components/BasicModalTimeline';
import '../../../assets/logos-clever/site-logo.png';
import { userStore } from '../../../store/userStore';
import PopoverHelp from '../utils/PopoverHelp';
import { ModalNoBondProject } from '../utils/ModalNoBondProject';

/* ----------- styles ----------- */
const CardBodyStyle = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

const BoxForResponsiveness = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(1800)]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [theme.breakpoints.up(900)]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const buttonsUnderCardStyle = { width: 200 };

const CustomNode = ({ data }) => {
  if (
    data.category === 'ACAO' ||
    (data.type === 'INICIAL' && data.status === 1 && data.executer !== null && data.executerIcon !== null)
  ) {
    return (
      <>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={
            data.executer === undefined
              ? `${i18next.t('infos-flow.notExecuter')}`
              : `${i18next.t('infos-flow.executer', { executer: `${data.executer}` })}`
          }
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<SmallAvatar alt={data.executer} src={data.executerIcon} />}
          >
            <Handle type="target" position="left" />
            <div className={ data.status === 1 ? "node__image--executado" : "node__image--cancelado"}>
            {/* <div className="node__image--executado"> */}
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img width="50" src={data.imagem} />
            </div>
            <Handle type="source" id="1" position="right" />
          </Badge>
        </Tooltip>
      </>
    );
  }

  if (data.status === 1 && data.executer !== null && data.executerIcon !== null) {
    return (
      <>
        <Handle type="target" position="left" />
        <div className="node__image--executado">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img width="50" src={data.imagem} />
        </div>
        <Handle type="source" id="1" position="right" />
      </>
    );
  }

  if (data.status === 2) {
    return (
      <>
        <Handle type="target" position="left" />
        <div className="node__image--cancelado">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img width="50" src={data.imagem} />
        </div>
        <Handle type="source" id="1" position="right" />
      </>
    );
  }
  if (data.status === 3) {
    return (
      <>
        <Handle type="target" position="left" />
        <div className="node__image--progress">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img width="50" src={data.imagem} />
        </div>
        <Handle type="source" id="1" position="right" />
      </>
    );
  }
  return (
    <>
      <Handle type="target" position="left" />
      <div className="node__image">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img width="50" src={data.imagem} />
      </div>
      <Handle type="source" id="1" position="right" />
    </>
  );
};
const CustomNodeGroup = ({ data }) => (
  <div className="node__group">
    <Typography sx={{ fontSize: 8 }} variant="body1">
      {data.label}
    </Typography>
  </div>
);

/* --------- Inicialização de variáves -------- */

const initialNodes = managementNode.initialNodes();

const nodeTypes = {
  customnode: CustomNode,
  customnodeGroup: CustomNodeGroup,
};

const FlowForTimeline = () => {
  const { state, pathname } = useLocation();
  // const { idprojeto, roleAdmin } = state;
  const yPosition = useRef(20);
  const xPosition = useRef(0);
  const idEdge = useRef(1);
  const id = useRef(1);
  const flowExists = useRef(false);
  // const project = useRef({});
  // const [projectContext, setProjectContext] = useState();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [altura, setAltura] = useState(150);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalBond, setIsOpenModalBond] = useState(false);
  const [nodeGroupParentId, setNodeGroupParentId] = useState(0);
  const [nodeSelectCheck, setNodeSelectCheck] = useState(null);
  const [nodeDeleteId, setNodeDeleteId] = useState(null);
  const [nodeName, setNodeName] = useState('');
  const [roleUser, setRoleUser] = useState('');
  const [nodeDesc, setNodeDesc] = useState('');
  const proOptions = { hideAttribution: true };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getUserPermissions = userStore((state) => state.getUser);

  const getUser = async () => {
    const userPermissions = await getUserPermissions();
    const admin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
    setRoleUser(admin);
  };
  const idProjectPathname = pathname.split('/')[2];

  const TIME_REFRESH = 1000 * 60 * 5;

  const { data: project, isFetching } = useQuery(
    ['projectFlow', idProjectPathname],
    () => loadFlow(idProjectPathname),
    {
      enable: !!idProjectPathname,
      refetchOnWindowFocus: true,
      refetchInterval: TIME_REFRESH,
    }
  );

  const loadFlow = async (idProjectPathname) => {
    const responseProject = await api.get(`projects/details/${idProjectPathname}`);
    // project? = responseProject.data.data.project;
    let flow = null;
    if (responseProject.data.data.project.projetostatus === -1) {
      // eslint-disable-next-line no-unused-vars
      flow = null;
    } else {
      flowExists.current = true;
      const nodesInGroup = await integrationsAPI.findAllModules(idProjectPathname, false);
      if (nodesInGroup.length === 0) {
        setIsOpenModalBond(true);
        setTimeout(() => {
          navigate('/projects');
        }, 1000 * 10);
        return
      }
      setNodes(nodesInGroup);
      const nodesGroup = nodesInGroup.filter((nds) => nds.type === 'customnodeGroup');
      const ids = nodesGroup.map((nds) => nds.id);
      const positionsX = nodesGroup.map((nds) => nds.position.x);
      const lastStageId = Math.max(...ids);
      const posX = Math.max(...positionsX);
      id.current = lastStageId + 1;
      xPosition.current = posX;
      const edgesFlow = await integrationsAPI.findAllEdges(idProjectPathname);
      edgesFlow.forEach((edge) => {
        const node = nodesInGroup.find((nds) => nds.id === edge.target);
        if (node.status === 1) {
          edge.style = { stroke: 'green', strokeWidth: 2 };
        }
        if (node.status === 2) {
          edge.style = { stroke: 'red', strokeWidth: 2 };
          edge.label = 'X';
        }
        if (node.status === 3) {
          edge.animated = true;
          edge.style = {
            strokeWidth: 3,
            stroke: '#55dd99',
          };
        }
        if (node.data.returnNode === true) {
          edge.label = '🡰';
          edge.labelStyle = { fontWeight: 700, fontSize: 16 };
          edge.labelBgStyle = { fillOpacity: 1 };
          edge.style = {
            strokeWidth: 3,
            stroke: '#E69138',
          };
        }
      });
      if (edgesFlow.length > 0) {
        const idsEdges = edgesFlow.map((edg) => edg.id.replace(/\D/g, ''));
        const lastEdgeId = Math.max(...idsEdges);
        idEdge.current = lastEdgeId + 1;
      }
      setEdges(edgesFlow);
    }
    // eslint-disable-next-line consistent-return
    return responseProject.data.data.project;
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (nodeSelectCheck !== null) {
          if (node.id === String(Number(nodeSelectCheck.parentNode) + 1)) {
            node.style = { ...node.style, height: altura };
          }
        }

        return node;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [altura, setNodes]);

  const showModalProperties = (node) =>
    node !== null ? (
      <BasicModalTimeline
        key={`modal-${node.id}`}
        idprojeto={idProjectPathname}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        node={node}
        nodes={nodes}
        edges={edges}
        status={project?.projetostatus}
        project={project}
        roleAdmin={roleUser}
      />
    ) : (
      <></>
    );

  const onNodeClick = async (event, node) => {
    if (node.type !== 'customnodeGroup') {
      setNodeSelectCheck(node);
      setIsOpen(true);
    }
  };

  const argsMenuContext = {
    nodeSelectCheck,
    nodes,
    setAltura,
    yPosition,
    flowExists,
    idProjectPathname,
    setIsOpen,
    nodeGroupParentId,
    setNodes,
    idEdge,
    setEdges,
    xPosition,
    setNodeGroupParentId,
    nodeDeleteId,
    edges,
    toast,
    id,
  };

  return (
    <StyledContainer>
      {isFetching && (
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0%',
            left: '0%',
            zIndex: '999',
            // backgroundColor: 'rgba(0,0,0,0.5)'
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} sx={{ position: 'relative' }}>
        <ModalNoBondProject isModalOpen={isOpenModalBond} setModalOpen={setIsOpenModalBond} />
        <Typography variant="h4" gutterBottom>
          {t('flow-workflow.project-flow')} : {project?.projetonome}
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <PopoverHelp />
        </Box>
      </Stack>
      <BoxForResponsiveness>
        <Card sx={{ height: '40vh', width: '100%' }}>
          <CardBodyStyle>
            <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={onNodeClick}
                className="validationflow"
                proOptions={proOptions}
                nodeTypes={nodeTypes}
                selectNodesOnDrag={false}
                nodesConnectable={false}
                nodesDraggable={false}
                elementsSelectable={false}
                onConnect={false}
                defaultViewport={{ x: 10, y: 35, zoom: 1 }}
              >
                <Controls showInteractive={false} />
                <MiniMap
                  style={{ scale: '0.6', marginBottom: '-2%', marginRight: '-2%' }}
                  // maskColor='rgb(240, 242, 243, 0.7)'
                />
                {showModalProperties(nodeSelectCheck)}
              </ReactFlow>
            </ReactFlowProvider>
          </CardBodyStyle>
        </Card>
        <Typography variant="h3" sx={{ marginTop: 2, textAlign: 'center' }}>
          Timeline
        </Typography>
        <TabsTimeline idprojeto={idProjectPathname} />
      </BoxForResponsiveness>
    </StyledContainer>
  );
};

export default FlowForTimeline;
