import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import personDoneIcon from '@iconify/icons-eva/person-done-outline';
import personDeleteIcon from '@iconify/icons-eva/person-delete-outline';
import { useTranslation } from 'react-i18next';

export default function ModalBlockUser(props) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();

  return (
    <div>
      <IconButton
        ref={ref}
        sx={{ borderRadius: '0', width: '100%', justifyContent: 'flex-start', gap: 1.4, marginLeft: 1 }}
        onClick={() => {
          handleOpen();
        }}
      >
        <Icon icon={props.statusUser === 2 ? personDeleteIcon : personDoneIcon} height={24} width={24} />
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          {props.statusUser === 2 ? t('user.unlock') : t('user.block')}
        </Typography>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.statusUser === 1 ? t('user.confirm-block') : t('user.confirm-unlock')}</DialogTitle>
        <DialogContent>
          <Stack sx={{ marginTop: '5% auto ', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color={props.statusUser === 1 ? 'error' : 'success'}
              onClick={() => {
                props.blockUser(props.statusUser, props.id);
                handleClose();
              }}
            >
              {props.statusUser === 2 ? t('user.unlock') : t('user.block')}
            </Button>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
