import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Typography } from '@mui/material';
import ReactInputVerificationCode from 'react-input-verification-code';

// ----------------------------------------------------------------------

RHFTwoFactor.propTypes = {
  name: PropTypes.string,
};

export default function RHFTwoFactor({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ReactInputVerificationCode {...field} {...other} value={field.value} length={6}  />
          </Box>
          {error && <Typography align="center" variant="p" color="red" >{error.message}</Typography>}
        </>
      )}
    />
  );
}