import { QueryClient, QueryClientProvider } from 'react-query';
// scroll bar
import 'simplebar/src/simplebar.css';
// React
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// i18n
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
// App
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import LogoutTime from './components/LogoutTime';
import { RefreshToken } from './utils/refreshToken';

// Criando o cliente do reactQuery
const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      refetchOnWindowFocus:true,
    },
  },
});

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <RefreshToken />
        <LogoutTime />
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
