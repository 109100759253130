// react
import { useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { PerfilEdit } from '../sections/auth/perfil';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// eslint-disable-next-line no-unused-vars
const ContentStyle = styled('div')(({ theme }) => ({
  margin: 0,
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function Perfil() {
  const { idPerfil } = useParams();
  const [open, setOpen] = useState(false);

  return (
    <Page title="Perfil">
      <RootStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <Box sx={{ width: 0 }}>
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        </Box>

        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <ContentStyle>
            <PerfilEdit id={idPerfil} sx={{ width: '100%', margin: 0 }} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
