/* eslint-disable no-undef */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Handle,
  MiniMap,
  Controls,
  applyNodeChanges,
  useOnViewportChange,
  Background,
  BackgroundVariant
} from 'reactflow';
import 'reactflow/dist/style.css';
import '../index.css';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import { Stack, Typography, Card, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from './components/ContextMenu';
import api from '../../../service/api';
import { StyledButton, StyledContainer } from '../../styled-global';
import integrationsAPI from '../utils/functionsIntegrationAPI';
import managementNode from '../utils/functionsManagementNode';
import validateFlow from '../utils/functionsValidateFlow';
import { optionsMenuContext } from '../utils/optionsMenu';
import { ProjectStatus } from '../../../enum/ProjectStatusEnum';
import PopoverHelp from '../utils/PopoverHelp';
import { ModalNoBondProject } from '../utils/ModalNoBondProject';

/* ----------- styles ----------- */
const CardBodyStyle = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));
const BoxForResponsiveness = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(1800)]: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const buttonsUnderCardStyle = { width: 200 };

const CustomNode = ({ data }) => {
  if (data.status === 1) {
    return (
      <>
        <Handle type="target" position="left" />
        <div className="node__image--executado">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img width="50" src={data.imagem} />
        </div>
        <Handle type="source" id="1" position="right" />
      </>
    );
  }
  if (data.status === 2) {
    return (
      <>
        <Handle type="target" position="left" />
        <div className="node__image--cancelado">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img width="50" src={data.imagem} />
        </div>
        <Handle type="source" id="1" position="right" />
      </>
    );
  }
  if (data.status === 3) {
    return (
      <>
        <Handle type="target" position="left" />
        <div className="node__image--progress">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img width="50" src={data.imagem} />
        </div>
        <Handle type="source" id="1" position="right" />
      </>
    );
  }
  return (
    <>
      <Handle type="target" position="left" />
      <div className="node__image">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img width="50" src={data.imagem} />
      </div>
      <Handle type="source" id="1" position="right" />
    </>
  );
};

const CustomNodeGroup = ({ data }) => (
  <div className="node__group">
    <Typography sx={{ fontSize: 8 }} variant="body1">
      {data.label}
    </Typography>
  </div>
);

/* --------- Inicialização de variáves -------- */

const initialNodes = managementNode.initialNodes();

let verificaNode;

const nodeTypes = {
  customnode: CustomNode,
  customnodeGroup: CustomNodeGroup,
};

let idprojeto;

const HorizontalFlow = () => {
  const { state } = useLocation();
  // const { idprojeto } = state;
  // const { idprojeto, roleAdmin } = state;
  const yPosition = useRef(20);
  const xPosition = useRef(0);
  const connectionFree = useRef(false);
  const idEdge = useRef(1);
  const id = useRef(1);
  const flowExists = useRef(false);
  const project = useRef({});
  const nodeChanged = useRef({});
  const nodeIsMoved = useRef(false);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isOpenModalBond, setIsOpenModalBond] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [rfInstance, setRfInstance] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [altura, setAltura] = useState(150);
  const [isOpen, setIsOpen] = useState(false);
  const [nodeGroupParentId, setNodeGroupParentId] = useState(0);
  const [nodeDeleteId, setNodeDeleteId] = useState(null);
  const [nodeSelectCheck, setNodeSelectCheck] = useState(null);
  const proOptions = { hideAttribution: true };
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (state !== null) {
    const { idprojeto: id } = state;
    idprojeto = id;
  }

  // eslint-disable-next-line no-plusplus, react-hooks/exhaustive-deps
  const getId = () => `${id.current++}`;
  // eslint-disable-next-line no-plusplus
  const subId = () => `${id.current--}`;

  verificaNode = (target, source) => {
    if (`${Number(source.parentNode) + 1}` === `${Number(target.parentNode)}`) {
      const existsConnection = edges.filter((edg) => target.id === edg.target);
      if (existsConnection.length > 0) {
        if (target.typeNode === 'UNIAO') {
          return true;
        }
        toast(`${t('flow-workflow-Union.unionprevStage')}`);
        connectionFree.current = false;
        return false;
      }
      return true;
    }
    toast(`${t('flow-workflow-Union.unionConnex')}`);
    return false;
  };

  const loadFlow = async () => {
    const responseProject = await api.get(`projects/details/${idprojeto}`);
    const nodesInGroup = await integrationsAPI.findAllModules(idprojeto, true);
    if (nodesInGroup.length === 0 && responseProject.data.data.project.projetostatus !== ProjectStatus.NoFlow) {
      setIsOpenModalBond(true);
      setTimeout(() => {
        navigate('/projects');
      }, 1000 * 10);
      return;
    }
    project.current = responseProject.data.data.project;
    let flow = null;
    if (nodesInGroup.length <= 0) {
      // eslint-disable-next-line no-unused-vars
      flow = null;
    } else {
      flowExists.current = true;
      setNodes(nodesInGroup);
      const nodesGroup = nodesInGroup.filter((nds) => nds.type === 'customnodeGroup');
      const ids = nodesGroup.map((nds) => nds.id);
      const positionsX = nodesGroup.map((nds) => nds.position.x);
      const lastStageId = Math.max(...ids);
      const posX = Math.max(...positionsX);
      id.current = lastStageId + 1;
      xPosition.current = posX;
      const edgesFlow = await integrationsAPI.findAllEdges(idprojeto);
      if (edgesFlow.length > 0) {
        const idsEdges = edgesFlow.map((edg) => edg.id.replace(/\D/g, ''));
        const lastEdgeId = Math.max(...idsEdges);
        idEdge.current = lastEdgeId + 1;
      }
      setEdges(edgesFlow);
    }
  };

  useEffect(() => {
    // loadFlow();
    if (state !== null) {
      loadFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEdges, setNodes]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (nodeSelectCheck !== null) {
          if (node.id === String(Number(nodeSelectCheck.parentNode) + 1)) {
            node.style = { ...node.style, height: altura };
          }
        }

        return node;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [altura, setNodes]);
  useEffect(() => {
    if (state === null) {
      setIsOpenModalBond(true);
      setTimeout(() => {
        navigate('/projects');
      }, 1000 * 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onNodeClick = (event, node) => {
    if (node.type !== 'customnodeGroup') {
      if (!connectionFree.current) {
        onContextMenu(event, node);
      } else {
        onConnectFree(node);
      }
    }
  };

  const onConnectFree = (node) => {
    if (verificaNode(node, nodeSelectCheck)) {
      const newIdEdge = `Edge${idEdge.current}`;
      idEdge.current += 1;
      const newEdge = { id: newIdEdge, source: nodeSelectCheck.id, target: node.id };
      setEdges((eds) => eds.concat(newEdge));
      integrationsAPI.saveEdge(idprojeto, newEdge);
      connectionFree.current = false;
    }
  };

  const onContextMenu = (event, node) => {
    event.preventDefault();
    if (node.type === 'customnodeGroup') {
      setNodeGroupParentId(node.id);
      setIsOpen(false);
    }

    if (isOpen === true) {
      setIsOpen(false);
    }

    if (isOpen === false) {
      if (node.type !== 'customnodeGroup' && (node.status === 0 || node.status === undefined)) {
        setNodeGroupParentId(`${Number(node.parentNode) + 1}`);
        setIsOpen(true);
      }
    }
    setNodeSelectCheck(node);
    setNodeDeleteId(node.id);
    setPosition({ x: event.clientX, y: event.clientY - 250 });
  };

  const onNodesChange = useCallback(
    (changes) => {
      if (changes.length === 1) {
        if (changes[0].dragging === true) {
          nodeIsMoved.current = true;
          nodeChanged.current = changes[0];
        } else if (nodeIsMoved.current) {
          const moduleUpdate = nodes.find((nds) => nds.id === nodeChanged.current.id);
          integrationsAPI.updateModule(idprojeto, moduleUpdate);
          nodeIsMoved.current = false;
        }
      }

      return setNodes((ns) => applyNodeChanges(changes, ns));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes, nodes, idprojeto]
  );

  const argsMenuContext = {
    nodeSelectCheck,
    nodes,
    setAltura,
    yPosition,
    flowExists,
    idprojeto,
    setIsOpen,
    nodeGroupParentId,
    setNodes,
    idEdge,
    setEdges,
    xPosition,
    setNodeGroupParentId,
    nodeDeleteId,
    edges,
    connectionFree,
    toast,
    getId,
    subId,
    project,
    id,
    loadFlow,
  };

  const checkValidateFlow = async () => {
    try {
      if (nodes.length > 2) {
        const isValid = await validateFlow.executeValidateFlow(nodes, edges, idprojeto);
        if (isValid) {
          toast.success(`${t('flow-workflow.valid-flow-success')}`, {
            duration: 5000,
          });
          if (
            project.current.projetostatus === ProjectStatus.NoFlow ||
            project.current.projetostatus === ProjectStatus.NoStarted
          ) {
            // alterar status do projeto
            const body = {
              status: ProjectStatus.NoStarted,
            };
            const projectsResponse = await api.put(`projectstatus/${idprojeto}`, body);
            if (projectsResponse.status === 201) {
              setTimeout(() => {
                navigate(`/projects?projetct=${idprojeto}`);
              }, 3000);
            }
          }
          setTimeout(() => {
            navigate(`/projects?projetct=${idprojeto}`);
          }, 3000);
        }
      } else {
        toast.error(`${t('notValidFlow')}`);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  function ViewportChangeLogger() {
    useOnViewportChange({
      onChange: (() => setIsOpen(false)),
    });

    return null;
  }

  return (
    <StyledContainer>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} sx={{ position: 'relative' }}>
        <ModalNoBondProject isModalOpen={isOpenModalBond} setModalOpen={setIsOpenModalBond} />
        <Typography variant="h4" gutterBottom>
          {t('flow-workflow.project-flow')} : {project.current.projetonome}
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <PopoverHelp />
          <StyledButton style={buttonsUnderCardStyle} to="#" onClick={checkValidateFlow} contained>
            {t('flow-workflow.finalize')}
          </StyledButton>
        </Box>
      </Stack>
      <BoxForResponsiveness>
        <Card sx={{ height: '74vh', width: '100%' }}>
          <CardBodyStyle>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              selectNodesOnDrag={false}
              onNodeClick={onNodeClick}
              elementsSelectable={false}
              className="validationflow"
              nodeTypes={nodeTypes}
              onInit={setRfInstance}
              proOptions={proOptions}
              onNodeContextMenu={onContextMenu}
              defaultViewport={{ x: 10, y: 200, zoom: 3 }}
              get
            >
              <Controls />
              <MiniMap />
              <ViewportChangeLogger />
              <svg>
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(253,29,29,1)" />
                    <stop offset="100%" stopColor="rgba(131,58,180,1)" />
                  </linearGradient>
                </defs>
              </svg>
            </ReactFlow>
            <ContextMenu
              isOpen={isOpen}
              position={position}
              onMouseLeave={() => setIsOpen(false)}
              actions={optionsMenuContext(argsMenuContext)}
            />
          </CardBodyStyle>
        </Card>
      </BoxForResponsiveness>
    </StyledContainer>
  );
};

export default HorizontalFlow;
