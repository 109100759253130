/* eslint-disable no-plusplus */
import { useEffect, useRef, useState } from 'react';
import { Box, InputAdornment, Tooltip } from '@mui/material';
import { filter } from 'lodash';
import infoIcon from '@iconify/icons-mdi/information-variant';
import { Icon } from '@iconify/react';
import searchFillIcon from '@iconify/icons-eva/search-fill';
import { useTranslation } from 'react-i18next';
import Scrollbar from './Scrollbar';
import RepositoryItem from './RepositoryItem';
import RepositoryContextMenu from './RepositoryContextMenu';
import RepositoryFilesFilterByStatusMenu from './RepositoryFilesFilterByStatusMenu';

export default function RepositoryFiles({
  SearchStyle,
  folder,
  uploadFileHandler,
  clickNewFolderHandler,
  moveDirectoryHandler,
  setDirectory,
  directory,
  setNavigatedDirectory,
  downloadFileHandler,
  reactivateFileHandler,
  selectedItems,
  setSelectedItems,
  iconsSize,
  loggedUser,
  isAdmin,
  openModalHandler,
}) {
  const [filterItems, setFilterItems] = useState('');
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [contextMenuItemPosition, setContextMenuItemPosition] = useState(null);
  const [folderItems, setFolderItems] = useState([]);
  const [isSearchingItems, setIsSearchingItems] = useState(false);
  const [filterByStatusMenuAnchorEl, setFilterByStatusAnchorel] = useState(null);

  const { t } = useTranslation();

  const contextMenuRef = useRef(null);

  const componentRef = useRef(null);

  const itemsRef = useRef([]);

  const filteredItems = applySortFilter(folderItems, filterItems);

  const openFilterByStatusMenu = Boolean(filterByStatusMenuAnchorEl);

  const handleFilterByStatusMenuClick = (event) => {
    setFilterByStatusAnchorel(event.currentTarget);
  };
  const handleCloseFilterByStatusMenu = () => {
    setFilterByStatusAnchorel(null);
  };

  const flattenFolderItems = (folderObj) => {
    folderObj.children?.forEach((child) => {
      setFolderItems((prevState) => [...prevState, child]);

      if (child.children) flattenFolderItems(child);
    });
  };

  // Achatar os ítens na montagem do componente
  useEffect(() => {
    setFolderItems([]);
    folderItems.push(flattenFolderItems(folder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder, directory]);

  // Se abrirmos o context menu de upload/nova pasta, retirará a seleção de itens
  useEffect(() => {
    if (contextMenuPosition) setSelectedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextMenuPosition]);

  function applySortFilter(array, query) {
    if (query) {
      if (!isSearchingItems) setIsSearchingItems(true);

      const arrayOfFilteredFiles = [];

      if (query.toLowerCase() === t('repo.repo-files.status-obsolete').toLocaleLowerCase()) {
        const filteredByStatus = array.filter((item) => item.status === 'O');
        arrayOfFilteredFiles.push(...filteredByStatus);
      }
      if (query.toLowerCase() === t('repo.repo-files.status-present').toLocaleLowerCase()) {
        const filteredByStatus = array.filter((item) => item.status === 'V');
        arrayOfFilteredFiles.push(...filteredByStatus);
      }
      if (query.toLowerCase() === t('repo.repo-files.status-scheduled').toLocaleLowerCase()) {
        const filteredByStatus = array.filter((item) => item.status === 'P');
        arrayOfFilteredFiles.push(...filteredByStatus);
      }

      const filteredByData = array.filter((item) => {
        const dtcriacaoSplitted = item.dtcriacao?.split(' ')[0];

        if (dtcriacaoSplitted === query) return item;
        return null;
      });

      const filteredByName = filter(array, (_item) =>
        _item.type === 'file'
          ? _item.filename?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
          : _item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );

      arrayOfFilteredFiles.push(...filteredByData);

      arrayOfFilteredFiles.push(...filteredByName);

      return arrayOfFilteredFiles;
    }
    if (isSearchingItems) setIsSearchingItems(false);

    return array;
  }

  const handleFilterByFolder = (event) => {
    setFilterItems(event.target.value);
  };

  const openContextMenuHandler = (event) => {
    event.preventDefault();
    if (isAdmin) {
      const parentBounds = event.target.getBoundingClientRect();
      const x = event.clientX - parentBounds.left;
      const y = event.clientY - parentBounds.top;
      setContextMenuItemPosition(null);
      setContextMenuPosition({ x, y });
    }
  };

  // Quando clicarmos em qualquer lugar fora do contextMenu, o menu irá fechar
  useEffect(() => {
    const handleClick = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        setContextMenuPosition(null);
        setContextMenuItemPosition(null);
        setSelectedItems([]);
      }
    };

    const element = componentRef.current.parentElement.parentElement.parentElement.parentElement;

    element.addEventListener('click', handleClick);

    return () => {
      element.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Resetar itens selecionados caso clique fora de um item
  useEffect(() => {
    const handleClick = (event) => {
      const isItemClickedArray = [];
      itemsRef.current.forEach((itemRef) => {
        isItemClickedArray.push(itemRef && itemRef.contains(event.target));
      });

      const contextMenuParentElement = event.target.parentElement.parentElement.parentElement.parentElement;
      if (!isItemClickedArray.includes(true) && !contextMenuParentElement.contains(contextMenuRef.current))
        setSelectedItems([]);
    };

    const element = componentRef.current.parentElement;

    element.addEventListener('click', handleClick);

    return () => {
      element.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Resetar campo de busca por arquivo ao navegar nas pastas
  useEffect(() => {
    setFilterItems('');
  }, [folder, directory]);

  const filterStatusHandler = (status) => {
    if (status === '') status = '';
    if (status === 'O') status = t('repo.repo-files.status-obsolete');
    if (status === 'V') status = t('repo.repo-files.status-present');
    if (status === 'P') status = t('repo.repo-files.status-scheduled');
    setFilterItems(status);
    handleCloseFilterByStatusMenu();
  };

  return (
    <>
      <Box sx={{ width: '100%', marginBottom: 1 }} ref={componentRef}>
        {/* Motor de Busca Arquivos */}
        <SearchStyle
          value={filterItems}
          onChange={(event) => handleFilterByFolder(event)}
          placeholder={t('repo.repo-files.search-files')}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon={searchFillIcon} />
            </InputAdornment>
          }
          endAdornment={
            <>
              <Tooltip title={t('repo.repo-files.search-info')} disableInteractive>
                <InputAdornment position="start">
                  <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              </Tooltip>
              <RepositoryFilesFilterByStatusMenu
                open={openFilterByStatusMenu}
                handleClick={handleFilterByStatusMenuClick}
                anchorEl={filterByStatusMenuAnchorEl}
                handleClose={handleCloseFilterByStatusMenu}
                filterStatusHandler={filterStatusHandler}
              />
            </>
          }
        />
      </Box>
      <Scrollbar
        sx={{ height: '100%', position: 'relative' }}
        onContextMenu={(event) => {
          openContextMenuHandler(event);
        }}
      >
        {(contextMenuPosition || contextMenuItemPosition) && (
          <RepositoryContextMenu
            contextMenuPosition={contextMenuPosition}
            setContextMenuPosition={setContextMenuPosition}
            clickNewFolderHandler={clickNewFolderHandler}
            folder={folder}
            uploadFileHandler={uploadFileHandler}
            contextMenuRef={contextMenuRef}
            contextMenuItemPosition={contextMenuItemPosition}
            setContextMenuItemPosition={setContextMenuItemPosition}
            downloadFileHandler={downloadFileHandler}
            reactivateFileHandler={reactivateFileHandler}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            isAdmin={isAdmin}
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '100%', gap: 1 }}>
          {filteredItems.map((item, index) => (
            <RepositoryItem
              key={index}
              index={index}
              item={item}
              moveDirectoryHandler={moveDirectoryHandler}
              directory={directory}
              setDirectory={setDirectory}
              setNavigatedDirectory={setNavigatedDirectory}
              isSearchingItems={isSearchingItems}
              setFilterItems={setFilterItems}
              setRepositoryFilesContextMenu={setContextMenuPosition}
              contextMenuItemPosition={contextMenuItemPosition}
              setContextMenuItemPosition={setContextMenuItemPosition}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              itemsRef={itemsRef}
              iconsSize={iconsSize}
              loggedUser={loggedUser}
              isAdmin={isAdmin}
              openModalHandler={openModalHandler}
            />
          ))}
        </Box>
      </Scrollbar>
    </>
  );
}
