import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Icon } from '@iconify/react';
import emailOutline from '@iconify/icons-eva/email-outline';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import toast from 'react-hot-toast';
import { TextField } from '@mui/material';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';
import ConnectAreasAndUsers from '../../../../ConnectAreasAndUsers';
import api from '../../../../../service/api';

export default function ModalEmail({ args }) {
  const { nodeSelectCheck, idprojeto } = args;
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasUsers, setAreasUsers] = useState(null);
  const [emailDescription, setEmailDescription] = useState('');
  const [emailTitle, setEmailTitle] = useState('');
  const [externalEmail, setExternalEmail] = useState('');

  const requestUsers = async () => {
    try {
      const response = await api.get(`/users`);
      setUsers(response.data.data.users);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  const requestAreas = async () => {
    try {
      const responseAreas = await api.get(`/areas`);
      setAreas(responseAreas.data.data.areas);
    } catch (error) {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  const getselectAreasAndUsers = async (id, idprojeto) => {
    try {
      const response = await api.get(`/modules/alertlista/${id}/${idprojeto}`);
      setSelectedAreas([...response.data.data.alertalista.area]);
      setSelectedUsers([...response.data.data.alertalista.usuario]);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  useEffect(() => {
    requestAreas();
    requestUsers();
  }, []);

  useEffect(() => {
    getselectAreasAndUsers(nodeSelectCheck.id, idprojeto);
    if (nodeSelectCheck.properties !== null && nodeSelectCheck.properties !== undefined) {
      const emailProps = nodeSelectCheck.properties?.EMAIL;
      if (emailProps.title !== null && emailProps.title !== undefined) {
        setEmailTitle(emailProps.title);
      }
      if (emailProps.description !== null && emailProps.description !== undefined) {
        setEmailDescription(emailProps.description);
      }
      if (emailProps.externalEmails !== null && emailProps.externalEmails !== undefined) {
        setExternalEmail(emailProps.externalEmails);
      }
    }
  }, [idprojeto, nodeSelectCheck.id, nodeSelectCheck.properties]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    nodeSelectCheck.properties = {
      EMAIL: { title: emailTitle, description: emailDescription, externalEmails: externalEmail },
    };
    nodeSelectCheck.alertalista = areasUsers;
    integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
    setOpen(false);
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-email.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={emailOutline} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('flow-Modals-email.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{i18next.t('flow-Modals-email.text')}</DialogContentText>
            <ConnectAreasAndUsers
              areas={areas}
              selectedAreas={selectedAreas}
              setSelectedAreas={setSelectedAreas}
              users={users}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              setAreasUsersData={setAreasUsers}
              style={{ marginTop: 3 }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={i18next.t('flow-Modals-email.labelEmails')}
              type="text"
              fullWidth
              variant="outlined"
              value={externalEmail}
              onChange={(e) => {
                setExternalEmail(e.target.value);
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={i18next.t('flow-Modals-email.labelTopic')}
              type="text"
              fullWidth
              variant="outlined"
              value={emailTitle}
              onChange={(e) => {
                setEmailTitle(e.target.value);
              }}
              required
            />
            <TextField
              id={`field-text`}
              label={i18next.t('flow-Modals-email.labelDesc')}
              multiline
              fullWidth
              rows={4}
              margin="dense"
              required
              value={emailDescription}
              onChange={(e) => {
                setEmailDescription(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button variant="contained" onClick={handleSend} color="success">
              {i18next.t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
