import { Box } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

export const LoadingForReports = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          margin: 'auto',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '0%',
          left: '0%',
          zIndex: '9999999',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }
  return <></>
};
