import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import  i18next  from 'i18next';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
// @mui
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// api
import api from '../../../service/api';

// ----------------------------------------------------------------------

export default function ActiveDirectoryForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userad } = state;

  async function requestApiRegisterAD(data) {
    try {
      const req = {
        name: data.name,
        email: data.email,
        userad,
      };
      const responseRegisterAD = await api.post('/usersad', req);
      if (responseRegisterAD.data.status === 'success') {
        navigate('/login', { replace: true });
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-/.]+$/, 'Digite um nome válido')
      .max(40)
      .required('Nome completo é obrigatório'),
    email: Yup.string().email('Digite um endereço de e-mail válido').required('Endereço de e-mail é obrigatório'),
    /* userad: Yup.string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-/.]+$/, 'Digite um nome de usuário válido')
      .max(40)
      .required('Nome do usuário é obrigatório'), */
  });

  const defaultValues = {
    name: '',
    email: '',
    userad: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    requestApiRegisterAD(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label="Nome Completo" />
        </Stack>

        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="userad" label="Nome de Usuário" />
        </Stack> */}

        <RHFTextField name="email" label="Endereço de e-mail" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Registrar
        </LoadingButton>
        <Button fullWidth size="large" variant="text" component={RouterLink} to="/loginad">
          Voltar
        </Button>
      </Stack>
    </FormProvider>
  );
}
