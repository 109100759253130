import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Stack,
  Switch,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  MenuItem,
  Checkbox,
} from '@mui/material';
import Select from '@mui/material/Select';
import { toast } from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import api from '../../../service/api';
import { LoadingForReports } from '../../LoadingForReports';
import ModalReportAuth from './ModalReportAuth';

const ImgBoxStyle = styled(Box)(() => ({
  width: 42,
  height: 42,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

export default function ModalReportProjectsOverdueArea({ open, handleClose, title, description, icon }) {
  const [checked, setChecked] = useState(true);
  const [checkedAllAreas, setCheckedAllAreas] = useState(false);
  const [areasToSend, setAreasToSend] = useState([]);
  const [reportAuth, setReportAuth] = useState(true);
  const [fieldValue, setFieldValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const hasOptions = useRef(null);

  const { t } = useTranslation();

  const getAreas = async () => {
    try {
      const responseUsers = await api.get('/areas');
      return responseUsers.data.data.areas;
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      else{
        toast.error(t('report.error'));

      }
      console.error(error);
    }
    return null;
  };

  const { data: areas } = useQuery(['areasForReport'], getAreas);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeAllAreas = (event) => {
    setCheckedAllAreas(event.target.checked);
  };

  // eslint-disable-next-line consistent-return
  const handleClickOpenAuth = (event) => {
    if(areasToSend.length === 0 && !checkedAllAreas) return toast.error(t('noAreasSelected'))
    setReportAuth(true);
    setFieldValue(event.isTrusted);
  };

  const handleCloseAuth = () => {
    setReportAuth(false);
  };

  const handleChangeSelect = (event) => {
    setAreasToSend(event.target.value);

  };

  // eslint-disable-next-line consistent-return
  const handleGenarator = async () => {
    let areasToSendCheck 
    if(!checkedAllAreas) areasToSendCheck = areasToSend
    if(checkedAllAreas) areasToSendCheck = areas.map(area => area.areaagrupamento)
    if(areasToSendCheck.length === 0) return toast.error(t('noAreasSelected'))
    setIsLoading(true);
    try {
      const response = await api.get(`/report/exportprojectsoverduearea/${areasToSendCheck}`, { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;

      const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];

      fileLink.setAttribute('download', `${fileName}.${extension}`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      else{
        toast.error(t('report.error'));

      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleGenaratorPDF = async () => {
    let areasToSendCheck 
    if(!checkedAllAreas) areasToSendCheck = areasToSend
    if(checkedAllAreas) areasToSendCheck = areas.map(area => area.areaagrupamento)
    if(areasToSendCheck.length === 0) return toast.error(t('noAreasSelected'))
    setIsLoading(true);
    try {
      const response = await api.get(`/report/exportprojectsoverdueareapdf/${areasToSendCheck}`, { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;

      const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];

      fileLink.setAttribute('download', `${fileName}.${extension}`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            component="span"
            sx={{ display: 'flex', flexDirection: 'row', alignSelf: '', marginTop: '2rem' }}
          >
            <ImgBoxStyle component="img" src={icon} sx={{ alignSelf: 'start', marginTop: 0.5 }} />
            <Typography
              component="span"
              variant="h6"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1, marginLeft: 1 }}
            >
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component="span" sx={{ marginTop: '1rem' }}>
              {description}
            </DialogContentText>
            <DialogContentText component="span">
              <Stack>
                <Typography component="span" sx={{ marginTop: '1rem', color: '#637381' }}>
                  {t('report.modal-report.type')}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography component="span">XLSX</Typography>
                  <Switch checked={checked} inputProps={{ 'aria-label': 'ant design' }} onChange={handleChange} />
                  <Typography component="span">PDF</Typography>
                </Stack>
              </Stack>
              <Stack component="span" direction="row" sx={{ marginTop: 3 }}>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  <InputLabel id="multiple-chip-label">{t('sidebar.areas')}</InputLabel>
                  <Select
                    labelId="multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    autoWidth
                    disabled={checkedAllAreas}
                    required
                    value={areasToSend}
                    onChange={handleChangeSelect}
                    input={<OutlinedInput id="select-multiple-chip" label={t('sidebar.areas')} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, index) => (
                          <Chip key={`selected_chip_${index}`} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {areas?.map((area, index) => (
                      <MenuItem key={`area_chip_${index}`} value={area.areaagrupamento}>
                        {area.areaagrupamento}
                      </MenuItem>
                    ))}
                  </Select>
                  <Stack direction={'row'} alignItems={'center'} marginTop={'1rem'}>
                    <Checkbox
                      checked={checkedAllAreas}
                      onChange={handleChangeAllAreas}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography component="span" sx={{ color: '#637381' }}>
                      {t('allAreas')}
                    </Typography>
                  </Stack>
                </FormControl>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {t('report.button.cancel')}
            </Button>
            {checked === false ? (
              <Button variant="contained" onClick={handleGenarator} color="success">
                {t('report.button.generate')}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleClickOpenAuth} color="success">
                {t('report.button.generate')}
              </Button>
            )}
          </DialogActions>
          <LoadingForReports isLoading={isLoading} />
          {fieldValue === true && (
            <ModalReportAuth
              open={reportAuth}
              onClose={handleCloseAuth}
              handleClose={handleCloseAuth}
              genaratorPDF={handleGenaratorPDF}
            />
          )}
        </Dialog>
      </Box>
    </div>
  );
}
