import { Breadcrumbs } from '@mui/material';
import { useState, useEffect } from 'react';

import BreadcrumbsLink from './BreadcrumbsLink';

export default function RepositoryBreadcrumbs({
  product,
  directory,
  setDirectory,
  moveDirectoryHandler,
  setNavigatedDirectory,
  setNavigationIndex,
}) {
  const [splittedPath, setSplittedPath] = useState(['~']);

  useEffect(() => {
    if (directory) {
      setSplittedPath(directory?.split('/'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directory]);

  if (directory) {
    if (splittedPath?.length >= 1) {
      splittedPath[0] = '~';

      if (splittedPath?.length > 1) splittedPath[1] = product?.produtonome;
    }
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {splittedPath.map((pathPortion, index) => (
        <BreadcrumbsLink
          key={index}
          pathIndex={index}
          pathPortion={pathPortion}
          setDirectory={setDirectory}
          directory={directory}
          moveDirectoryHandler={moveDirectoryHandler}
          setNavigatedDirectory={setNavigatedDirectory}
          setNavigationIndex={setNavigationIndex}
        />
      ))}
    </Breadcrumbs>
  );
}
