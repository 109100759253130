/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { toast } from 'react-hot-toast';
import i18next from 'i18next';
import integrationsAPI from './functionsIntegrationAPI';
import managementNode from './functionsManagementNode';
import { imagens } from '../assets/imagens';

const initialNodes = managementNode.initialNodes();

const getNodeId = () => `${+new Date()}`;

const updateNode = async (args) => {
  const {
    nodeSelectCheck,
    nodes,
    setAltura,
    yPosition,
    flowExists,
    idprojeto,
    setIsOpen,
    nodeGroupParentId,
    setNodes,
    idEdge,
    setEdges,
    xPosition,
    setNodeGroupParentId,
    getId,
  } = args;
  if (nodeSelectCheck.type !== 'customnodeGroup') {
    const customNodes = nodes.filter((nod) => nod.type === 'customnode');
    const nodesInGroup = customNodes.filter((custom) => Number(custom.parentNode) > nodeSelectCheck.parentNode);
    const customNodesInGroup = nodes.filter(
      (custom) => custom.parentNode === String(Number(nodeSelectCheck.parentNode) + 1)
    );
    if (nodesInGroup.length > 0) {
      if (customNodesInGroup.length >= 2) {
        const alturaPlus = customNodesInGroup.length + 1;
        const novaAltura = alturaPlus * 70;
        setAltura(novaAltura);
      }
      yPosition.current = 20 + customNodesInGroup.length * 60;
      onAdd(nodeSelectCheck, yPosition, nodeGroupParentId, idprojeto, setNodes, idEdge, setEdges);
    } else {
      if (!flowExists.current) {
        await integrationsAPI.saveInitialModule(idprojeto, initialNodes);
        flowExists.current = true;
      }
      await onAddGroup(xPosition, setNodeGroupParentId, setNodes, getId);
      yPosition.current = 20;
      onAdd(nodeSelectCheck, yPosition, nodeGroupParentId, idprojeto, setNodes, idEdge, setEdges);
    }
  }
  setIsOpen(false);
};

const deleteNode = async (args) => {
  const {
    nodeSelectCheck,
    nodes,
    idprojeto,
    setIsOpen,
    nodeGroupParentId,
    setNodes,
    setEdges,
    xPosition,
    setNodeGroupParentId,
    nodeDeleteId,
    edges,
    subId,
    idEdge,
  } = args;

  try {
    // não apagar estágios ou o primeiro módulo
    if (nodeSelectCheck.type === 'customnodeGroup' || nodeSelectCheck.id === 'a') {
      setIsOpen(false);
    } else if (nodeDeleteId) {
      const currentStageId = nodeGroupParentId - 1;
      const customNodes = nodes.filter((nod) => nod.type === 'customnode');
      const nodesFlow = nodes.filter((nds) => nds.id !== nodeDeleteId);
      const edgesDeleted = edges.filter((edge) => edge.source === nodeDeleteId || edge.target === nodeDeleteId);
      const edgesInFlow = edges
        .filter((x) => !edgesDeleted.includes(x))
        .concat(edgesDeleted.filter((x) => !edges.includes(x)));

      await integrationsAPI.deleteModule(nodeDeleteId, idprojeto, edgesDeleted);

      setEdges(edgesInFlow);

      const nodesInGroup = customNodes.filter((nds) => nds.parentNode === `${currentStageId}`);
      const nodesInGroupBefore = customNodes.filter((nds) => nds.parentNode === `${currentStageId - 1}`);
      const nodesInGroupAfter = customNodes.filter((nds) => nds.parentNode === `${currentStageId + 1}`);

      if (nodesInGroupBefore.length === 1 && nodesInGroupAfter.length === 1) {
        const edgeNodeSource = edges.find((edg) => edg.target === nodeDeleteId);
        const edgeNodeTarget = edges.find((edg) => edg.source === nodeDeleteId);

        if (edgeNodeSource !== undefined && edgeNodeTarget !== undefined) {
          /* const nodeSource = nodes.find((nds) => nds.id === edgeNodeSource.source);
        const nodeTarget = nodes.find((nds) => nds.id === edgeNodeTarget.target); */

          if (nodesInGroup.length === 1) {
            const newIdEdge = `Edge${idEdge.current}`;
            idEdge.current += 1;
            const newEdge = { id: newIdEdge, source: edgeNodeSource.source, target: edgeNodeTarget.target };
            setEdges((eds) => eds.concat(newEdge));
            await integrationsAPI.saveEdge(idprojeto, newEdge);
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      /* for await (const edge of edgesDeleted) {
      await integrationsAPI.deleteEdge(idprojeto, edge);
    } */

      if (nodesInGroup.length <= 1) {
        const newNodeGroupParentId = String(currentStageId);
        setNodeGroupParentId(newNodeGroupParentId);
        xPosition.current -= 120;
        subId();
        const nodesNonDeleted = nodesFlow.filter((nds) => nds.id !== `${currentStageId}`);
        let sumParenteId = Number(newNodeGroupParentId);
        const arrNewNodes = [];
        for await (const nds of nodesNonDeleted) {
          if (nds.id !== `${nodeGroupParentId - 1}`) {
            if (Number(nds.parentNode) >= Number(newNodeGroupParentId) + 1) {
              nds.parentNode = `${Number(nds.parentNode) - 1}`;
              await integrationsAPI.updateModule(idprojeto, nds);
            }

            if (Number(nds.id) >= Number(newNodeGroupParentId) + 1 && nds.parentNode === undefined) {
              nds.id = `${sumParenteId}`;
              nds.data.label = `${i18next.t('stages-Flow.stage')} ${sumParenteId}`;
              nds.position.x -= 120;
              sumParenteId += 1;
            }
          }
          arrNewNodes.push(nds);
        }
        setNodes(arrNewNodes);
      } else {
        let novaAltura;
        if (nodesInGroup.length > 2) {
          const alturaPlus = nodesInGroup.length - 1;
          novaAltura = alturaPlus * 75;
        } else {
          novaAltura = 150;
        }

        setNodes(() => {
          let yPosition = 20;
          const nodesResult = nodesFlow.map((nds) => {
            if (Number(nds.parentNode) === currentStageId) {
              nds.position.y = yPosition;
              yPosition += 60;
            }
            if (Number(nds.id) === currentStageId) {
              nds.style.height = novaAltura;
            }
            return nds;
          });
          return nodesResult;
        });
      }
      setIsOpen(false);
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      // toast.error(`${error.response.data.data.errors[0]}`);
      toast.error(`${i18next.t('not-Access')}`);
    }
    if (error.response.status === 409) {
      toast.error(i18next.t('toast-ModuloNotDelete'));
    } else {
      toast.error(`${error.response.data.data.errors[0]}`);
    }
  }
};

const onAdd = async (nodeSelectedCurrent, yPosition, nodeGroupParentId, idprojeto, setNodes, idEdge, setEdges) => {
  const newNode = managementNode.createNode(
    getNodeId(),
    { x: 23, y: yPosition.current },
    { label: null, imagem: imagens.fileFill },
    nodeGroupParentId,
    'ACAO',
    'ACOMPANHA'
  );
  // setNodes((nds) => nds.concat(newNode));


  setNodes((nodes) => {
    const nodesInGroup = [];
    const concatNodes = nodes.concat(newNode);
    let novaAltura;
    concatNodes.forEach((node) => {
      if (node.parentNode === nodeGroupParentId) nodesInGroup.push(node);
    });
    if (nodesInGroup.length > 1) {
      const alturaPlus = nodesInGroup.length;
      novaAltura = alturaPlus * 75;
    } else {
      novaAltura = 150;
    }

    const newNodes = concatNodes.map((node) => {
      if (node.id === nodeGroupParentId) {
        node.style.height = novaAltura;
      }
      return node;
    });

    return newNodes;
  });

  await integrationsAPI.saveModule(idprojeto, newNode);
  const newIdEdge = `Edge${idEdge.current}`;
  idEdge.current += 1;
  const newEdge = { id: newIdEdge, source: nodeSelectedCurrent.id, target: newNode.id };
  setEdges((eds) => eds.concat(newEdge));
  integrationsAPI.saveEdge(idprojeto, newEdge);
};

const onAddGroup = async (xPosition, setNodeGroupParentId, setNodes, getId) => {
  const idStage = getId();
  const newStage = managementNode.createStage(
    idStage,
    { x: (xPosition.current += 120), y: 0 },
    { label: `${i18next.t('stages-Flow.stage')} ${idStage}` },
    { width: 100, height: 150 }
  );
  setNodeGroupParentId(newStage.id);
  setNodes((nds) => nds.concat(newStage));
};

const createBack = async (args) => {
  // eslint-disable-next-line no-unused-vars
  const { nodeSelectCheck, nodes, idprojeto, setNodes, xPosition, edges, id, yPosition, setEdges, idEdge } = args;

  // const prevStage = nodes.find((node) => node.id === `${Number(nodeSelectCheck.parentNode) - 1}`);
  const currentStage = nodes.find((node) => node.id === `${Number(nodeSelectCheck.parentNode)}`);

  // const newIdStage = `${Number(prevStage.id) + 1}`;
  const newIdStage = `${currentStage.id}`;

  const newStage = managementNode.createStage(
    newIdStage,
    { x: (xPosition.current += 120), y: 0 },
    { label: `${i18next.t('stages-Flow.stage')} ${newIdStage}` },
    { width: 100, height: currentStage.style.height }
  );

  const nodesNewStage = nodes.concat(newStage);

  let sumParenteId = Number(newIdStage);
  const arrNewNodes = [];
  // eslint-disable-next-line no-unreachable-loop
  for await (const nds of nodesNewStage) {
    if (nds.parentNode !== undefined && Number(nds.parentNode) >= Number(newIdStage)) {
      nds.parentNode = `${Number(nds.parentNode) + 1}`;
      await integrationsAPI.updateModule(idprojeto, nds);
    }

    if (nds.type === 'customnodeGroup' && Number(nds.id) >= Number(newIdStage)) {
      nds.id = `${sumParenteId}`;
      nds.data.label = `${i18next.t('stages-Flow.stage')} ${sumParenteId}`;
      sumParenteId += 1;
    }
    arrNewNodes.push(nds);
  }

  const newNode = managementNode.createNode(
    getNodeId(),
    { x: 23, y: 20 },
    { label: null, imagem: imagens.fileFill },
    newIdStage,
    'ACAO',
    'ACOMPANHA'
  );

  const nodesNewNodes = arrNewNodes.concat(newNode);

  await integrationsAPI.saveModule(idprojeto, newNode);

  nodesNewNodes.sort((a, b) => a.id - b.id);
  const nodesGroup = nodesNewNodes.filter((nds) => nds.type === 'customnodeGroup');
  const ids = nodesGroup.map((nds) => nds.id);
  const lastStageId = Math.max(...ids);
  id.current = lastStageId + 1;

  setNodes(nodesNewNodes);

  const nodesInGroupCurrent = nodesNewNodes.filter((nds) => nds.parentNode === `${newIdStage}`);
  const nodesInGroupBefore = nodes.filter((node) => node.parentNode === `${Number(currentStage.id) - 1}`);
  const nodesInGroupAfter = nodes.filter((nds) => nds.parentNode === `${Number(currentStage.id) + 1}`);

  nodesInGroupBefore.forEach((node) => {
    const edgesSourceNode = edges.filter((edge) => edge.source === node.id);
    setEdges((eds) => eds.filter((edge) => edge.source !== node.id));
    edgesSourceNode.forEach(async (edge) => {
      await integrationsAPI.deleteEdge(idprojeto, edge);
    });
  });

  if (nodesInGroupBefore.length === 1 && nodesInGroupAfter.length === 1) {
    const newIdEdgeTarget = `Edge${idEdge.current}`;
    idEdge.current += 1;
    const newEdgeTarget = { id: newIdEdgeTarget, source: nodesInGroupBefore[0].id, target: nodesInGroupCurrent[0].id };
    setEdges((eds) => eds.concat(newEdgeTarget));
    await integrationsAPI.saveEdge(idprojeto, newEdgeTarget);

    const newIdEdgeSource = `Edge${idEdge.current}`;
    idEdge.current += 1;
    const newEdgeSource = { id: newIdEdgeSource, source: nodesInGroupCurrent[0].id, target: nodesInGroupAfter[0].id };
    setEdges((eds) => eds.concat(newEdgeSource));
    await integrationsAPI.saveEdge(idprojeto, newEdgeSource);
  }
};

const connect = (args) => {
  const { connectionFree, toast } = args;
  connectionFree.current = true;
  toast(`${i18next.t('cilckNodeConnex.toastInfo')}`);
};

const actionsFlow = {
  updateNode,
  deleteNode,
  createBack,
  connect,
};

export default actionsFlow;
