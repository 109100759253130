import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  // Tooltip, IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Tooltip,
} from '@mui/material';

import infoIcon from '@iconify/icons-mdi/information-variant';
import searchIcon from '@iconify/icons-eva/search-fill';

import { useTranslation } from 'react-i18next';

// component
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 270,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 330, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ProjectsListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterProject: PropTypes.string,
  onFilterProject: PropTypes.func,
};

export default function ProjectsListToolbar({ numSelected, filterProject, onFilterProject }) {
  const { t } = useTranslation();

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
        display: 'flex',
        justifyContent: 'space-between',
        gap: 5,
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterProject}
          onChange={onFilterProject}
          placeholder={t('projects.project-search-label')}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon={searchIcon} height={20} width={20} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
          endAdornment={
            <Tooltip title={t('projects.search-info')} disableInteractive>
              <InputAdornment position="start">
                <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            </Tooltip>
          }
        />
      )}
    </RootStyle>
  );
}
