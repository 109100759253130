import { styled } from '@mui/material/styles';
import { Container, Typography, Dialog, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Logo from '../../Logo';
import { ReportForm } from '../../../sections/auth/report';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: theme.spacing(1, 0, 0, 0),
  },
}));

// ----------------------------------------------------------------------

export default function ModalReportAuth({ open, handleClose, genaratorPDF }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} sx={{ width: '100%' }}>
      <Container sx={{ padding: '15%' }}>
        <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%' }}>
          <IconButton size="large" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        <Typography variant="h4" sx={{ color: 'text.login' }} gutterBottom>
          {t('login.title')}
        </Typography>
        <ReportForm handleClose={handleClose} genaratorPDF={genaratorPDF} />
      </Container>
    </Dialog>
  );
}
