export const light = {
  control: {
    backgroundColor: '#e1e8f0',
    fontSize: 14,
    fontWeight: 'normal',
    borderRadius: '5px',
  },

  '&multiLine': {
    control: {
      fontFamily: 'Public Sans,sans-serif',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '2px solid rgba(0,0,0,0.25)',
      boxSizing: 'border-box',
      borderRadius: '5px',
    },
  },
  suggestions: {
    list: {
      backgroundColor: '#E6E6E9',
      borderRadius: '5px',
      border: '1px solid rgba(0,0,0,0.25)',
      fontSize: 14,
    },
    item: {
      color: 'black',
      borderRadius: '5px',
      fontWeight: 'bold',
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#FF9A03',
      },
    },
  },
};

export const mention = {
  backgroundColor: '#f5ba62',
  
}
