import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import i18next from 'i18next';
import { Icon } from '@iconify/react';
import quickReference from '@iconify/icons-material-symbols/quick-reference';
import { Typography, CardMedia } from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { moduleProperties } from '../../../utils/functionsManagementNode';
// import toast from 'react-hot-toast';
import { NodeSelectTimeline } from '../BasicModalTimeline';
import api from '../../../../../service/api';
import ModalRefAnexo from './ModalRefAnexo';
import ModalEscolhaFormExecution from './ModalEscolhaFormExecution';
import { userStore } from '../../../../../store/userStore';

// npm install --save-dev @iconify/react @iconify/icons-material-symbols
export default function ModalReferenciaExecution() {
  const [open, setOpen] = useState(false);
  const User = useRef(null);
  const getUser = userStore((state) => state.getUser);
  const [prevNodes, setPrevNodes] = useState([]);
  const nodeSelectTimeline = useContext(NodeSelectTimeline);
  const { node: nodeselect, idprojeto } = nodeSelectTimeline;

  async function getIdUser() {
    const userPermissions = await getUser();
    User.current = userPermissions;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const loadArryNodesHasFile = async () => {
    const response = await api.get(`moduleschoice/${nodeselect.id}/${idprojeto}`);
    setPrevNodes(response.data.data.modules);
  };

  useEffect(() => {
    loadArryNodesHasFile();
    getIdUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeselect]);

  return (
    <Box sx={{ width: 1200 }}>
      {nodeselect.properties !== null &&
        nodeselect.properties !== undefined &&
        nodeselect.properties.REFS !== undefined &&
        nodeselect.properties.REFS.length > 0 && (
          <>
            <Tooltip title={i18next.t('flow-Modals-ref.tooltip')}>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                  marginTop: 2,
                  width: 50,
                  height: 50,
                  backgroundColor: 'background.neutral',
                  color: 'text.secondary',
                }}
              >
                <Icon icon={quickReference} fontSize={60} />
              </Button>
            </Tooltip>
            <Dialog
              onClose={handleClickClose}
              open={open}
              maxWidth="1200px"
              fullWidth
              sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
            >
              <>
                <IconButton
                  onClick={handleClickClose}
                  id="Botão fechar modal"
                  sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogTitle>{i18next.t('modalReferences.title')}</DialogTitle>
                <DialogContent>
                  <Typography variant="h4" sx={{ textAlign: 'center', marginTop: 1 }}>
                    {i18next.t('modalReferences.subTitle')}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginTop: '1rem' }}>
                    {prevNodes.map((node, i) => (
                      <Card key={`node_ref-${i}`} sx={{ width: '22%', maxHeight: '100%' }}>
                        <Typography sx={{ textAlign: 'center' }} variant="h6">
                          {i18next.t('stages-Flow.stage')} {node.moduloestagio}
                        </Typography>
                        <CardMedia
                          sx={{ width: 50, height: 50, margin: '0 auto' }}
                          image={`${(() => moduleProperties.find((nds) => nds.typeBack === node.modulotipo).img)()}`}
                          alt={`icone do modulo de ${(() =>
                            moduleProperties.find((nds) => nds.typeBack === node.modulotipo).categ)()}`}
                        />
                        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            component="div"
                            sx={{ width: '80%', fontWeight: 'bold' }}
                          >
                            {node.modulotxt}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{height:'5vh'}}>
                            {node.modulodesc === null || node.modulodesc.length <= 0
                              ? `${i18next.t('selectedFieldRef.notDescriptionNode')}`
                              : `${node.modulodesc.slice(0, 40)} ...`}
                          </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', gap: '0.3rem', marginBottom: 0 }}>
                          {node.annexs.length > 0 && <ModalRefAnexo annexs={node.annexs} userCurrent={User.current} />}
                          {node.modulotipo === 'FORM' && (
                            <ModalEscolhaFormExecution module={prevNodes[i]} node={nodeselect} idprojeto={idprojeto} />
                          )}
                        </Box>
                      </Card>
                    ))}
                    {prevNodes.length <= 0 && (
                      <Typography variant="h5">{i18next.t('modalReferences.notReferences')}</Typography>
                    )}
                  </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'end' }}>
                  <Button
                    onClick={() => {
                      handleClickClose();
                    }}
                    variant="contained"
                    color="success"
                  >
                    {i18next.t('flow-Modals.confirm-button')}
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          </>
        )}
      {nodeselect.properties === null && nodeselect.properties === undefined && <></>}
    </Box>
  );
}
