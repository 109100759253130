import { useState, useEffect } from 'react';
// @mui
import { Box, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// layouts
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// components
import Page from '../components/Page';
import ReportCard from '../components/ReportCard';
// icon
import engineering from '../assets/reports/engineering.png';
import performance from '../assets/reports/performance.png';
import projectStatus from '../assets/reports/to-do-list.png';
import statusUser from '../assets/reports/status-user.png';
import projectOverdue from '../assets/reports/projects-overdue-area.png';
import laggingAreas from '../assets/reports/cancel.png';
import laggingProduct from '../assets/reports/warning.png';
import auditTrail from '../assets/reports/audit-trail-search.png';
import customReport from '../assets/reports/file-cog.svg'
import projectStatusReport from '../assets/reports/file-chart.svg'
import userOvedue from '../assets/reports/user-clock.svg'
import { StyledContainer } from '../components/styled-global';
// store
import { userStore } from '../store/userStore';

// ----------------------------------------------------------------------

export default function Report() {
  const [open, setOpen] = useState(true);
  const [adm, setAdm] = useState(false);
  const { t } = useTranslation();
  const getUserPermissions = userStore((state) => state.getUser);

  const PerfilPermission = async () => {
    const userPermissions = await getUserPermissions();
    const isAdmin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
    setAdm(isAdmin);
  };

  useEffect(() => {
    PerfilPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={t('report.title')} sx={{overflowX: 'hidden'}}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <StyledContainer >
        <Typography variant="h4" sx={{ mb: 3 }}>
          {t('report.title')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'space-evenly',
            justifyContent: 'flex-start',
            gap: '1rem',
            width: '120%',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportUserTitle')}
              description={t('reports.reportUserDescription')}
              icon={engineering}
              user
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportPerformanceTitle')}
              description={t('reports.reportPerformanceDescription')}
              icon={performance}
              performanceIndicator
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportStatusUserTitle')}
              description={t('reports.reportStatusUserDescription')}
              icon={statusUser}
              userStatus
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportStatusProjectTitle')}
              description={t('reports.reportStatusProjectDescription')}
              icon={projectStatus}
              projectStatus
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportProjectOverdueAreaTitle')}
              description={t('reports.reportProjectOverdueAreaTitle')}
              icon={projectOverdue}
              projectsOverdueArea
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportAreaOverdueTitle')}
              description={t('reports.reportAreaOverdueDescription')}
              icon={laggingAreas}
              laggingAreas
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportProductsOverdueTitle')}
              description={t('reports.reportProductsOverdueDescription')}
              icon={laggingProduct}
              laggingProduct
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportProjectsByStatusTitle')}
              description={t('reports.reportProjectsByStatusDescription')}
              icon={projectStatusReport}
              projectsStatus
            />
          </Box>
          {/* <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportProjectsByStatusTitle')}
              description={t('reports.reportProjectsByStatusDescription')}
              icon={projectStatusReport}
              projectsStatus
            />
          </Box> */}
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportCustomTitle')}
              description={t('reports.reportCustomDescription')}
              icon={customReport}
              customReport
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <ReportCard
              title={t('reports.reportOverdueUserTitle')}
              description={t('reports.reportOverdueUserDescription')}
              icon={userOvedue}
              overdueUser
            />
          </Box>
          {adm === true ? (
            <Box sx={{ width: '20%' }}>
              <ReportCard
                title={t('reports.reportAuditTrailTitle')}
                description={t('reports.reportAuditTrailDescription')}
                icon={auditTrail}
                auditTrail
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </StyledContainer>
    </Page>
  );
}
