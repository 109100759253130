import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

import { useTranslation } from 'react-i18next';

import { useQueryClient, useMutation, useQuery } from 'react-query';

import {
  Typography,
  Box,
  FormControl,
  Card,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  styled,
  IconButton,
  Modal,
} from '@mui/material';

import moment from 'moment/moment';

import closeIcon from '@iconify/icons-material-symbols/close';

import { Icon } from '@iconify/react';

import { NameProjectsEnum } from '../../../../../enum/NameProjectsEnum';

import CalendarInput from '../../../../CalendarInput';

import { AddProductsModal } from '../../products/modals';

import { StyledButton } from '../../../../styled-global';

import api from '../../../../../service/api';

import ConnectAreasAndUsers from '../../../../ConnectAreasAndUsers';

import { textfieldMaxLength, textfieldMaxLengthModuleDesc } from '../../../../../utils/textfieldMaxLength';

const StyledCard = styled(Card)(() => ({
  padding: 25,

  width: '80%',
  position: 'relative',
}));

const TagBox = styled(Box)(() => ({
  height: '1.3rem',
  width: 50,
  marginLeft: 5,
  borderRadius: 16,
  boxShadow: '2px 1px 8px #808080',
  minWidth: 'max-content',
}));

const TextFieldWithPositionedErrors = styled(TextField)(() => ({
  '.css-16d5wub-MuiFormHelperText-root': { position: 'absolute', top: 15, right: 20 },
}));

export default function NewProjectModal({ isModalOpen, setModalOpen, products, setProducts, tags, rowsPerPage, page }) {
  const [projectName, setProjectName] = useState('');

  const [selectedProduct, setSelectedProduct] = useState('');

  const [projectTag, setProjectTag] = useState(null);

  const [addModalOpen, setAddModalOpen] = useState(false);

  const [date, setDate] = useState(null);

  const [dateError, setDateError] = useState('');

  const [baseProjectCheckBox, setBaseProjectCheckBox] = useState(0);

  const [projectDescription, setProjectDescription] = useState('');

  const [selectedProject, setSelectedProject] = useState(null);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [selectedAreas, setSelectedAreas] = useState([]);

  const [areasUsers, setAreasUsers] = useState(null);

  const [duplicateProject, setDuplicateProject] = useState(null);

  const [equalNameError, setEqualNameError] = useState(false);

  const [similarNameWarning, setSimilarNameWarning] = useState(false);

  const [emptyNameError, setEmptyNameError] = useState(false);

  const [tagOptionsForProject, setTagOptionsForProject] = useState([]);

  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const usersCache = queryClient.getQueryData(['users']);
  const usersActiveCache = queryClient.getQueryData(['usersActive']);
  const areas = queryClient.getQueryData(['areas']);

  const { data: baseProjects } = useQuery(['projectsBase'], () => getProjectsBase(), {
    staleTime: 5000,
  });


  const getProjectsBase = async () => {
    try {
      const baseProjectsResponse = await api.get('projects/1');

      return baseProjectsResponse.data.data.projects;
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }

    return null;
  };

  // Setar apenas as Tags tipo Tag para Projeto
  useEffect(() => {
    const tagsTipoTag = tags.filter((tag) => tag.tagtipo === 'Tag' && tag.tagstatus === 1);

    setTagOptionsForProject(tagsTipoTag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const postMutation = useMutation(() => postProject(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['projects', page, rowsPerPage]);
    },
    onError: (error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    },
  });

  const postProject = async () => {
    try {
      const tagId = projectTag?.idtag;

      const newProject = {
        name: projectName,
        description: projectDescription,
        base: baseProjectCheckBox,
        projetovinculo: areasUsers,
        duplicate: duplicateProject,
      };

      if (selectedProduct !== []) newProject.idproduto = selectedProduct.idproduto;
      if (tagId !== null) newProject.idtag = tagId;
      if (date !== null) newProject.dtconclusion = date.format('YYYY-MM-DD');

      if (projectName.length > textfieldMaxLength) {
        toast.error(t('projects.edit-projects.project-name-maxLength', { textfieldMaxLength }));
      }

      if (projectName.length <= textfieldMaxLength) {
        const projectsResponse = await api.post('project', newProject);
        if (projectsResponse.status === 201) {
          toast.success(t('projects.new-project-form.toast-success-project-created'));
          clearInputs();
          setModalOpen(false);
        }
      }

      // setProjects((prevState) => [...prevState, projectsResponse.data.data.project]);
    } catch (error) {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      else {
        console.log(error);
        toast.error(t('projects.new-project-form.errors.post-error'));
      }
    }
  };

  const autocompleteTagInputHandler = (inputValue) => {
    setProjectTag(inputValue);
  };

  const autocompleteBaseProjectInputHandler = (inputValue) => {
    setDuplicateProject(inputValue?.idprojeto);
    setSelectedProject(inputValue);

    if (!inputValue) {
      setDuplicateProject(null);
      clearInputs();
    }
  };

  // eslint-disable-next-line consistent-return
  const checkAreaAndUsersWasSelected = () => {
    if (selectedAreas.length > 0) {
      return true;
    }
    if (selectedUsers.length > 0 || selectedAreas.length > 0) {
      return false;
    }
  };

  const createProjectHandler = () => {
    if (projectName.trim() === '') {
      setEmptyNameError(true);
      return;
    }

    if (equalNameError) return;

    const now = moment(new Date(), 'YYYY-MM-DD');

    if (date !== null) {
      if (date < now) {
        setDateError(t('projects.new-project-form.errors.future-date'));
        return;
      }
      if (!date._isValid) {
        setDateError(t('projects.new-project-form.errors.invalid-date'));
        return;
      }
    }

    if ((baseProjectCheckBox === 0 && selectedProduct === '') || selectedProduct === null) {
      toast.error(t('projects.new-project-form.errors.no-product'));
      return;
    }

    if (checkAreaAndUsersWasSelected()) {
      postMutation.mutate();
      // clearInputs();
      // setModalOpen(false);
    } else {
      toast.error(t('projects.new-project-form.errors.no-areas-users'));
    }
  };

  function clearInputs() {
    setEqualNameError(false);
    setSimilarNameWarning(false);
    setEmptyNameError(false);
    setProjectName('');
    setSelectedProduct('');
    setProjectTag(null);
    setDate(null);
    setProjectDescription('');
    setSelectedAreas([]);
    setSelectedUsers([]);
  }

  useEffect(() => {
    if (baseProjectCheckBox === 1) {
      setSelectedProduct('');
      setDate(null);
    }
  }, [baseProjectCheckBox]);

  const checkboxInputHandler = () => {
    if (baseProjectCheckBox === 0) {
      setBaseProjectCheckBox(1);
    }
    if (baseProjectCheckBox === 1) {
      setBaseProjectCheckBox(0);
    }
  };

  // Atualização de campos de acordo com o projeto base escolhido
  useEffect(() => {
    if (selectedProject) {
      if (selectedProject.idtag !== null) {
        const [projectTag] = tags.filter((tag) => tag.idtag === selectedProject.idtag);
        setProjectTag(projectTag);
      }

      if (selectedProject.projetodtconclusao !== null) {
        const dateFromBaseProject = selectedProject.projetodtconclusao;
        const dateMomentObject = moment(dateFromBaseProject, 'YYYY-MM-DD');
        setDate(dateMomentObject);
      }

      const projetoVinculo = selectedProject.projetovinculo;
      if (projetoVinculo.area !== undefined) {
        const filteredAreas = projetoVinculo.area.map((areaId) => {
          const [areaObj] = areas?.filter((area) => area.idarea === areaId);
          return areaObj;
        });
        setSelectedAreas(filteredAreas);
      }
      if (projetoVinculo.usuario !== undefined) {
        const filteredUsers = projetoVinculo.usuario.map((userId) => {
          const [userObj] = usersCache?.filter((user) => user.idusuario === userId);
          return userObj;
        });
        setSelectedUsers(filteredUsers);
      }

      setProjectDescription(selectedProject.projetodescricao);
    }
  }, [selectedProject, products, tags, areas, usersCache]);

  const checkNameInputValidation = async (inputValue) => {
    if (inputValue.length > 0) {
      const hasProjectWithNameEqual = await getAllNamesProject(inputValue);

      if (hasProjectWithNameEqual === NameProjectsEnum.similar) {
        setSimilarNameWarning(true);
      }
      if (hasProjectWithNameEqual === NameProjectsEnum.equal) {
        setEqualNameError(true);
      }
      if (hasProjectWithNameEqual === NameProjectsEnum.notExist) {
        setEqualNameError(false);
        setSimilarNameWarning(false);
      }
      setEmptyNameError(false);
    }

    if (inputValue.length === 0 && inputValue.trim() !== projectName.trim()) {
      setEqualNameError(false);
      setSimilarNameWarning(false);
      setEmptyNameError(true);
    }
  };

  const getAllNamesProject = async (name) => {
    const response = await api.get(`/projectsname/${name}`);
    return response.data.data.exists;
  };

  const selectNameErrorMessage = () => {
    if (equalNameError) return t('projects.new-project-form.errors.already-has-name');
    if (similarNameWarning) return t('projects.new-project-form.errors.similar-project-name');
    if (emptyNameError) return t('projects.new-project-form.errors.project-must-have-name');
    if (projectName.length > textfieldMaxLength)
      return t('projects.edit-projects.project-name-maxLength', { textfieldMaxLength });

    return null;
  };

  useEffect(() => {
    if (projectName !== undefined && projectName !== null) {
      checkNameInputValidation(projectName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName]);

  const closeModal = () => {
    setSelectedProject(null);
    setModalOpen(false);
    clearInputs();
  };

  return (
    <Modal
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}
      open={isModalOpen}
      onClose={closeModal}
    >
      <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <AddProductsModal
          isModalOpen={addModalOpen}
          setModalOpen={() => {
            setAddModalOpen(false);
          }}
          currentItems={products}
          triggerPageRerender={setProducts}
          tags={tags}
          autoInputProduct={setSelectedProduct}
        />
        <StyledCard>
          <IconButton
            onClick={closeModal}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <Icon icon={closeIcon} fontSize={32} />
          </IconButton>
          <Typography variant="h5" textAlign="center" sx={{ marginBottom: 1 }}>
            {t('projects.new-project-form.heading')}
          </Typography>
          <FormControl sx={{ display: 'flex', justifyContent: 'center', gap: 3, height: '100%', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <FormControlLabel
                variant="outline"
                sx={{ width: '20%' }}
                control={<Checkbox />}
                label={t('projects.new-project-form.base-project')}
                onChange={checkboxInputHandler}
                checked={baseProjectCheckBox === 1}
              />
              <Autocomplete
                id="select-base-projects"
                options={baseProjects}
                getOptionLabel={(option) => option.projetonome}
                onChange={(_, value) => autocompleteBaseProjectInputHandler(value)}
                value={selectedProject || null}
                sx={{ width: '100%' }}
                isOptionEqualToValue={(option, value) => option.idprojeto === value.idprojeto}
                renderInput={(params) => (
                  <TextField {...params} label={t('projects.new-project-form.load-base-label')} />
                )}
                noOptionsText={t('projects.new-project-form.no-options.base-projects')}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1 }}>
              <TextFieldWithPositionedErrors
                value={projectName}
                error={equalNameError || emptyNameError}
                helperText={selectNameErrorMessage()}
                label={t('projects.new-project-form.project-name-label')}
                variant="outlined"
                color="primary"
                sx={{ width: '100%' }}
                onChange={(event) => setProjectName(event.target.value)}
                inputProps={{ maxLength: textfieldMaxLength }}
              />
              <TextField
                id="descrição-id"
                label={t('projects.new-project-form.project-description-label')}
                variant="outlined"
                multiline
                maxRows={2}
                sx={{ width: '100%' }}
                onChange={(event) => setProjectDescription(event.target.value)}
                value={projectDescription}
                inputProps={{ maxLength: textfieldMaxLengthModuleDesc }}
              />
            </Box>
            <ConnectAreasAndUsers
              areas={areas}
              selectedAreas={selectedAreas}
              setSelectedAreas={setSelectedAreas}
              users={usersActiveCache}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              setAreasUsersData={setAreasUsers}
              style={{ gap: 1 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, transition: 'ease' }}>
              <Autocomplete
                onChange={(_, inputValue) => setSelectedProduct(inputValue)}
                disabled={baseProjectCheckBox === 1}
                id="selected-product"
                options={products}
                noOptionsText={t('projects.new-project-form.no-options.products')}
                getOptionLabel={(option) => option.produtonome}
                isOptionEqualToValue={(option, value) => option.idproduto === value.idproduto}
                sx={{ width: '100%', zIndex: 10 }}
                renderInput={(params) => <TextField {...params} label={t('projects.new-project-form.product-label')} />}
                value={selectedProduct || null}
              />
              <StyledButton
                disabled={baseProjectCheckBox === 1}
                style={baseProjectCheckBox === 1 && { borderColor: '#ccc' }}
                contained
                onClick={() => setAddModalOpen(true)}
              >
                {t('projects.new-project-form.create-product-button')}
              </StyledButton>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1 }}>
              <Autocomplete
                onChange={(_, value) => autocompleteTagInputHandler(value)}
                noOptionsText={t('products.modals.add-products.no-tags')}
                id="selected-tag"
                options={tagOptionsForProject}
                sx={{ width: '100%' }}
                value={projectTag || null}
                getOptionLabel={(option) => option.tagnome}
                isOptionEqualToValue={(option, value) => option.idtag === value.idtag}
                renderInput={(params) => {
                  let selectedTag;
                  if (params.inputProps.value !== '') {
                    [selectedTag] = tagOptionsForProject.filter((tag) => tag.tagnome === params.inputProps.value);
                  }
                  return (
                    <Box sx={{ position: 'relative' }}>
                      <TextFieldWithPositionedErrors {...params} label="Tag" />

                      {tagOptionsForProject.map((tag) => {
                        if (params.inputProps.value === tag.tagnome) {
                          return (
                            <TagBox
                              key={tag.idtag}
                              sx={{
                                backgroundColor: selectedTag?.tagcolor,
                                marginLeft: '12px',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                paddingX: 1,
                                paddingY: 0.5,
                                borderRadius: '16px',
                                display: 'flex',
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  color: '#fff',
                                  textShadow: '1px 1px 1px #000',
                                }}
                              >
                                {selectedTag.tagnome}
                              </Typography>
                            </TagBox>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  );
                }}
                renderOption={(props, option) => (
                  <li key={option.idtag} {...props}>
                    <Box style={{ marginRight: 8 }}>
                      <TagBox
                        sx={{
                          backgroundColor: option.tagcolor,
                          marginLeft: '15px',
                          alignItems: 'center',
                          paddingX: 1,
                          paddingY: 0.5,
                          borderRadius: '16px',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            color: '#fff',
                            textShadow: '1px 1px 1px #000',
                          }}
                        >
                          {option.tagnome}
                        </Typography>
                      </TagBox>
                    </Box>
                  </li>
                )}
              />
              <CalendarInput
                setDate={setDate}
                date={date}
                error={dateError}
                setError={setDateError}
                disabled={baseProjectCheckBox === 1}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <StyledButton contained style={{ width: '20%' }} onClick={createProjectHandler}>
                {t('projects.new-project-form.create-project-button')}
              </StyledButton>
            </Box>
          </FormControl>
        </StyledCard>
      </Box>
    </Modal>
  );
}
