/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
// import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, Box } from '@mui/material';
import { StyledBoldTypography, StyledButton } from '../../../../styled-global';

// Estilos -------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));

const InputBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,
}));

// ---------------------------------------------------------------------------

export default function ConfirmChangeStatusModal({ isModalOpen, setModalOpen, confirmButtonHandler, itemToBeEdited }) {
  const { t } = useTranslation();
  return (
    <ModalStyle open={isModalOpen} onClose={() => setModalOpen(false)}>
      <GridStyle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <InputBoxStyle sx={{ flexDirection: 'column', justifyContent: 'center', gap: 10, maxWidth: '90%', flex: 1 }}>
          <Typography variant="p" fontSize={30} textAlign={'center'}>
            {t('areas.confirm-modal.wish')}{' '}
            <StyledBoldTypography>
              {itemToBeEdited?.areastatus === 'ativo'
                ? t('areas.confirm-modal.deactivate')
                : t('areas.confirm-modal.activate')}
            </StyledBoldTypography>{' '}
            {t('areas.confirm-modal.the-area')} <StyledBoldTypography>{itemToBeEdited?.areanome}</StyledBoldTypography>
          </Typography>
          <InputBoxStyle>
            <StyledButton
              contained
              onClick={() => confirmButtonHandler(itemToBeEdited.areanome, itemToBeEdited.areastatus)}
            >
              {t('areas.edit-area-modal.confirm-modal.confirm-button')}
            </StyledButton>
            <StyledButton onClick={() => setModalOpen(false)}>
              {t('areas.edit-area-modal.confirm-modal.decline-button')}
            </StyledButton>
          </InputBoxStyle>
        </InputBoxStyle>
      </GridStyle>
    </ModalStyle>
  );
}
