/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
// @mui
import { Button, DialogActions, Tooltip } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';
// icon
import { Icon } from '@iconify/react';
import iconDoc from '../assets/doc.svg';
// api
import api from '../service/api';
import ConnectAreasAndUsers from './ConnectAreasAndUsers';

//-----------------------------------------------------------------------

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const drop = {
  border: '1px solid #eaeaea',
  borderRadius: '5px',
  width: '100%',
  padding: '2rem',
  margin: ' 5% auto',
};
//-------------------------------------------------------------------------

export default function UploadFiles({ handleClose, produtosId, path, requestRepo }) {
  const [files, setFiles] = useState([]);
  const [thumbs, setThumbs] = useState([]);

  // modificações
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasUsers, setAreasUsers] = useState(null);

  const { t } = useTranslation();

  const requestAreas = async () => {
    try {
      const responseAreas = await api.get(`/areas`);
      const areasBeforeTransformation = responseAreas.data.data.areas;
      const newAreas = areasBeforeTransformation.map((area) => ({
        idarea: area.idarea,
        areanome: area.areanome,
        areastatus: area.areastatus === 0 ? 'desativado' : 'ativo',
      }));

      setAreas(newAreas);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  const requestUsers = async () => {
    try {
      const response = await api.get(`/users`);
      setUsers(response.data.data.users);
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
    }
  };

  useEffect(() => {
    requestAreas();
    requestUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      renderThumbs(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple: true,
  });

  const handleChange = () => {
    files.forEach(async (file) => {
      const formData = new FormData();
      formData.append('idproduct', produtosId);
      formData.append('destination', path);
      formData.append('privacidade', areasUsers);
      formData.append('repositoryanexo', file);

      try {
        const response = await api.post(`/repositoryanexo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200 || response.status === 201) {
          toast.success(`${response.data.data.message}`);
          requestRepo(produtosId, path);
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          // toast.error(`${error.response.data.data.errors[0]}`);
          toast.error(`${t('not-Access')}`);
        } else {
          toast.error(`${t('validation.profileedit.uptade-photo.error')}`);
        }
      }
    });
    handleClose();
  };

  const renderThumbs = (files) => {
    const thumbsTmp = files.map((file) => {
      const regex = /image/g;
      const imageOrDoc = regex.test(file.type);
      return (
        <Tooltip title={file.name} key={file.name}>
          <div>
            <div style={thumb}>
              <div style={thumbInner}>
                <img
                  src={imageOrDoc === true ? file.preview : iconDoc}
                  style={img}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
              </div>
            </div>
            <p>{file.name.slice(0, 10)}...</p>
          </div>
        </Tooltip>
      );
    });
    setThumbs(thumbsTmp);
  };

  return (
    <div>
      <section className="container" style={drop}>
        <div style={{ padding: '2rem' }} {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>{t('repo.upload-modal.info-text')}</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
      <ConnectAreasAndUsers
        areas={areas}
        selectedAreas={selectedAreas}
        setSelectedAreas={setSelectedAreas}
        users={users}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        setAreasUsersData={setAreasUsers}
        style={{ marginTop: 3 }}
        label="Privacidade"
        privacidade
      />
      <DialogActions>
        <Button variant="contained" onClick={handleChange} color="success">
          <Icon icon="material-symbols:cloud-upload" width={24} height={24} />
        </Button>
        <Button variant="contained" onClick={handleClose}>
          {t('repo.upload-modal.cancel')}
        </Button>
      </DialogActions>
    </div>
  );
}

<UploadFiles />;
