export const textfieldMaxLength = 100

// * Usuario
// - usuarionome (100)
// - usuarioemail (100)
export const textfieldMaxLengthUser = 100

// * Area
// - nomearea (50)
// - agrupamento (50)
export const textfieldMaxLengthArea = 50 

// * Modulo
// - modulotxt (100)
// - modulodesc (255)
export const textfieldMaxLengthModuleName = 100 
export const textfieldMaxLengthModuleDesc = 255 

// * Produto
// - produtonome (100)
// - produtocodigo (20)
export const textfieldMaxLengthProduct = 100 
export const textfieldMaxLengthProductCode = 20 

// * Tag
// - tagnome (50)
export const textfieldMaxLengthTagNome = 50 