import i18next from 'i18next';
import { imagens } from '../assets/imagens';
import ModalForm from '../creation/components/Modal/ModalForm';
import ModalAnexo from '../execution/components/Modal/ModalAnexo';
import ModalFormulario from '../execution/components/Modal/ModalFormulario';
import ModalTempo from '../creation/components/Modal/ModalTempo';
import ModalAlertaInterno from '../creation/components/Modal/ModalAlertaInterno';
import ModalTag from '../creation/components/Modal/ModalTag';
import ModalPrivacidade from '../creation/components/Modal/ModalPrivacidade';
import ModalTempoLimite from '../creation/components/Modal/ModalTempoLimite';
import ModalEscolhaAnexo from '../execution/components/Modal/ModalEscolhaAnexo';
import ModalSubstituicao from '../execution/components/Modal/ModalSubstituicao';
import ModalReferencia from '../creation/components/Modal/ModalReferencia';
// import ModalReferenciaExecution from '../execution/components/Modal/ModalReferenciaExecution';

let idModal = 1;
// eslint-disable-next-line no-plusplus
const getIdModal = () => idModal++;

export const moduleProperties = [
  {
    type: `${i18next.t('types-modules.initial')}`,
    descHelp: `${i18next.t('types-modules-help.initial')}`,
    typeBack: 'INICIAL',
    categBack: 'CONTROL',
    categ: `${i18next.t('module-category.control')}`,
    img: imagens.emptyFile,
    modal: [<ModalAlertaInterno key={`modal-${getIdModal()}`} />],
    modalTimeline: [<ModalAnexo key={`modal-${getIdModal()}`} />],
  },
  {
    type: `${i18next.t('types-modules.form')}`,
    descHelp: `${i18next.t('types-modules-help.form')}`,
    typeBack: 'FORM',
    categBack: 'ACAO',
    categ: `${i18next.t('module-category.action')}`,
    img: imagens.form,
    modal: [
      <ModalForm key={`modal-${getIdModal()}`} />,
      <ModalAlertaInterno key={`modal-${getIdModal()}`} />,
      <ModalPrivacidade key={`modal-${getIdModal()}`} />,
      <ModalTempoLimite key={`modal-${getIdModal()}`} />,
      <ModalReferencia key={`modal-${getIdModal()}`} />,
    ],
    modalTimeline: [
      <ModalFormulario key={`modal-${getIdModal()}`} />,
      <ModalAnexo key={`modal-${getIdModal()}`} />,
      // <ModalReferenciaExecution key={`modal-${getIdModal()}`} />,
    ],
  },
  {
    type: `${i18next.t('types-modules.attendance')}`,
    descHelp: `${i18next.t('types-modules-help.attendance')}`,
    typeBack: 'ACOMPANHA',
    categBack: 'ACAO',
    categ: `${i18next.t('module-category.action')}`,
    img: imagens.fileFill,
    modal: [
      <ModalPrivacidade key={`modal-${getIdModal()}`} />,
      <ModalAlertaInterno key={`modal-${getIdModal()}`} />,
      <ModalTempoLimite key={`modal-${getIdModal()}`} />,
      <ModalReferencia key={`modal-${getIdModal()}`} />,
    ],
    modalTimeline: [
      <ModalAnexo key={`modal-${getIdModal()}`} />,
      // <ModalReferenciaExecution key={`modal-${getIdModal()}`} />,
    ],
  },
  {
    type: `${i18next.t('types-modules.attachment')}`,
    descHelp: `${i18next.t('types-modules-help.attachment')}`,
    typeBack: 'ANEXO',
    categBack: 'ACAO',
    categ: `${i18next.t('module-category.action')}`,
    img: imagens.anexo,
    modal: [
      <ModalPrivacidade key={`modal-${getIdModal()}`} />,
      <ModalAlertaInterno key={`modal-${getIdModal()}`} />,
      <ModalTempoLimite key={`modal-${getIdModal()}`} />,
      <ModalReferencia key={`modal-${getIdModal()}`} />,
    ],
    modalTimeline: [
      <ModalEscolhaAnexo key={`modal-${getIdModal()}`} />,
      // <ModalReferenciaExecution key={`modal-${getIdModal()}`} />,
    ],
  },
  {
    type: `${i18next.t('types-modules.replacement')}`,
    descHelp: `${i18next.t('types-modules-help.replacement')}`,
    typeBack: 'SUBST',
    categBack: 'ACAO',
    categ: `${i18next.t('module-category.action')}`,
    img: imagens.substituir,
    modal: [
      <ModalPrivacidade key={`modal-${getIdModal()}`} />,
      <ModalAlertaInterno key={`modal-${getIdModal()}`} />,
      <ModalTempoLimite key={`modal-${getIdModal()}`} />,
      <ModalReferencia key={`modal-${getIdModal()}`} />,
    ],
    modalTimeline: [
      <ModalSubstituicao key={`modal-${getIdModal()}`} />,
      // <ModalReferenciaExecution key={`modal-${getIdModal()}`} />,
    ],
  },
  {
    type: `${i18next.t('types-modules.repository')}`,
    descHelp: `${i18next.t('types-modules-help.repository')}`,
    typeBack: 'REPO',
    categBack: 'RESP',
    categ: `${i18next.t('module-category.answer')}`,
    img: imagens.repositorio,
    modal: [<ModalAlertaInterno key={`modal-${getIdModal()}`} />],
    modalTimeline: [],
  },
  {
    type: `${i18next.t('types-modules.email')}`,
    descHelp: `${i18next.t('types-modules-help.email')}`,
    typeBack: 'EMAIL',
    categBack: 'RESP',
    categ: `${i18next.t('module-category.answer')}`,
    img: imagens.email,
    modal: [],
    modalTimeline: [],
  },
  {
    type: `${i18next.t('types-modules.alert')}`,
    descHelp: `${i18next.t('types-modules-help.alert')}`,
    typeBack: 'ALERTA',
    categBack: 'RESP',
    categ: `${i18next.t('module-category.answer')}`,
    img: imagens.alert,
    modal: [],
    modalTimeline: [],
  },
  {
    type: `${i18next.t('types-modules.tag')}`,
    descHelp: `${i18next.t('types-modules-help.tag')}`,
    typeBack: 'TAG',
    categBack: 'RESP',
    categ: `${i18next.t('module-category.answer')}`,
    img: imagens.tags,
    modal: [<ModalAlertaInterno key={`modal-${getIdModal()}`} />, <ModalTag key={`modal-${getIdModal()}`} />],
    modalTimeline: [],
  },
  {
    type: `${i18next.t('types-modules.time')}`,
    descHelp: `${i18next.t('types-modules-help.time')}`,
    typeBack: 'TEMPO',
    categBack: 'CONTROL',
    categ: `${i18next.t('module-category.control')}`,
    img: imagens.time,
    modal: [<ModalAlertaInterno key={`modal-${getIdModal()}`} />, <ModalTempo key={`modal-${getIdModal()}`} />],
    modalTimeline: [],
  },
  {
    type: `${i18next.t('types-modules.unity')}`,
    descHelp: `${i18next.t('types-modules-help.unity')}`,
    typeBack: 'UNIAO',
    categBack: 'CONTROL',
    categ: `${i18next.t('module-category.control')}`,
    img: imagens.merge,
    modal: [<ModalAlertaInterno key={`modal-${getIdModal()}`} />],
    modalTimeline: [],
  },
  {
    type: `${i18next.t('types-modules.checkpoint')}`,
    descHelp: `${i18next.t('types-modules-help.checkpoint')}`,
    typeBack: 'CHECK',
    categBack: 'CONTROL',
    categ: `${i18next.t('module-category.control')}`,
    img: imagens.checkList,
    modal: [<ModalAlertaInterno key={`modal-${getIdModal()}`} />],
    modalTimeline: [],
  },
  {
    type: `${i18next.t('types-modules.sequence')}`,
    descHelp: `${i18next.t('types-modules-help.sequence')}`,
    typeBack: 'SEQ',
    categBack: 'CONTROL',
    categ: `${i18next.t('module-category.control')}`,
    img: imagens.next,
    modal: [<ModalAlertaInterno key={`modal-${getIdModal()}`} />],
    modalTimeline: [],
  },
];

const createNode = (
  id,
  position,
  data,
  parentNode,
  categoryNode,
  typeNode,
  modulotxt,
  modulodesc,
  properties,
  alertalista,
  tempolimite,
  drag,
  status,
  moduloresponsaveis
) => {
  const node = {
    id,
    type: 'customnode',
    position,
    data,
    parentNode,
    extent: 'parent',
    categoryNode,
    typeNode,
    modulotxt,
    modulodesc,
    draggable: drag,
    properties,
    alertalista,
    tempolimite,
    status,
    moduloresponsaveis,
  };
  return node;
};

const createStage = (id, position, data, style) => {
  const stage = {
    id,
    type: 'customnodeGroup',
    data,
    position,
    style,
    draggable: false,
  };
  return stage;
};

const initialNodes = () => {
  const nodes = [
    {
      id: '0',
      type: 'customnodeGroup',
      data: { label: `${i18next.t('stages-Flow.stageInit')}` },
      position: { x: 0, y: 0 },
      style: { width: 100, height: 150 },
      draggable: false,
    },
    {
      id: 'a',
      type: 'customnode',
      position: { x: 22, y: 20 },
      data: { label: 'Inicio do projeto', imagem: imagens.emptyFile },
      parentNode: '0',
      extent: 'parent',
      categoryNode: 'CONTROL',
      typeNode: 'INICIAL',
      modulotxt: `${i18next.t('infos-flow.boot-module')}`,
      modulodesc: `${i18next.t('infos-flow.boot-module-desc')}`,
      draggable: true,
      properties: {},
      fakeNode: true,
    },
  ];
  return nodes;
};

const managementNode = {
  createNode,
  createStage,
  initialNodes,
};

export default managementNode;
