export const checkIfFileIsImage = (filePath) => {
  let fileExtension;
  const slicedFileName = filePath.slice(-4);
  if (slicedFileName.includes('.')) {
    fileExtension = slicedFileName.split('.')[1];
  } else {
    fileExtension = slicedFileName;
  }
  if (
    fileExtension.toLowerCase() === 'apng' ||
    fileExtension.toLowerCase() === 'avif' ||
    fileExtension.toLowerCase() === 'gif' ||
    fileExtension.toLowerCase() === 'gif' ||
    fileExtension.toLowerCase() === 'jpg' ||
    fileExtension.toLowerCase() === 'jpeg' ||
    fileExtension.toLowerCase() === 'jfif' ||
    fileExtension.toLowerCase() === 'pjpeg' ||
    fileExtension.toLowerCase() === 'pjp' ||
    fileExtension.toLowerCase() === 'png' ||
    fileExtension.toLowerCase() === 'svg' ||
    fileExtension.toLowerCase() === 'webp'
  ) {
    return true;
  }
  return false;
};
