import { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Input,
  Stack,
  Typography,
  Switch,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
} from '@mui/material';
import toast from 'react-hot-toast';
import { Icon } from '@iconify/react';
import editIcon from '@iconify/icons-eva/edit-outline';

import { compareTwoStrings } from 'string-similarity';
import { textfieldMaxLengthTagNome } from '../../../utils/textfieldMaxLength'
import { TagsContext } from '../../../pages/Tags';
// api
import api from '../../../service/api';
// store
import { usePermission } from '../../../store/usePermission';

//------------------------------------------------------------------------

export default function DialogTags(props) {
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(props.tagcolor);
  const [checked, setChecked] = useState(props.tagstatus === 1);
  const [nome, setNome] = useState(props.tagnome);
  // eslint-disable-next-line no-unused-vars
  const [permissionView, setPermissionView] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const [similarNameWarning, setSimilarNameWarning] = useState(false);
  const [equalNameError, setEqualNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);

  const getUserPermissions = usePermission((state) => state.getUserPermission);
  const { t } = useTranslation();
  const tagsContext = useContext(TagsContext);
  // eslint-disable-next-line no-unused-vars
  const { tags, setTags } = tagsContext;

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const tagView = userPermissions.includes('tagViewing');
      const tagAdm = userPermissions.includes('tagAdministrating');
      setPermissionView(tagView);
      setPermissionAdmin(tagAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkNameInputValidation = (inputValue) => {
    setEmptyNameError(false);

    const tagsExcludingTagBeingEdited = tags.filter((tag) => tag.tagnome !== props.tagnome);

    const isProjectNameRepeated = tagsExcludingTagBeingEdited
      .map((tag) => tag.tagnome)
      .map((tagName) => tagName.toLowerCase() === inputValue.toLowerCase())
      .includes(true);

    const isProjectNameSimilar = tagsExcludingTagBeingEdited
      .map((tag) => tag.tagnome)
      .map((tagName) => compareTwoStrings(tagName.toLowerCase(), inputValue.toLowerCase()) > 0.8)
      .includes(true);

    setEqualNameError(isProjectNameRepeated);
    setSimilarNameWarning(isProjectNameSimilar);
  };

  // Validação do nome das Tags
  useEffect(() => {
    checkNameInputValidation(nome);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome]);

  const selectNameErrorMessage = () => {
    if (equalNameError) return t('tags.errors.equal-name');
    if (similarNameWarning) return t('tags.errors.similar-name');
    if (emptyNameError) return t('tags.errors.empty-name');
    return null;
  };

  const update = async (id) => {
    try {
      const response = await api.put(`/tag/${id}`, {
        tagnome: nome,
        tagcolor: color,
      });
      toast.success(`${t('tags.toats.success')}`);
      if (response.status === 200 || response.status === 201) {
        let novoStatus;
        if (checked) {
          novoStatus = 1;
        } else {
          novoStatus = 0;
        }

        const tags2 = tags.map((element) =>
          element.idtag === props.idtag && element.tagnome === props.tagnome
            ? { ...element, tagnome: nome, tagstatus: novoStatus, tagcolor: color }
            : element
        );
        setTags(tags2);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const atualizaDados = async (valor, id) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await api.put(`/tag/status/${id}`, {
        tagstatus: valor,
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const enviaCongfig = () => {
    let novoStatus;
    if (checked) {
      novoStatus = 1;
    } else {
      novoStatus = 0;
    }
    atualizaDados(novoStatus, props.idtag);
  };

  const ref = useRef(null);

  return (
    <div>
      {permissionAdmin === true ? (
        <IconButton
          ref={ref}
          onClick={() => {
            handleClickOpen();
          }}
        >
          <Icon icon={editIcon} sx={{ color: 'text.secondary' }} width={20} height={20} />
        </IconButton>
      ) : (
        ''
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.tagnome}</DialogTitle>
        <DialogContent>
          <DialogContentText>{`${t('tags.modal.nameTag')} ${props.tagnome}`}</DialogContentText>
          <DialogContentText sx={{ marginTop: '1rem' }}>{`${t('tags.modal.typeTag')} ${
            props.tagtipo
          }`}</DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label={`${t('tags.headCell.labelName')}`}
            type="string"
            fullWidth
            variant="standard"
            helperText={selectNameErrorMessage()}
            error={equalNameError || emptyNameError}
            defaultValue={props.tagnome}
            inputProps={{ maxLength: textfieldMaxLengthTagNome }}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
          {permissionAdmin === true ? (
            <Stack>
              <Typography sx={{ marginTop: '1rem', color: '#637381' }}>{t('tags.modal.statusTag')}</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{t('tags.deactivated')}</Typography>
                <Switch checked={checked} inputProps={{ 'aria-label': 'ant design' }} onChange={handleChange} />
                <Typography>{t('tags.active')}</Typography>
              </Stack>
            </Stack>
          ) : (
            ''
          )}

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ color: '#637381' }}>{t('tags.modal.colorTag')}</Typography>
            <Input
              id="color"
              label="Status da tag"
              sx={{ width: '15%', height: '3rem' }}
              variant="filled"
              type="color"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              if (equalNameError) return;
              if (nome.length >= 3) {
                enviaCongfig(props.idtag);
                update(props.idtag);
                handleClose();
              } else {
                setEmptyNameError(true);
              }
            }}
          >
            {t('tags.modal.update')}
          </Button>
          <Button variant="contained" onClick={handleClose}>
            {t('tags.modal.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
