import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Input, InputAdornment, Stack, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-eva/eye-fill';
import eyeOffIcon from '@iconify/icons-eva/eye-off-fill';
import passwordAddIcon from '@iconify/icons-eva/plus-outline';
import { useTranslation } from 'react-i18next';
import { AddOutlined, Password, PasswordOutlined, PasswordSharp, PasswordTwoTone } from '@mui/icons-material';
import { useMutation } from 'react-query';
import api from '../../../service/api';
import RHFTextField from '../../hook-form/RHFTextFieldEmail';

export default function ModalGenerateTemporaryPassword({ userId }) {
  const [open, setOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("")
  const [showNewPasswordCheck, setShowNewPasswordCheck] = useState(false)
  const ref = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();

  const mutation = useMutation({
    mutationKey: ["editUser", userId],
    mutationFn: async (newpassword) => {
      const result = await api.put(`/generate-temp-password/${userId}`, {
        newpassword
      })
      return result.data
    },
    onSuccess: () => {
      console.log("success");
    },
    onError: (err) => {
      console.log(err);
    }
  })

  const confirm = () => {
    mutation.mutate(newPassword)
  }

  return (
    <div>
      <IconButton
        sx={{ borderRadius: '0', width: '100%', justifyContent: 'flex-start', gap: 1.4, marginLeft: 1 }}
        onClick={() => {
          handleOpen();
        }}
      >
        <AddOutlined />
        {/* <Icon icon={passwordAddIcon} height={24} width={24} /> */}
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          {t("user.generate-temp-password")}
        </Typography>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('newpassword.pleacehold-senha2')}</DialogTitle>
        <DialogContent>
          <Stack p={1} gap={1}>
            <TextField
              type={showNewPasswordCheck ? 'text' : "password"}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              label={t('newpassword.pleacehold-senha1')}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPasswordCheck(!showNewPasswordCheck)} edge="end">
                      <Icon icon={showNewPasswordCheck ? eyeIcon : eyeOffIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Stack sx={{ marginTop: '5% auto ', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                color={'success'}
                onClick={() => {
                  confirm()
                  // props.blockUser(props.statusUser, props.id);
                  handleClose();
                }}
              >
                {t('newpassword.button-newpassword')}
              </Button>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
