import React from 'react';
import { useDrop } from 'react-dnd';
import { Typography } from '@mui/material';

export default function BreadcrumbsLink({
  pathPortion,
  pathIndex,
  moveDirectoryHandler,
  setDirectory,
  directory,
  setNavigatedDirectory,
  setNavigationIndex,
}) {
  const splittedPath = directory?.split('/');
  const splittedFinalPathArray = splittedPath?.slice(0, pathIndex + 1);
  const finalPath = splittedFinalPathArray?.join('/');

  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop({
    accept: ['file', 'directory'],
    drop: (droppedItem) => {
      moveDirectoryHandler({ path: finalPath }, droppedItem);
    },
  });

  const clickOnPath = () => {
    if (pathPortion === 'Produtos') return;
    setDirectory(finalPath);
    setNavigatedDirectory((prevState) => [...prevState, finalPath]);
    setNavigationIndex(-1);
  };

  return (
    <Typography
      sx={{
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      }}
      ref={drop}
      onClick={clickOnPath}
    >
      {pathPortion}
    </Typography>
  );
}
