import React, { useRef, useState, useContext, useEffect } from 'react';
import i18next from 'i18next';
import Button from '@mui/material/Button';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import filesIcon from '@iconify/icons-ph/files';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import toast from 'react-hot-toast';
// import { ProjectContext } from '../../../../../pages/Timeline';
import { useQueryClient } from 'react-query';
import { CurrentModuleContext } from '../../../../../store/CurrentModuleContext';
import { NodeSelectTimeline } from '../BasicModalTimeline';
import api, { baseURLFile } from '../../../../../service/api';
import ModalViewAnnex from '../../../../ModalViewAnnex';
import { userStore } from '../../../../../store/userStore';
import { checkIfFileIsImage } from '../../../../../utils/checkIfFileIsImage';
import { checkTypeFile } from '../../../utils/checkTypeFile';
import AnnexWithDownloadEscolhaAnexo from './AnnexWithDownloadEscolhaAnexo'

import validations from '../../../../../service/validations';

const fileToSend = [];
export default function ModalEscolhaAnexo() {
  const [open, setOpen] = useState(false);
  // const [projectAnnexCache?, setImagens] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [path, setpath] = useState('');
  const [imageFilename, setImageFilename] = useState();
  const [openModalPdf, setModalPdf] = useState(false);
  const [selectFile, setselectFile] = useState(0);
  const [idModuleAnnex, setIdModuleAnnex] = useState(null);
  const getUserPermissions = userStore((state) => state.getUser);
  const [roleUser, setRoleUser] = useState('');
  const [initIsAdmin, setInitIsAdmin] = useState(null);

  const User = useRef();
  const checkinitIsAdmin = useRef(null);


  const currentModuleCtx = useContext(CurrentModuleContext);

  const getUser = async () => {
    const userPermissions = await getUserPermissions();
    User.current = userPermissions;
    const admin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
    setRoleUser(admin);
  };

  const nodeSelectTimeline = useContext(NodeSelectTimeline);
  const { idprojeto, project } = nodeSelectTimeline;

  const queryClient = useQueryClient();
  const projectAnnexCache = queryClient.getQueryData(['projectAnnex', idprojeto]);

  // const currentUser = JSON.parse(localStorage.getItem('User'));

  let timer = 0;

  const delay = 200;

  let prevent = false;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setselectFile(0);
    setCheckedState(new Array(projectAnnexCache?.length).fill(false));
    setOpen(false);
  };

  const testFile = (image) => {
    if (checkIfFileIsImage(image.moduloanexocaminho)) return `${baseURLFile}/${image.pathfile}`;
    return checkTypeFile(image.pathfile);
  };

  const onSingleClickHandler = (position) => {
    timer = setTimeout(() => {
      if (!prevent) {
        const updateCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
        setCheckedState(updateCheckedState);
      }
    }, delay);
  };

  useEffect(() => {
    if (initIsAdmin !== null) {
      if (project?.projetoiniciador.idusuario === User.current?.idusuario && initIsAdmin) {
        checkinitIsAdmin.current = true;
      } else {
        checkinitIsAdmin.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initIsAdmin]);


  // eslint-disable-next-line no-unused-vars
  const onDoubleClickHandler = (selectedDocument) => {
    clearTimeout(timer);
    prevent = true;
    setIdModuleAnnex(selectedDocument.idmoduloanexo);
    setImageFilename(selectedDocument.moduloanexonome);
    const baseUrlAndPathfile = `${`/${selectedDocument.moduloanexocaminhopdf}`}`;
    toast.dismiss();
    setModalPdf(true);
    setpath(baseUrlAndPathfile);

    setTimeout(() => {
      prevent = false;
    }, delay);
  };

  const builArrayCheck = () => {
    const check = projectAnnexCache?.map((item) => {
      if (item.moduloanexoescolhido === 1) return true;
      return false;
    });
    setCheckedState(check);
  };

  useEffect(() => {
    const checkedTrue = checkedState.filter((item) => item === true);
    setselectFile(checkedTrue.length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedState]);

  useEffect(() => {
    setCheckedState(new Array(projectAnnexCache?.length).fill(false));
    getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAnnexCache?.length]);

  // eslint-disable-next-line consistent-return
  const checkUserPriv = (arrPrivUser) => {
    const checkUser = arrPrivUser.some((user) => user.idusuario === User.current?.idusuario);
    if (!checkUser) {
      return false;
    }
    if (checkUser) {
      return true;
    }
  };
  useEffect(() => {
    builArrayCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    async function settings() {
      const settings = await validations('project');
      setInitIsAdmin(settings.initIsAdmin);
    }
    settings();
  }, []);

  const selectFilesToSend = async () => {
    checkedState.forEach((check, index) => {
      if (check === true) {
        const fileSelect = projectAnnexCache[index];
        fileSelect.moduloanexoescolhido = 1;
        fileToSend.push(fileSelect);
      } else {
        const fileSelect = projectAnnexCache[index];
        fileSelect.moduloanexoescolhido = 0;
        fileToSend.push(fileSelect);
      }
    });
    try {
      await api.put(`/moduleanexos`, { anexos: fileToSend });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-choiceFiles.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={filesIcon} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClickClose}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
      >
        <>
          <IconButton
            onClick={handleClickClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('flow-Modals-choiceFiles.title')}</DialogTitle>
          <DialogContent>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row', height: '15%', gap: '1rem' }}>
              <ModalViewAnnex
                path={path}
                openModalPdf={openModalPdf}
                setModalPdf={setModalPdf}
                filename={imageFilename}
                id={imageFilename}
                idModule={currentModuleCtx.idModule}
                idModuleAnnex={idModuleAnnex}
                idProject={idprojeto}
              />
              {projectAnnexCache?.map((imagem, index) => {

                if (
                  // true
                  checkUserPriv(imagem.moduloanexoprivacidade.userslinked) ||
                  imagem.moduloanexoprivacidade.userslinked.length <= 0 ||
                  checkinitIsAdmin.current||
                  roleUser
                ) {
                  return (
                    <AnnexWithDownloadEscolhaAnexo
                    key={`annex_donwload-escolha_${imagem.moduloanexonome}`}
                    index={index}
                    imagem={imagem}
                    checkedState={checkedState}
                    onSingleClickHandler={onSingleClickHandler}
                    onDoubleClickHandler={onDoubleClickHandler}
                    testFile={testFile}
                    />
                  );
                }
                return <></>;
              })}
            </FormGroup>
            <Typography sx={{ marginTop: 1 }}>{`${i18next.t(
              'flow-Modals-choiceFiles.tooltip'
            )} : ${selectFile}`}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClickClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                selectFilesToSend();
                handleClickClose();
              }}
              color="success"
            >
              {i18next.t('flow-Modals-choiceFiles.send')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
