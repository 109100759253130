import { useEffect } from 'react';
import { Box, Card, IconButton, Modal } from '@mui/material';

import closeIcon from '@iconify/icons-material-symbols/close';
import { Icon } from '@iconify/react';
import ViewSDKClient from './ViewSDKClient';
import { baseURLFile } from '../service/api';

const BoxPdf = () => <Box id="pdf-div" className="full-window-div border border-gray-100 h-screen" />;

export default function ModalViewAnnex({
  path,
  openModalPdf,
  setModalPdf,
  filename,
  id,
  itemStatus,
  isAdmin,
  readOnly,
  idModule,
  idModuleAnnex,
  idProject,
}) {
  const loadPDF = () => {
    const eventOptions = {
      listenOn: [
        window.AdobeDC.View.Enum.AnnotationEvents.ANNOTATION_ADDED,
        window.AdobeDC.View.Enum.AnnotationEvents.ANNOTATION_DELETED,
        window.AdobeDC.View.Enum.AnnotationEvents.ANNOTATION_UPDATED,
      ],
      enableAnnotationEvents: true,
    };

    if (openModalPdf === true) {
      const viewSDKClient = new ViewSDKClient();
      viewSDKClient.ready().then(() => {
        viewSDKClient
          .previewFile(
            'pdf-div',
            {
              defaultViewMode: 'FIT_WIDTH',
              showAnnotationTools: !itemStatus,
              enableAnnotationAPIs: true,
              includePDFAnnotations: true,
              showPrintPDF: (itemStatus !== 'O' && itemStatus !== 'P') || !!isAdmin,
              showDownloadPDF: (itemStatus !== 'O' && itemStatus !== 'P') || !!isAdmin,
              downloadWithAnnotations: true,
              focusOnRendering: true,
            },
            `${baseURLFile}/${path}`,
            filename,
            id,
            readOnly
          )
          .then((adobeViewer) => {
            adobeViewer.getAnnotationManager().then((annotationManager) => {
              annotationManager.registerEventListener(() => {}, {
                eventOptions,
              });
            });
          });
        viewSDKClient.ready().then(() => {
          viewSDKClient.setUserApiHandler();
          viewSDKClient.registerSaveApiHandler(idModuleAnnex);
          viewSDKClient.registerEventsHandler(idModule, idModuleAnnex, idProject);
        });
      });
    }
  };

  useEffect(() => {
    loadPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalPdf]);

  return (
    <Modal
      open={openModalPdf ?? false}
      onClose={() => setModalPdf(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <>
        <IconButton
          onClick={() => setModalPdf(false)}
          id="Botão fechar modal"
          sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
        >
          <Icon icon={closeIcon} fontSize={32} />
        </IconButton>
        <Card sx={{ height: '84%', width: '80%' }}>{BoxPdf()}</Card>
      </>
    </Modal>
  );
}
