import i18next from 'i18next';

export const Config = {
  passwordMinLength: i18next.t('config.passwordMinLength.description'),
  isSessionExpires: i18next.t('config.isSessionExpires.description'),
  passwordContainsSpecialSymbol: i18next.t('config.passwordContainsSpecialSymbol.description'),
  passwordContainsUppercase: i18next.t('config.passwordContainsUppercase.description'),
  userNameMinimal: i18next.t('config.userNameMinimal.description'),
  passwordContainsLowercase: i18next.t('config.passwordContainsLowercase.description'),
  passwordContainsNumber: i18next.t('config.passwordContainsNumber.description'),
  passwordMaxLength: i18next.t('config.passwordMaxLength.description'),
  passwordExpirationTime: i18next.t('config.passwordExpirationTime.description'),
  loginAttempt: i18next.t('config.loginAttempt.description'),
  timeTokenExpires: i18next.t('config.timeTokenExpires.description'),
  login2Factor: i18next.t('config.login2Factor.description'),
  timeSessionExpires: i18next.t('config.timeSessionExpires.description'),
  initIsAdmin: i18next.t('config.initIsAdmin.description'),
  returnAnywhereModule: i18next.t('config.returnAnywhereModule.description'),
};
