import PropTypes from 'prop-types';
import { useMemo, useState, createContext, useEffect } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette, { paletteDark } from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export const DarkModeContext = createContext();

export default function ThemeProvider({ children }) {
  const [darkMode, setdarkMode] = useState(localStorage.getItem('darkMode'));

  useEffect(() => {
    if (localStorage.getItem('darkMode') !== null &&  localStorage.getItem('darkMode') !== undefined) {
      const darkMode = localStorage.getItem('darkMode');
      if (darkMode === 'true') {
        setdarkMode(true);
      }
      if (darkMode === 'false') {
        setdarkMode(false);
      }
    }
  }, []);

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    []
  );
  const themeOptionsDark = useMemo(
    () => ({
      palette: paletteDark,
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  const themeDark = createTheme(themeOptionsDark);
  theme.components = componentsOverride(theme);
  themeDark.components = componentsOverride(themeDark);

  return (
    <StyledEngineProvider injectFirst>
      <DarkModeContext.Provider value={{ darkMode, setdarkMode }}>
        <MUIThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </DarkModeContext.Provider>
    </StyledEngineProvider>
  );
}
