import { styled } from '@mui/material/styles';
import i18next from 'i18next';

import { Grid, Modal, Typography, Box, Button, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-circle-outline';
import { useNavigate } from 'react-router-dom';

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));

export const ModalNoBondProject = ({ isModalOpen, setModalOpen }) => {

  const navigate = useNavigate();
  const closeModalHandler = () => {
    setModalOpen(false);
    navigate('/projects')
  };

  return (
    <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
      <GridStyle sx={{ position: 'relative', height: 'max-content' }}>
        <Typography variant="h3" align="center">
        {i18next.t('notBondProject.title')}
        </Typography>
        <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
          <IconButton size="large" onClick={() => closeModalHandler()}>
            <Icon icon={closeIcon} />
          </IconButton>
        </Box>
        <Typography variant="subtitle1" align="center">
        {i18next.t('notBondProject.subtitle')}
        </Typography>
        <Stack sx={{ margin: '5% auto ', flexDirection: 'row', justifyContent: 'center', gap: '3rem' }}>
          <Button onClick={closeModalHandler} variant="contained">
            {i18next.t('flow-Modals.exit')}
          </Button>
        </Stack>
      </GridStyle>
    </ModalStyle>
  );
};
