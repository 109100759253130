import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-eva/eye-fill';
import eyeOffIcon from '@iconify/icons-eva/eye-off-fill';
import validations from '../../../service/validations';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// api
import api from '../../../service/api';
// ----------------------------------------------------------------------

export default function NewPasswordForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordCheck, setShowNewPasswordCheck] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email } = state;
  const { t } = useTranslation();
  const [lowerCase, setLowerCase] = useState();
  const [containsNumber, setContainsNumber] = useState();
  const [specialSymbol, setSpecialSymbol] = useState();
  const [upperCase, setUpperCase] = useState();
  const [passwordMaxLength, setPasswordMaxLength] = useState();
  const [passwordMinLength, setPasswordMinLength] = useState();

  useEffect(() => {
    async function settings() {
      const settings = await validations('users');
      setLowerCase(settings.passwordContainsLowercase);
      setContainsNumber(settings.passwordContainsNumber);
      setSpecialSymbol(settings.passwordContainsSpecialSymbol);
      setUpperCase(settings.passwordContainsUppercase);
      setPasswordMaxLength(settings.passwordMaxLength);
      setPasswordMinLength(settings.passwordMinLength);
    }
    settings();
  }, []);
  async function requestNewPassword(data) {
    const user = JSON.parse(localStorage.getItem('User'));
    let useremail = '';
    if (user === undefined || user === null) {
      useremail = email;
    } else {
      // eslint-disable-next-line prefer-destructuring
      useremail = user.useremail;
    }
    try {
      const req = {
        password: data.passwordTemp,
        newpassword: data.newPassword,
      };
      const response = await api.put(`/password/${useremail}`, req);
      if (response.data.status === 'success') {
        navigate('/login', { replace: true });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        toast.error(`${error.response.data.data.errors[0]}`);
        // toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  const passwordRequired = t('validation.newpassword.password-required');

  const NewPasswordSchema = Yup.object().shape({
    passwordTemp: Yup.string().required(passwordRequired),
    newPassword: Yup.string()
      .required(passwordRequired)
      .min(passwordMinLength, `${t('validation.newpassword.password-short')} ${passwordMinLength} `)
      .max(passwordMaxLength, `${t('validation.newpassword.password-longer')} ${passwordMaxLength} `)
      .test('upercase', `${t('validation-newpassoard.uppercase')}`, (Password) => {
        if (upperCase === true) {
          const uppercase = /(.*[A-Z].*)/;
          return uppercase.test(Password);
        }
        return true;
      })
      .test('containsNumber', `${t('validation-newpassoard.number')}`, (Password) => {
        if (containsNumber === true) {
          const containsNumber = /(.*[0-9].*)/;
          return containsNumber.test(Password);
        }
        return true;
      })
      .test('specialSymbol', `${t('validation-newpassoard.symbols')}`, (Password) => {
        if (specialSymbol === true) {
          const ContainsSpecialSympol = /(.*[!@#$%^&*].*)/;
          return ContainsSpecialSympol.test(Password);
        }
        return true;
      })
      .test('lowerCase',  `${t('validation-newpassoard.lowercase')}`, (Password) => {
        if (lowerCase === true) {
          const containsLowerCase = /(.*[a-z].*)/;
          return containsLowerCase.test(Password);
        }
        return true;
      }),
    newPasswordConfirm: Yup.string()
      .required(passwordRequired)
      .oneOf([Yup.ref('newPassword'), null], `${t('validation-newpassoard.passowoardIsEqual')}`),
  });

  const defaultValues = {
    passwordTemp: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    requestNewPassword(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
      <Stack spacing={3} >
        <RHFTextField
          name="passwordTemp"
          label={t('newpassword.pleacehold-senha1')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Icon icon={showPassword ? eyeIcon : eyeOffIcon} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="newPassword"
          label={t('newpassword.pleacehold-senha2')}
          type={showNewPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                  <Icon icon={showNewPassword ? eyeIcon : eyeOffIcon} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="newPasswordConfirm"
          label={t('newpassword.pleacehold-senha3')}
          type={showNewPasswordCheck ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowNewPasswordCheck(!showNewPasswordCheck)} edge="end">
                  <Icon icon={showNewPasswordCheck ? eyeIcon : eyeOffIcon} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('newpassword.button-newpassword')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
