import { useState, useRef, useContext, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import editIcon from '@iconify/icons-eva/edit-outline';
import { ConfigContext } from '../../../pages/Config';
import api from '../../../service/api';

//------------------------------------------------------------------------

export default function FormDialog(props) {
  const [open, setOpen] = useState(false);
  const [valor, setValor] = useState(props.valor);
  const [returnAnywhereModuleValue, setReturnAnywhereModuleValue] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.configchave === 'returnAnywhereModule') {
      if (props.configvalor === '') return;
      const roles = props.configvalor.split(',').filter((role) => role !== 'Administrador');
      setReturnAnywhereModuleValue(roles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = useQueryClient();

  const configContext = useContext(ConfigContext);

  const { config, setConfig } = configContext;

  const updateMutation = useMutation((valorSet) => atualizaDados(valorSet), {
    onSuccess: () => {
      queryClient.invalidateQueries('settingsLogin');
    },
    onError: (erro) => {
      console.log(erro);
    },
  });

  const atualizaDados = async (valor) => {
    try {
      const response = await api.put(`/settings/${props.configchave}`, {
        value: valor,
      });
      if (response.status === 200 || response.status === 201) {
        toast.success(`${t('configEdit.toastSuccess')}`);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (boolean) => {
    let novoBool;
    if (boolean === 'false') {
      novoBool = 'true';
    }
    if (boolean === 'true') {
      novoBool = 'false';
    }
    // atualizaDados(novoBool, props.configchave);
    updateMutation.mutate(novoBool);

    const config2 = config.map((element) =>
      element.idconfig === props.idconfig ? { ...element, configvalor: novoBool } : element
    );

    setConfig(config2);

    handleClose();
  };

  const enviaCongfig = (valor) => {
    // atualizaDados(valor, props.configchave);
    updateMutation.mutate(valor);

    const config2 = config.map((element) =>
      element.idconfig === props.idconfig ? { ...element, configvalor: valor } : element
    );

    setConfig(config2);
  };

  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  // eslint-disable-next-line consistent-return
  const verificaTipo = (tipo, chave) => {
    if (tipo === 'boolean') {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{props.configchave}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.configdescricao}</DialogContentText>
            <Stack sx={{ marginTop: '5%' }}>
              <Button
                variant="contained"
                color={props.configvalor === 'false' ? 'success' : 'primary'}
                onClick={() => {
                  handleChange(props.configvalor);
                }}
              >
                {props.configvalor === 'false' ? 'Ativar' : 'Desativar'}
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      );
    }
    if (tipo === 'number') {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{props.configchave}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.configdescricao}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              type={props.configtipo}
              fullWidth
              variant="standard"
              // defaultValue={props.configvalor}
              value={String(valor).trimStart("0")}
              // eslint-disable-next-line no-return-assign
              onChange={(e) => {
                const min = props.configchave === "timeSessionExpires" ? 10 : 0
                setValor(() => Math.max(min, Number(String(e.target.value))));
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                enviaCongfig(valor);
                handleClose();
              }}
            >
              {t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    if (tipo === 'string') {
      if (chave === 'returnAnywhereModule') {
        const handleChangeValue = (role) => {
          const valueIncluded = returnAnywhereModuleValue.includes(role);
          if (valueIncluded) {
            setReturnAnywhereModuleValue((prevState) => prevState.filter((prevRole) => prevRole !== role));
          } else {
            setReturnAnywhereModuleValue((prevState) => [...prevState, role]);
          }
        };

        return (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{props.configchave}</DialogTitle>
            <DialogContent>
              <DialogContentText>{props.configdescricao}</DialogContentText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={returnAnywhereModuleValue.includes('Gerente')}
                      onChange={() => handleChangeValue('Gerente')}
                    />
                  }
                  label="Gerente"
                />
                <FormControlLabel
                  control={<Checkbox checked={returnAnywhereModuleValue.includes('Comum')} />}
                  onChange={() => handleChangeValue('Comum')}
                  label="Comum"
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button
                onClick={() => {
                  if (returnAnywhereModuleValue.length <= 0) {
                    enviaCongfig('');
                  } else {
                    enviaCongfig(returnAnywhereModuleValue.toString());
                  }
                  handleClose();
                }}
              >
                {t('tags.modal.update')}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{props.configchave}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.configdescricao}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              type={props.configtipo}
              fullWidth
              variant="standard"
              defaultValue={props.configvalor}
              // eslint-disable-next-line no-return-assign
              onChange={(e) => {
                setValor(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                enviaCongfig(valor);
                handleClose();
              }}
            >
              {t('tags.modal.update')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  return (
    <div>
      <IconButton
        ref={ref}
        onClick={() => {
          handleClickOpen();
        }}
        sx={{ color: 'text.secondary' }}
      >
        <Icon icon={editIcon} width={20} height={20} />
      </IconButton>
      {verificaTipo(props.configtipo, props.configchave)}
    </div>
  );
}
