import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { userStore } from '../store/userStore';
import { ID_SUPERUSER } from '../enum/SuperUser';


const PerfilPermissionSuperUser = () => {
  const [id, setid] = useState(null);
const getUserPermissions = userStore((state) => state.getUser);

async function getIdUser() {
  const userPermissions = await getUserPermissions();
  setid(userPermissions.idusuario);
}
  useEffect(() => {
    getIdUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-nested-ternary
  return id === null ? <> </> : id === ID_SUPERUSER ? <Outlet /> : <Navigate to="/" />;
};

export default PerfilPermissionSuperUser;
