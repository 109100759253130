import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Badge } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import i18next from 'i18next';
// components
import CircularProgress from '@mui/material/CircularProgress';
import MenuPopover from '../../components/MenuPopover';
import brasil from '../../assets/flags/bandeira-brasil.svg';
import eua from '../../assets/flags/bandeira-eua.svg';
import espanha from '../../assets/flags/bandeira-espanha.svg';
import notation from '../../assets/icones/icon-notification2.svg';
//
import { reaload } from './DashboardSidebar';

// api
import api from '../../service/api';


// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [flagIcon, setFlagIcon] = useState(brasil);
  const [flagLabel, setLabel] = useState('Português');
  const { i18n } = useTranslation();
  const timeRefresh = 1000 * 60 * 5

  localStorage.setItem('i18nextLng', i18n.language);
  api.defaults.headers.common['Accept-Language'] = `${i18n.language}`;


  const languangeOptions = [
    {
      value: 'pt',
      label: `${i18next.t('languanges.portuguese')}`,
      icon: brasil,
    },
    {
      value: 'en',
      label: `${i18next.t('languanges.english')}`,
      icon: eua,
    },
    {
      value: 'es',
      label: `${i18next.t('languanges.spanish')}`,
      icon: espanha,
    },
  ];

  useEffect(() => {
    const optionLanguage = languangeOptions.find((option) => option.value === i18n.language);
    setFlagIcon(optionLanguage.icon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFlagIcon]);

  // eslint-disable-next-line consistent-return
  async function requestAlertCounters() {
    try {
      const response = await api.get('/alertcounter');
      // eslint-disable-next-line prefer-destructuring
      return response.data.data.alertcounter
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      console.log(error);
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenAlert = () => {
    navigate('/alerts', { replace: true });
  };

  const selectLanguage = (option) => {
    i18n.changeLanguage(option.value);
    setFlagIcon(option.icon);
    setLabel(option.label);
    setOpen(false);
    setFlagIcon(option.icon);
    reaload();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data, isFetching } = useQuery(['alertCounter'], () => requestAlertCounters(), {
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchInterval: timeRefresh

  });

  return (
    <>
      <Stack spacing={2} direction="row">
        {isFetching && <CircularProgress />}
        <Badge badgeContent={data?.total} color="secondary" max={99}>
          {/* <MailIcon color="action" />  */}
          <IconButton
            ref={anchorRef}
            onClick={handleOpenAlert}
            sx={{
              padding: 0,
              width: 32,
              height: 32,
              ...(open && {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
              }),
            }}
          >
            <img src={notation} alt="Click para detalhes" />
          </IconButton>
        </Badge>
      </Stack>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={flagIcon} alt={flagLabel} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {languangeOptions.map((option, idx) => (
            <MenuItem key={idx} selected={option.value === 'true'} onClick={() => selectLanguage(option)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
