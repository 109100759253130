import { React } from 'react';
import { Alert } from '@mui/material';

export function AlertConfig(props) {
  if (props.error.bool === 'true') {
    return <Alert severity="error">{props.error.mensagem}</Alert>;
  }
  if (props.error.bool === 'false') {
    return <Alert severity="success">{props.error.mensagem}</Alert>;
  }
  return <></>;

}



