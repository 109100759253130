// @mui
import { styled } from '@mui/material/styles';
import { Container, CardMedia } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { ActiveDirectoryForm } from '../sections/auth/activedirectory';
// LogoActiveDirectory
import ADLogo from '../assets/logos-login/active-directory.svg';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ActiveDirectory() {
  return (
    <Page title="Active Directory Register">
      <RootStyle>
        <Container>
          <ContentStyle>
            <CardMedia component="img" src={ADLogo} sx={{ px: 10, py: 2 }} />
            <ActiveDirectoryForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
