import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// translation
import ptLanguage from './translation/pt.json';
import enLanguage from './translation/en.json';
import esLanguage from './translation/es.json';

// ----------------------------------------------------------------------

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    supportedLngs: ['pt', 'en', 'es'],
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pt: ptLanguage,
      en: enLanguage,
      es: esLanguage,
    },
    detection: {
      checkWhitelist: true,
    },
  });

export default i18n;
