import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  Stack,
  Typography,
  Switch,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
} from '@mui/material';
import toast from 'react-hot-toast';
import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-fill';
import { compareTwoStrings } from 'string-similarity';
import { TagsContext } from '../../../pages/Tags';
import Page from '../../Page';
// api
import api from '../../../service/api';
// store
import { usePermission } from '../../../store/usePermission';

//------------------------------------------------------------------------

export default function DialogNewTags() {
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState('#FFFFFF');
  const [checkedType, setCheckedType] = useState(false);
  const [nome, setNome] = useState('');
  const [similarNameWarning, setSimilarNameWarning] = useState(false);
  const [equalNameError, setEqualNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [permissionView, setPermissionView] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);
  const { t } = useTranslation();

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const tagView = userPermissions.includes('tagViewing');
      const tagAdm = userPermissions.includes('tagAdministrating');
      setPermissionView(tagView);
      setPermissionAdmin(tagAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagsContext = useContext(TagsContext);
  const { tags, setTags } = tagsContext;

  const checkNameInputValidation = (inputValue) => {
    setEmptyNameError(false);

    const isProjectNameRepeated = tags
      .map((tag) => tag.tagnome)
      .map((tagName) => tagName.toLowerCase() === inputValue.toLowerCase())
      .includes(true);

    const isProjectNameSimilar = tags
      .map((tag) => tag.tagnome)
      .map((tagName) => compareTwoStrings(tagName.toLowerCase(), inputValue.toLowerCase()) > 0.8)
      .includes(true);

    setEqualNameError(isProjectNameRepeated);
    setSimilarNameWarning(isProjectNameSimilar);
  };

  // Validação do nome das Tags
  useEffect(() => {
    checkNameInputValidation(nome);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome]);

  const addTag = async (type) => {
    try {
      const response = await api.post(`/${type}`, {
        tagnome: nome,
        tagcolor: color,
      });
      if (response.status === 200 || response.status === 201) {
        toast.success(`${t('tags.toats.success')}`);
        const tagBack = response.data.data.tag;
        const newTagState = structuredClone(tags);
        newTagState.push(tagBack);
        setTags(newTagState);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeType = (event) => {
    setCheckedType(event.target.checked);
  };

  const enviaCongfig = () => {
    let newType;
    if (checkedType) {
      newType = 'tagsku';
    } else {
      newType = 'tag';
    }
    addTag(newType);
  };

  const selectNameErrorMessage = () => {
    if (equalNameError) return t('tags.errors.equal-name');
    if (similarNameWarning) return t('tags.errors.similar-name');
    if (emptyNameError) return t('tags.errors.empty-name');
    return null;
  };

  return (
    <Page>
      <Button
        variant="contained"
        startIcon={<Icon icon={plusIcon} />}
        onClick={() => {
          handleClickOpen();
        }}
      >
        {t('tags.modal.addTag')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('tags.modal.addTag')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{`${t('tags.modal.newNameTag')}`}</DialogContentText>
          <DialogContentText sx={{ marginTop: '1rem' }}>
            {`${t('tags.modal.typeTag')} `}
            <Stack component={'span'} direction="row" spacing={1} alignItems="center">
              {permissionAdmin === true ? <Typography component={'span'}>TAG</Typography> : ''}
              <Switch checked={checkedType} inputProps={{ 'aria-label': 'ant design' }} onChange={handleChangeType} />
              {permissionAdmin === true ? <Typography component={'span'}>TAG-SKU</Typography> : ''}
            </Stack>
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label={`${t('tags.headCell.labelName')}`}
            type="string"
            fullWidth
            variant="standard"
            helperText={selectNameErrorMessage()}
            error={equalNameError || emptyNameError}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography component={'span'} sx={{ color: '#637381' }}>
              {t('tags.modal.colorTag')}
            </Typography>
            <Input
              id="color"
              label="Status da tag"
              sx={{ width: '15%', height: '3rem' }}
              variant="filled"
              type="color"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              if (equalNameError) return;
              if (nome.length >= 3) {
                enviaCongfig();
                handleClose();
              } else {
                setEmptyNameError(true);
              }
            }}
          >
            {t('tags.modal.update')}
          </Button>
          <Button variant="contained" onClick={handleClose}>
            {t('tags.modal.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
