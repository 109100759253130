import { Card, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import fileDownloadIcon from '@iconify/icons-ic/round-file-download';
import refreshFileIcon from '@iconify/icons-mdi/file-refresh';
import addFolderIcon from '@iconify/icons-mdi/folder-add';
import fileUploadIcon from '@iconify/icons-ic/file-upload';

const ICON_SIZE = 24;

export default function RepositoryContextMenu({
  contextMenuPosition,
  setContextMenuPosition,
  clickNewFolderHandler,
  folder,
  uploadFileHandler,
  contextMenuRef,
  contextMenuItemPosition,
  setContextMenuItemPosition,
  downloadFileHandler,
  reactivateFileHandler,
  selectedItems,
  setSelectedItems,
}) {
  const [areItemsObsolete, setAreItemsObsolete] = useState(false);

  const { t } = useTranslation();

  const isMultipleSelected = selectedItems.length > 1;

  const contextMenuItemsOptions = [
    {
      label: isMultipleSelected ? t('repo.context-menu.download-files') : t('repo.context-menu.download-file'),
      icon: fileDownloadIcon,
      function: () => downloadOnClickHandler(),
    },
    {
      label: isMultipleSelected ? t('repo.context-menu.reactivate-files') : t('repo.context-menu.reactivate-file'),
      icon: refreshFileIcon,
      function: () => reactivateOnClickHandler(),
    },
  ];

  useEffect(() => {
    const allItemsObsolete = selectedItems.every((item) => item.status === 'O');

    setAreItemsObsolete(allItemsObsolete);
  }, [selectedItems]);

  const downloadOnClickHandler = () => {
    if (selectedItems.length > 0) downloadFileHandler(selectedItems);
    setContextMenuItemPosition(null);
    setSelectedItems([]);
  };

  const reactivateOnClickHandler = () => {
    if (selectedItems.length > 0) reactivateFileHandler(selectedItems);
    setContextMenuItemPosition(null);
    setSelectedItems([]);
  };

  return (
    <Card
      sx={{
        width: 320,
        maxWidth: '100%',
        position: 'absolute',
        borderRadius: 1,
        zIndex: 10,
        top: contextMenuPosition ? contextMenuPosition.y : contextMenuItemPosition.y,
        left: contextMenuPosition ? contextMenuPosition.x : contextMenuItemPosition.x,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'table_filler',
      }}
      ref={contextMenuRef}
      onContextMenu={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <MenuList>
        {contextMenuPosition && (
          <MenuItem
            onClick={() => {
              setContextMenuPosition(null);
              clickNewFolderHandler(+folder.name);
            }}
          >
            <ListItemIcon>
              <Icon icon={addFolderIcon} fontSize={ICON_SIZE} />
            </ListItemIcon>
            <ListItemText>{t('repo.create-folder')}</ListItemText>
          </MenuItem>
        )}
        {contextMenuPosition && (
          <MenuItem
            onClick={() => {
              setContextMenuPosition(null);
              uploadFileHandler();
            }}
          >
            <ListItemIcon>
              <Icon icon={fileUploadIcon} fontSize={ICON_SIZE} />
            </ListItemIcon>
            <ListItemText>{t('repo.do-upload-file')}</ListItemText>
          </MenuItem>
        )}
        {contextMenuItemPosition &&
          contextMenuItemsOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={option.function}
              sx={{ display: index === 1 && !areItemsObsolete && 'none' }}
            >
              <ListItemIcon>
                <Icon icon={option.icon} fontSize={ICON_SIZE} />
              </ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
      </MenuList>
    </Card>
  );
}
