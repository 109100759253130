import { useNavigate } from 'react-router-dom';
// @mui
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Link,
  Typography,
  Paper,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Avatar
} from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// utils

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Masonry } from '@mui/lab';
import { fToNow } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

AppNewsAllUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  // list: PropTypes.array.isRequired,
};

export default function AppNewsAllUpdate({ newsLoading, list, ...other }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const handleOpenVejaMais = () => {
    navigate('/news', { replace: true });
  };

  if (newsLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!newsLoading && list.length)
    return (
      <Masonry columns={1} spacing={2} {...other}>
        {list.map((news) => (
          <NewsItem key={news.id} news={news} />
        ))}
      </Masonry>
    );

  if (!newsLoading && !list.length)
    return (
      <Paper elevation={3} sx={{ width: '100%' }}>
        <br />
        <Typography variant="h4" align="center" color="primary">
          {t('home.no-news')}
        </Typography>
        <br />
      </Paper>
    );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  // eslint-disable-next-line no-unused-vars
  const { id, projeto, execdescricao, moduloacaodata, idusuario, usuarioicon, usuarionome, modulotxt, moduloestagio } =
    news;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      mb={2}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Paper sx={{ minWidth: 770, maxWidth: 770, padding: 1, display: 'flex', justifyContent: 'center' }} elevation={3}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: 'center' }}
              >
                <Tooltip title={usuarionome} arrow>
                  <Avatar
                    alt={usuarionome}
                    src={usuarioicon}
                    sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0, objectFit: 'cover' }}
                  />
                </Tooltip>
                <Box sx={{ minWidth: 240 }}>
                  <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
                    {projeto}
                  </Link>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    <b>{i18next.t('newsAll.stage')} </b> {moduloestagio}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    <b>{i18next.t('newsAll.node')} </b>
                    {modulotxt}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                  {fToNow(moduloacaodata)}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails sx={{ marginTop: 0.5, minWidth: 770 }}>
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              {execdescricao}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Stack>
  );
}
