import { Icon } from '@iconify/react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import filterIcon from '@iconify/icons-material-symbols/filter-list';

export default function RepositoryFilesFilterByStatusMenu({
  open,
  handleClick,
  anchorEl,
  handleClose,
  filterStatusHandler,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t('repo.repo-files.filter-by-status')} disableInteractive>
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          position="start"
        >
          <Icon icon={filterIcon} sx={{ color: 'text.disabled' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => filterStatusHandler('')}>{t('repo.repo-files.without-filter')}</MenuItem>
        <MenuItem onClick={() => filterStatusHandler('V')}>{t('repo.repo-files.status-present')}</MenuItem>
        <MenuItem onClick={() => filterStatusHandler('O')}>{t('repo.repo-files.status-obsolete')}</MenuItem>
        <MenuItem onClick={() => filterStatusHandler('P')}>{t('repo.repo-files.status-scheduled')}</MenuItem>
      </Menu>
    </>
  );
}
