import { styled } from '@mui/material/styles';
import { Button, Container, Typography, Box, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowBackIcon from '@iconify/icons-eva/arrow-back-fill';

const APP_BAR_MOBILE = 64;

const ContainerStyled = styled(Container)(({ theme }) => ({
  height: '100%',
  marginLeft: 50,
  width: '97%',
  flexGrow: 1,
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
}));

const BoldTypographyStyled = styled(Typography)(() => ({
  display: 'inline',
  fontSize: 'inherit',
  fontWeight: 'bold',
}));

const ButtonStyle = styled(Button)(() => ({
  width: '20%',
  border: '1px solid #FECD0E',
}));

const TagBox = styled(Box)(() => ({
  height: '1.5rem',
  width: 'max-content',
  // padding: '0px, 10px',
  // marginLeft: 5,
  borderRadius: 16,
  boxShadow: '2px 1px 10px #808080',
}));

const GoBackBox = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'red',
  zIndex: 100,
  borderRadius: 100,
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down(1920)]: {
    left: -65,
  },
}));

export function StyledContainer({ children, sx }) {
  return (
    <ContainerStyled sx={sx} maxWidth={false}>
      {children}
    </ContainerStyled>
  );
}

export function StyledBoldTypography({ children }) {
  return <BoldTypographyStyled>{children}</BoldTypographyStyled>;
}

export function StyledButton({ children, contained, onClick, style, disabled }) {
  let buttonType = (
    <ButtonStyle sx={style} disabled={disabled} onClick={onClick}>
      {children}
    </ButtonStyle>
  );

  if (contained)
    buttonType = (
      <ButtonStyle sx={style} disabled={disabled} variant="contained" onClick={onClick}>
        {children}
      </ButtonStyle>
    );
  return buttonType;
}

export function StyledTagBox({ tagColor, children, style }) {
  return (
    <TagBox
      sx={[
        {
          backgroundColor: tagColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '1.2rem',
          width: 'max-content',
          padding: '0 10px',
          stroke: "1px black"
        },
        style,
      ]}
    >
      {children}
    </TagBox>
  );
}

export function StyledGoBackBox({ navigateBackFunction, sx }) {
  return (
    <GoBackBox sx={sx} onClick={navigateBackFunction}>
      <Icon sx={{ height: 36, width: 36 }} icon={arrowBackIcon} />
      {/* <Icon sx={{ height: 36, width: 36 }} icon="eva:arrow-back-fill" /> */}
    </GoBackBox>
  );
}
