import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Stack,
  Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {LoadingForReports} from '../../LoadingForReports'
import api from '../../../service/api';
import ModalReportAuth from './ModalReportAuth';

const ImgBoxStyle = styled(Box)(() => ({
  width: 42,
  height: 42,
}));

export default function ModalReportOverdueUser({ open, handleClose, title, description, icon }) {
  const [checked, setChecked] = useState(true);

  const [reportAuth, setReportAuth] = useState(true);
  const [fieldValue, setFieldValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpenAuth = (event) => {
    setReportAuth(true);
    setFieldValue(event.isTrusted);
  };

  const handleCloseAuth = () => {
    setReportAuth(false);
  };

  const handleGenarator = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/report/overdueusers', { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;

      const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];

      fileLink.setAttribute('download', `${fileName}.${extension}`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error(error);
    }finally {
      setIsLoading(false);
    }

  };

  const handleGenaratorPDF = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/report/overdueuserspdf', { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;

      const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];

      fileLink.setAttribute('download', `${fileName}.${extension}`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error(error);
    }finally {
      setIsLoading(false);
    }

  };

  return (
    <div>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            component="span"
            sx={{ display: 'flex', flexDirection: 'row', alignSelf: '', marginTop: '2rem' }}
          >
            <ImgBoxStyle component="img" src={icon} sx={{ alignSelf: 'start', marginTop: 0.5 }} />
            <Typography
              component="span"
              variant="h6"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1, marginLeft: 1 }}
            >
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component="span" sx={{ marginTop: '1rem' }}>
              {description}
            </DialogContentText>
            <DialogContentText component="span">
              <Stack>
                <Typography component="span" sx={{ marginTop: '1rem', color: '#637381' }}>
                  {t('report.modal-report.type')}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography component="span">XLSX</Typography>
                  <Switch checked={checked} inputProps={{ 'aria-label': 'ant design' }} onChange={handleChange} />
                  <Typography component="span">PDF</Typography>
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {t('report.button.cancel')}
            </Button>
            {checked === false ? (
              <Button variant="contained" onClick={handleGenarator} color="success">
                {t('report.button.generate')}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleClickOpenAuth} color="success">
                {t('report.button.generate')}
              </Button>
            )}
          </DialogActions>
          <LoadingForReports isLoading={isLoading}/>
          {fieldValue === true && (
            <ModalReportAuth
              open={reportAuth}
              onClose={handleCloseAuth}
              handleClose={handleCloseAuth}
              genaratorPDF={handleGenaratorPDF}
            />
          )}
        </Dialog>
      </Box>
    </div>
  );
}
