import { styled } from '@mui/material/styles';
import i18next from 'i18next';

import {
  Grid,
  Modal,
  Typography,
  Box,
  Button,
  Stack,
  Card,
  CardMedia,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-circle-outline';
import IconButton from '@mui/material/IconButton';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import toast from 'react-hot-toast';
import { ModuleStatus } from '../../../../../enum/ModuleStatusEnum';
import { textfieldMinLength } from '../../../../../utils/textfieldMinLength';
import api from '../../../../../service/api';
import { moduleProperties } from '../../../utils/functionsManagementNode';
import Scrollbar from '../../../../Scrollbar';
import ModalAprovaAnexo from './ModalAprovaAnexo';

// store
import { userStore } from '../../../../../store/userStore';

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));

const schema = yup.object({
  argsForChangeStatus: yup
    .string()
    .required(`${i18next.t('flow-Modals-changeStatusProject.args-empty')}`)
    .min(textfieldMinLength, `${i18next.t('flow-Modals-changeStatusProject.args-minLength', { textfieldMinLength })}`),
});

export const ConfirmReturnModal = ({
  isModalOpen,
  setModalOpen,
  moduleReturns,
  idprojeto,
  propertiesConfirm,
  onClose,
  nodes,
  edges,
  returnAnywhereModuleConfigValue,
}) => {
  const [returnNodes, setReturnNodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [argument, setArgument] = useState('');
  const [allPrevActionNodes, setAllPrevActionNodes] = useState([]);
  const [nodeCheckpointLater, setNodeCheckpointLater] = useState([]);
  const [prevNodesChangeStatus, setPrevNodesChangeStatus] = useState([]);
  const [selectNode, setSelecktNode] = useState({});
  const [nodesBranchs, setNodesBranchs] = useState([]);
  const isReturn = useRef(true);
  // const isAdmin = useRef(false);
  const [isAdmin, setIsAdmin] = useState([]);
  const getUser = userStore((state) => state.getUser);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const openModal = () => {
    setOpen(true);
  };

  const finisheModal = () => {
    queryClient.invalidateQueries(['projectFlow', idprojeto]);
    setModalOpen(false);
    setLoading(false);
    onClose();
  };

  const handleChangeStatus = async (data) => {
    setLoading(true);
    openModal();
    try {
      if (isReturn.current) {
        if (returnNodes.length > 0) {
          const returnNodesIds = returnNodes.map((module) => module.id);

          // eslint-disable-next-line array-callback-return, consistent-return
          const prevNodesChangeStatusIds = prevNodesChangeStatus
            .map((module) => {
              if (
                !returnNodesIds.includes(module.id) &&
                Number(module.parentNode) > Number(returnNodes[0].parentNode)
              ) {
                return module.id;
              }
              return null;
            })
            .filter((moduleId) => moduleId !== null);

          const body = {
            nodeidcurrent: moduleReturns.id,
            returnnodesids: [...new Set(returnNodesIds)],
            nodesnotexecuteids: [...new Set(prevNodesChangeStatusIds)],
            argument: data.argsForChangeStatus,
          };

          const response = await api.put(`/modules/return/${idprojeto}`, body);

          if (response.data.status === 'success') {
            toast.success(i18next.t('flow-Modals-approve.return-toast'));
          }
        } else {
          toast(i18next.t('flow-Modals-approve.return-error-module-action'));
          setModalOpen(false);
          setLoading(false);
          onClose();
        }
      } else {
        toast(i18next.t('flow-Modals-approve.return-error-checkpoint'));
        setModalOpen(false);
        setLoading(false);
        onClose();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${i18next.t('flow-Modals-approve.conflict')}`);
        setTimeout(() => {
          queryClient.invalidateQueries(['projectFlow', idprojeto]);
          setModalOpen(false);
          setLoading(false);
          onClose();
        }, 2000);
      }
      if (error.response.status === 401) {
        toast.error(`${i18next.t('not-Access')}`);
        setTimeout(() => {
          queryClient.invalidateQueries(['projectFlow', idprojeto]);
          setModalOpen(false);
          setLoading(false);
          onClose();
        }, 2000);
      }
      toast.error(error.response.data.data.errors[0]);
      setModalOpen(false);
      setLoading(false);
      onClose();
    }
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setSelecktNode({});
  };

  useEffect(() => {
    const checkAdministrator = async () => {
      const userPermissions = await getUser();
      const admin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
      setIsAdmin(admin);
    };
    checkAdministrator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const goTravelBranch = (node) => {
      if (node.id !== 'a') {
        const edgeNode = edges.find((edg) => edg.target === node.id);
        const prevNode = nodes.find((nds) => nds.id === edgeNode.source);
        const edgeNodeNextUnion = edges.find((edg) => edg.source === prevNode.id);
        const nodeNextUnion = nodes.find((nds) => nds.id === edgeNodeNextUnion.target);
        if (prevNode.typeNode === 'UNIAO' && nodeNextUnion.categoryNode === 'ACAO') {
          setNodesBranchs((nds) => nds.concat(prevNode));
          return;
        }
        if (prevNode.typeNode === 'UNIAO' && nodeNextUnion.categoryNode !== 'ACAO') {
          const edgesNode = edges.filter((edg) => edg.target === prevNode.id);
          edgesNode.forEach((edg) => {
            const prevNodesUnion = nodes.find((nds) => nds.id === edg.source);
            setReturnNodes((nodes) => nodes.concat(prevNodesUnion));
          });
          setNodesBranchs((nds) => nds.concat(prevNode));
          return;
        }
        /* if (prevNode.typeNode === 'CHECK') {
          setNodesBranchs((nds) => nds.concat(prevNode));
          return;
        } */
        if (prevNode.id === 'a') {
          setNodesBranchs((nds) => nds.concat(prevNode));
          return;
        }
        setNodesBranchs((nds) => nds.concat(prevNode));
        goTravelBranch(prevNode);
      }
    };
    goTravelBranch(moduleReturns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleReturns, nodes, edges]);

  useEffect(() => {
    nodesBranchs.forEach((nds) => {
      if (
        (Number(nds.parentNode) < Number(moduleReturns.parentNode) &&
          nds.categoryNode === 'ACAO' &&
          nds.status === ModuleStatus.executed) ||
        (nds.typeNode === 'INICIAL' && nds.status === ModuleStatus.executed)
      ) {
        setAllPrevActionNodes((nodes) => nodes.concat(nds));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesBranchs, moduleReturns.id]);

  useEffect(() => {
    const run = async () => {
      if (moduleReturns.id !== 'a') {
        const edgeNode = edges.find((edg) => edg.target === moduleReturns.id);
        const prevNode = nodes.find((nds) => nds.id === edgeNode.source);
        if (prevNode.typeNode === 'UNIAO') {
          const edgesNode = edges.filter((edg) => edg.target === prevNode.id);
          edgesNode.forEach((edg) => {
            const prevNodesUnion = nodes.find((nds) => nds.id === edg.source);
            setReturnNodes((nodes) => nodes.concat(prevNodesUnion));
          });
        } else if (prevNode.typeNode === 'CHECK') {
          if (!isAdmin) {
            isReturn.current = false;
            return;
          }
          // isReturn.current = false;
          allPrevActionNodes.forEach((nds) => {
            if (nds.parentNode === allPrevActionNodes[0].parentNode) {
              setReturnNodes((nodes) => nodes.concat(nds));
            }
          });
        } else {
          allPrevActionNodes.forEach((nds) => {
            if (nds.parentNode === allPrevActionNodes[0].parentNode) {
              setReturnNodes((nodes) => nodes.concat(nds));
            }
          });
        }
      }
    };

    run();
  }, [moduleReturns.id, allPrevActionNodes, nodes, edges, isAdmin]);

  const checkModuleExists = (node) => nodeCheckpointLater.some((nds) => nds.id === node.id);

  useEffect(() => {
    async function findNodesLaterCheckPoint() {
      if (!isAdmin) {
        const nodeCheckpoint = nodesBranchs.find(
          (nds) => Number(nds.parentNode) < Number(moduleReturns.parentNode) && nds.typeNode === 'CHECK'
        );
        if (nodeCheckpoint) {
          allPrevActionNodes.forEach((nds) => {
            if (Number(nds.parentNode) > Number(nodeCheckpoint.parentNode) && !checkModuleExists(nds)) {
              setNodeCheckpointLater((nodes) => nodes.concat(nds).reverse());
            }
          });
        }
        const user = await getUser();
        const userCanReturnAnywhereModule = returnAnywhereModuleConfigValue.includes(user.roles[0].perfilnome);
        if (!nodeCheckpoint && userCanReturnAnywhereModule) {
          allPrevActionNodes.forEach((nds) => {
            setNodeCheckpointLater((nodes) => nodes.concat(nds));
          });
        }
      } else if (nodeCheckpointLater.length <= 0 && isAdmin) {
        allPrevActionNodes.forEach((nds) => {
          setNodeCheckpointLater((nodes) => nodes.concat(nds));
        });
      }
    }
    findNodesLaterCheckPoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPrevActionNodes, moduleReturns.id]);

  useEffect(() => {
    if (returnNodes.length > 0) {
      nodesBranchs.forEach((nds) => {
        if (
          Number(nds.parentNode) < Number(moduleReturns.parentNode) &&
          Number(nds.parentNode) > Number(returnNodes[0].parentNode)
          // && !prevNodesChangeStatus.find((node) => node.id === nds.id)
        ) {
          setPrevNodesChangeStatus((nodes) => nodes.concat(nds));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnNodes, moduleReturns.id]);

  const nodeTurnBackSelected = (node) => {
    if (node.id === selectNode.id) {
      setSelecktNode({});
    }
    setSelecktNode(node);
    setReturnNodes([]);
    setReturnNodes((nds) => nds.concat(node));
  };

  const textfieldReturnsFlow = () => (
    <TextField
      key="textfield-canceled-project"
      id="textfield-canceled-project"
      label={i18next.t('returnArgs')}
      // label="Argumentos para o retorno do fluxo"
      fullWidth
      margin="dense"
      required
      multiline
      rows={4}
      name="argsForChangeStatus"
      value={argument}
      {...register('argsForChangeStatus')}
      onChange={(e) => setArgument(e.target.value)}
      helperText={errors.argsForChangeStatus?.message}
    />
  );

  return (
    <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
      <GridStyle sx={{ position: 'relative', height: 'max-content' }}>
        <Typography variant="h3" align="center">
          {propertiesConfirm.title}
        </Typography>
        <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
          <IconButton size="large" onClick={() => closeModalHandler()}>
            <Icon icon={closeIcon} />
          </IconButton>
        </Box>
        <Typography variant="h5" align="center">
          {nodeCheckpointLater.length > 0 ? propertiesConfirm.contentCheckPoint : propertiesConfirm.content}
        </Typography>

        {useMemo(
          () =>
            nodeCheckpointLater.length > 0 && (
              <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 345, marginTop: 1, paddingBottom: 0 }}>
                <FormGroup
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: '1rem',
                    // overflowY: 'scroll',
                    height: '50vh',
                  }}
                >
                  {nodeCheckpointLater.map((node, i) => (
                    <Tooltip title={node.modulotxt.replaceAll('_', '-')} key={`checkbox_tooltip-${i}`}>
                      <FormControlLabel
                        key={`checkbox_formLabelNodes-${i}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column-reverse',
                          width: '20%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        control={
                          <Checkbox
                            sx={{ display: 'none' }}
                            checked={selectNode.id === node.id}
                            onChange={() => nodeTurnBackSelected(node)}
                            key={`checkbox_nodeTurnBack-${i}`}
                          />
                        }
                        label={
                          <Card
                            key={`node_ref-${i}`}
                            sx={{
                              width: '100%',
                              maxHeight: '95%',
                              border: selectNode.id === node.id ? '3px solid #FECD0E' : 'node',
                            }}
                          >
                            <Typography sx={{ textAlign: 'center' }} variant="h6">
                              {i18next.t('stages-Flow.stage')} {node.parentNode}
                            </Typography>
                            <CardMedia
                              sx={{ width: 75, height: 75, margin: '0 auto' }}
                              image={`${(() => moduleProperties.find((nds) => nds.typeBack === node.typeNode).img)()}`}
                              alt={`icone do modu de ${(() =>
                                moduleProperties.find((nds) => nds.typeBack === node.typeNode).categ)()}`}
                            />
                            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="div"
                                sx={{ width: '80%', fontWeight: 'bold' }}
                              >
                                {`${node.modulotxt.replaceAll('_', '-').slice(0, 20)} ...`}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {node.modulodesc === null || node.modulodesc.length <= 0
                                  ? 'No description'
                                  : `${node.modulodesc.slice(0, 30)} ...`}
                              </Typography>
                            </CardContent>
                          </Card>
                        }
                      />
                    </Tooltip>
                  ))}
                </FormGroup>
              </Scrollbar>
            ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [nodeCheckpointLater, selectNode.id]
        )}

        {textfieldReturnsFlow()}

        <ModalAprovaAnexo
          open={open}
          setOpen={setOpen}
          idprojeto={idprojeto}
          nodeIdTarget={selectNode.id}
          nodeIdCurrent={moduleReturns.id}
          finisheModal={finisheModal}
        />

        {loading && <CircularProgress size={70} sx={{ position: 'absolute', top: '50%', left: '50%', zIndex: '99' }} />}

        <Stack sx={{ margin: '5% auto ', flexDirection: 'row', justifyContent: 'center', gap: '3rem' }}>
          <Button onClick={closeModalHandler} variant="contained">
            {i18next.t('flow-Modals.exit')}
          </Button>
          <Button variant="contained" onClick={handleSubmit(handleChangeStatus)} color="success">
            {i18next.t('flow-Modals.confirm-button')}
          </Button>
        </Stack>
      </GridStyle>
    </ModalStyle>
  );
};
