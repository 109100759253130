import axios from 'axios';

export const baseURL = process.env.NODE_ENV==="development" ? window.env.REACT_APP_DEV_BACKEND_URL : window.env.REACT_APP_BACKEND_URL;

export const baseURLFile = process.env.NODE_ENV==="development" ? window.env.REACT_APP_DEV_FILES_URL : window.env.REACT_APP_FILES_URL;

const api = axios.create({
  baseURL: `${baseURL}/api`,
});

const language = localStorage.getItem('i18nextLng');
const token = localStorage.getItem('token');

// eslint-disable-next-line dot-notation
api.defaults.headers.common['Authorization'] = token;

api.defaults.headers.common['Accept-Language'] = `${language}`;

export default api;
