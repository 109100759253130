import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  750: '#4D4D4D',
  800: '#212B36',
  1000: '1E1E1F',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  ultralighter: '#FEFAD5',
  lighter: '#FEFF03',
  light: '#E8D102',
  main: '#FECD0E',
  dark: '#FF9A03',
  darker: '#FF9A03',
  contrastText: '#000000',
};
const PRIMARYDARK = {
  ultralighter: '#524f48',
  lighter: '#FEFF03',
  light: '#E8D102',
  main: '#FECD0E',
  dark: '#FF9A03',
  darker: '#FF9A03',
  contrastText: '#000000',
};

const SECONDARY = {
  lighter: '#FEFF03',
  light: '#E8D102',
  main: '#FECD0E',
  dark: '#E8A315',
  darker: '#FF9A03',
  contrastText: GREY[800],
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#FFF',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#FFF',
};

// Indicadores da home
const INICIADO = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const PROXIMO = {
  lighter: '#FFE67A',
  light: '#FFF601',
  main: '#FFDD00',
  dark: '#E89D0C',
  darker: '#A85B08',
  contrastText: GREY[800],
};

const ATRASADO = {
  lighter: '#FFE7D9',
  light: '#FF4842',
  main: '#C41613',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: GREY[800],
};

const FINALIZADO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: GREY[800],
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const GREYBUTTON = {
  main: '#637381',
};
const GRAYBUTTON = {
  light:"#e4e9f7",
  main: '#d9dbde',
  dark:'#a3a5a8',
};

const GREYBUTTONDARK = {
  main: '#fff',
};

const palette = {
  mode: 'light',
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  iniciado: { ...INICIADO },
  proximo: { ...PROXIMO },
  atrasado: { ...ATRASADO },
  finalizado: { ...FINALIZADO },
  grey: GREY,
  greyButton: { ...GREYBUTTON },
  grayButton: { ...GRAYBUTTON },
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  table_filler: '#F1F3F4',
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], login: GREY[750], logintitle: GREY[0] },
  background: { paper: '#fff', default: GREY[100], neutral: GREY[200], login: GREY[1000] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export const paletteDark = {
  mode: 'dark',
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARYDARK },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  iniciado: { ...INICIADO },
  proximo: { ...PROXIMO },
  atrasado: { ...ATRASADO },
  finalizado: { ...FINALIZADO },
  grey: GREY,
  greyButton: { ...GREYBUTTONDARK },
  grayButton: { ...GRAYBUTTON },
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  table_filler: '#2E3236',
  text: { primary: GREY[0], secondary: GREY[100], disabled: GREY[300], login: GREY[500], logintitle: GREY[0] },
  background: { paper: GREY[700], default: GREY[900], neutral: GREY[900], login: GREY[1000] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_80],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};
export default palette;
