// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Box, Grid, CardMedia, Link } from '@mui/material';
// router
import { Link as RouterLink } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import LogoEnterprice from '../components/LogoEnterprise';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthActiveDirectory from '../sections/auth/AuthSocial';
// images
import Background from '../assets/logos-login/new-background.jpg';
import BackgroundFlow from '../assets/logos-login/flow.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  lineHeight: 0,
  zIndex: -1,
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: theme.spacing(0, 2, 0, 3),
  },
}));

const FooterStyle = styled('div')(({ theme }) => ({
  bottom: 0,
  lineHeight: 0,
  zIndex: -1,
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  position: 'absolute',
  justifyContent: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 25, 0, 20),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 490,
  height: 600,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const stylesBackground = {
  display: 'flex',
  justifyContent: 'center',
  zIndex: -1,
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
};

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslation();

  return (
    <Page title="Login">
      <Grid sx={stylesBackground} alt="login">
        <RootStyle>
          {smUp && (
            <Box sx={{ px: 15, mt: 0, mb: 0, height: 700, width: 900 }}>
              <Typography variant="h3" sx={{ color: 'text.logintitle' }}>
                {t('login.welcome')} <br /> Artwork Management
              </Typography>
              <CardMedia component="img" image={BackgroundFlow} />
            </Box>
          )}
          <SectionStyle>
            <Container maxWidth="sm">
              <HeaderStyle>
                <Logo />
              </HeaderStyle>
              <ContentStyle>
                <Typography variant="h4" sx={{ color: 'text.login' }} gutterBottom>
                  {t('login.title')}
                </Typography>
                <Link underline="none" component={RouterLink} to="/loginad">
                  <AuthActiveDirectory />
                </Link>
                <Typography variant="h5" sx={{ color: 'text.login', mb: 3 }}>
                  {t('login.subtitle')}
                </Typography>
                <LoginForm />
                <FooterStyle>
                  <LogoEnterprice />
                </FooterStyle>
              </ContentStyle>
            </Container>
          </SectionStyle>
        </RootStyle>
      </Grid>
    </Page>
  );
}
