/* eslint-disable no-lone-blocks */
import { memo, useState } from 'react';
import i18next from 'i18next';
import { Button } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';

// eslint-disable-next-line prefer-arrow-callback
export const ContextMenu = memo(({ isOpen, position, actions = [], onMouseLeave }) => {
  // eslint-disable-next-line no-unused-expressions
  const [subOpen, setSubOpen] = useState(false);

  // eslint-disable-next-line consistent-return
  const optionsCheck = (options) => {
    if (options && subOpen) {
      return options.map((option, i) => (
        <Button
          key={`${option.label}-${i}`}
          onClick={option.effect}
          sx={{ color: '#757575', width: '60%' }}
          component="span"
        >
          {option.label}
        </Button>
      ));
    }
  };

  const handleClick = () => {
    setSubOpen(!subOpen);
  };

  return isOpen ? (
    <div
      key={`contextMenu-${position.x}`}
      style={{
        position: 'absolute',
        left: position.x,
        top: position.y,
        zIndex: 1000,
        border: 'solid 2px #CCC',
        borderRadius: 10,
        backgroundColor: '#FCFDFD',
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
        color: 'black',
      }}
      onMouseLeave={() => { }}
    >
      {actions.map((action) => {
        const { label, effect, options, args } = action;
        return (
          <Box key={`container-${label}`} sx={{ textAlign: 'center' }}>
            <Button
              key={label}
              onClick={() => {
                effect(args);
                onMouseLeave();
              }}
              sx={{ color: 'black' }}
            >
              {label}
            </Button>
            {options && (
              <Button key={`buttonmore-${label}`} onClick={handleClick} sx={{ color: 'black', marginRight: ' 19%  ' }}>
                {i18next.t('flow-options.moreoptions')}
                {subOpen ? <ExpandLess /> : <ExpandMore />}
              </Button>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'columns',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {optionsCheck(action.options)}
            </Box>
          </Box>
        );
      })}
      <Button onClick={onMouseLeave} sx={{ color: 'black' }}>
        {' '}
        {i18next.t('flow-options.close')}
      </Button>
    </div>
  ) : null;
});
