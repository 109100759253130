import { useState } from 'react';
import { Button, Tooltip, Typography, MenuItem, Menu } from '@mui/material';
import DetectableOverflow from 'react-detectable-overflow';
import { toast } from 'react-hot-toast';
import { Icon } from '@iconify/react';
import folderOpenIcon from '@iconify/icons-material-symbols/folder-open';
import folderIcon from '@iconify/icons-material-symbols/folder';
import documentMultipleIcon from '@iconify/icons-mdi/file-document-multiple';
// import documentRemoveIcon from '@iconify/icons-mdi/file-document-remove';

import ModalViewAnnex from '../../../../ModalViewAnnex';

import { baseURLFile } from '../../../../../service/api';

export default function FileComponent({
  item,
  directory,
  setDirectory,
  setNavigatedDirectory,
  fileSelectedHandle,
  checkFileIsSelected,
}) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [path, setpath] = useState('');
  const [openModalPdf, setModalPdf] = useState(false);
  const [imageFilename, setImageFilename] = useState();
  const [contextMenu, setContextMenu] = useState(null);


  const defineItemIcon = (fileObj) => {
    let icon;
    if (fileObj.type === 'directory') {
      if (item.isEmpty === false) {
        icon = <Icon height={150} width={100} icon={folderOpenIcon} />;
      } else {
        icon = <Icon height={150} width={100} icon={folderIcon} />;
      }
    }
    if (fileObj.type === 'file') {
      icon = <Icon height={150} width={100} icon={documentMultipleIcon} />;
      if (
        fileObj.extension === 'apng' ||
        fileObj.extension === 'avif' ||
        fileObj.extension === 'gif' ||
        fileObj.extension === 'gif' ||
        fileObj.extension === 'jpg' ||
        fileObj.extension === 'jpeg' ||
        fileObj.extension === 'jfif' ||
        fileObj.extension === 'pjpeg' ||
        fileObj.extension === 'pjp' ||
        fileObj.extension === 'png' ||
        fileObj.extension === 'svg' ||
        fileObj.extension === 'webp'
        ) {
          icon = <img src={`${baseURLFile}/${fileObj.path}`} alt={fileObj.filename} height={150} style={{
            opacity:(fileObj.status === 'O' || fileObj.status === 'P') && 0.55
          }}/>;
          // icon = <img src={`${baseURLFile}/${fileObj.path}`} alt={fileObj.filename} height={150} />;
        }
        // if (fileObj.status === 'O') icon = <Icon height={150} width={100} icon={documentRemoveIcon} />;
    }
    return icon;
  };

  const onDoubleClickHandler = () => {
    toast.dismiss();
    setTimeout(() => {
      toast.dismiss();
    }, 200);
    if (item.status === 'O' || item.status === 'P') return;
    // O back fará a transformação dos arquivos em PDF para visualização
    if (item.type === 'file') {
      if (item.extension === 'pdf') {
        setModalPdf(true);
        setpath(item.path);
        setImageFilename(item.moduloanexonome);
        // navigate(`/repository/${item.filename}`, { state: `${`${baseURLFile}/${item.path}`}` });
      }
    }
    if (item.type === 'directory') {
      setDirectory(item.path);
      setNavigatedDirectory((prevState) => [...prevState, item.path]);
    }
  };
  const checkPath = (currentItem, settedPath) => {
    const splittedSettedPath = settedPath?.split('/');
    const splittedPathItem = currentItem?.path.split('/');
    if (
      splittedSettedPath?.length + 1 === splittedPathItem?.length &&
      splittedSettedPath?.at(-1) === splittedPathItem?.at(splittedPathItem?.length - 2)
    ) {
      return true;
    }
    return false;
  };

  const downloadFile = (baseURLFile, fileObj) => {
    fetch(`${baseURLFile}/${fileObj.path}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download =  fileObj.filename
        alink.click();
      });
    });
  };

  const downloadFilePDF = (baseURLFile, fileObj) => {
    fetch(`${baseURLFile}/${fileObj.pathpdf}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${fileObj.filename.split('.')[0]}.pdf`;
        alink.click();
      });
    });
  };


  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Button
      disableRipple
      sx={{
        display: !checkPath(item, directory) && 'none',
        justifyContent: 'start',
        flexDirection: 'column',
        height: 200,
        width: 162.5,
        '& .MuiButton-startIcon': { marginRight: '0px' },
        border: checkFileIsSelected(item) ? '3px solid #FECD0E' : 'node',
      }}
      startIcon={defineItemIcon(item)}
      onClick={() => fileSelectedHandle(item)}
      color="greyButton"
      onDoubleClick={onDoubleClickHandler}
      onContextMenu={handleContextMenu}
    >
      <ModalViewAnnex
        path={path}
        openModalPdf={openModalPdf}
        setModalPdf={setModalPdf}
        filename={imageFilename}
        id={imageFilename}
      />

      <Tooltip title={isOverflowing && (item.type === 'file' ? item.filename : item.name)}>
        <Typography
          component="span"
          sx={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            textTransform: 'none',
            textDecoration: item.status === 'O' && 'line-through',
          }}
        >
          <DetectableOverflow onChange={() => setIsOverflowing(true)}>
            {item.type === 'file' ? item.filename : item.name}
          </DetectableOverflow>
        </Typography>
      </Tooltip>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={() => downloadFile(baseURLFile, item)}>Download Original</MenuItem>
        <MenuItem onClick={() => downloadFilePDF(baseURLFile, item)}>Download Pdf</MenuItem>
        {/* <MenuItem onClick={handleClose}>Print</MenuItem> */}
      </Menu>
    </Button>
  );
}
