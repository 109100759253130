// material
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import logo from '../assets/logo-precision/pps-logo.png';

// ----------------------------------------------------------------------

const ImgBoxStyle = styled(Box)(() => ({
  width: 150,
}));

// ----------------------------------------------------------------------

LogoEnterprise.propTypes = {
  sx: PropTypes.any,
};

// eslint-disable-next-line no-unused-vars
export default function LogoEnterprise({ sx }) {
  return <ImgBoxStyle component="img" src={logo} />;
}
