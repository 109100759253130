/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import i18next from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import Badge from '@mui/material/Badge';
import { Box } from '@mui/system';
import {
  Autocomplete,
  Chip,
  TextField,
  Button,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Checkbox,
  Divider,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RuleIcon from '@mui/icons-material/Rule';
import EmailIcon from '@mui/icons-material/Email';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { useQuery, useQueryClient } from 'react-query';

import { toast } from 'react-hot-toast';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { Add, NotificationAdd, NotificationImportant, NotificationImportantOutlined, NotificationImportantRounded, Notifications, NotificationsActive, PlusOne, PlusOneSharp, PriorityHigh, Report, ReportOutlined, SavedSearch, Star, StarBorderOutlined, StarOutline, Title } from '@mui/icons-material';
import { NodeSelect } from '../BasicModal';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';
import api from '../../../../../service/api';

const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#454F5B' : '#FFF',
  color: theme.palette.text.secondary,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  padding: '1.5rem',
  borderRadius: '1rem',
}));
const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#161C24' : '#DCDCD0',
  color: theme.palette.text.secondary,
}));
const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#161C24' : '#DCDCD0',
  color: theme.palette.text.secondary,
}));

export default function ModalForm() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [sendFormLoad, setSendFormLoad] = useState(false);
  const [arrPropertiesForm, setArrPropertiesForm] = useState();
  const emptyArray = [];
  const { control, handleSubmit } = useForm({});
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'fieldsForm',
  });

  const allPositionsFieldsForRemoved = useMemo(() => Array(fields.length).fill("_").map((item, index) => index), [fields])
  const queryClient = useQueryClient();

  let nodeSelectCurrent;
  let idprojet;

  const nodeSelect = useContext(NodeSelect);
  if (nodeSelect !== undefined) {
    const { nodeSelectCheck, idprojeto } = nodeSelect;
    nodeSelectCurrent = nodeSelectCheck;
    idprojet = idprojeto;
  }

  const loadForm = async (nodeId, idProjeto) => {
    const responseProject = await api.get(`modules/form/${nodeId}/${idProjeto}`);
    const { form } = responseProject.data.data;
    return form;
  };

  const accountCache = queryClient.getQueryData(['formNode', nodeSelectCurrent.id, idprojet]);

  const { data: form, isFetching } = useQuery(
    ['formNode', nodeSelectCurrent.id, idprojet],
    () => loadForm(nodeSelectCurrent.id, idprojet),
    {}
  );

  useEffect(() => {
    if (data === null) {
      if (form !== null && form !== undefined) {
        setData(form.form);
        setArrPropertiesForm(JSON.parse(form?.form));
      }
    } else {
      setArrPropertiesForm(JSON.parse(data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  // useEffect(() => {
  //   const loadForm = async () => {
  //     if (data === null) {
  //       const responseProject = await api.get(`modules/form/${nodeSelectCurrent.id}/${idprojet}`);
  //       const { form } = responseProject.data.data;
  //       if (form !== null && form !== undefined) {
  //         setData(form.form);
  //         setArrPropertiesForm(JSON.parse(form.form));
  //       }
  //     } else {
  //       setArrPropertiesForm(JSON.parse(data));
  //     }
  //   };

  //   loadForm();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open]);

  useEffect(() => {
    if (open === true) {
      if (accountCache !== null && accountCache !== undefined) {
        // setArrPropertiesForm(accountCache.form);
        setData(accountCache.form);
        rebuildFieldsForm(JSON.parse(accountCache.form));
      }
      if (accountCache === null || accountCache === undefined) {
        if (arrPropertiesForm && arrPropertiesForm.length > 0 && data !== null) {
          rebuildFieldsForm(arrPropertiesForm);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setArrPropertiesForm([...emptyArray]);
    remove(allPositionsFieldsForRemoved);
    setOpen(false);
  };

  const rebuildFieldsForm = (arrayForm) => {
    arrayForm.forEach((field) => {
      if (field.able === true) {
        append({
          id: field.id,
          type: field.type,
          question: field.question,
          options: field.options,
          answer: null,
          required: field.required,
          important: field.important,
          able: field.able,
          position: field.position,
        });
      }
    });
  };

  const defaultAnwser = [
    { label: `${i18next.t('defaultOption.yes')}` },
    { label: `${i18next.t('defaultOption.no')}` },
    { label: `${i18next.t('defaultOption.maybe')}` },
  ];

  const Textbox = ({ item, index }) => {
    item.position.splice(0, item.position.length);
    item.position.push(index + 1);
    return (
      <BoxStyled key={item.id}>
        <Controller
          render={({ field }) => (
            <TextField
              label={i18next.t('flow-Modals-form.question')}
              fullWidth
              type="text"
              helperText={i18next.t('buildForm.helperText', { type: `${item.type}` })}
              autoFocus
              maxRows={4}
              margin="dense"
              required
              {...{ ...field, value: field.value.trimStart() }}
            />
          )}
          rules={{
            validate: {
              noWhitespace: value => value.trim() !== '' // Custom rule to check for non-whitespace value
            }
          }}
          name={`fieldsForm.${index}.question`}
          control={control}
        />
        <Tooltip title={i18next.t('flow-Modals-form.required')}>
          <Checkbox
            defaultChecked={item.required[0]}
            icon={<LockOpenIcon />}
            checkedIcon={<LockIcon />}
            onChange={(event) => {
              console.log(item);
              item.required.splice(0, item.required.length);
              item.required.push(event.target.checked);
              return event.target.checked;
            }}
          />
        </Tooltip>
        <Tooltip title={i18next.t('flow-Modals-form.important')}>
          <Checkbox
            defaultChecked={item.important?.[0]}
            icon={<ReportOutlined />}
            checkedIcon={<Report />}
            onChange={(event) => {
              item.important.splice(0, item.important.length);
              item.important.push(event.target.checked);
              return event.target.checked;
            }}
          />
        </Tooltip>

        <IconButtonStyled
          type="button"
          onClick={() => {
            remove(index);
          }}
        >
          <DeleteIcon />
        </IconButtonStyled>
        <IconButtonStyled
          type="button"
          onClick={() => {
            item.position.splice(0, item.position.length);
            item.position.push(index);
            move(index, index - 1);
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButtonStyled>
        <IconButtonStyled
          type="button"
          onClick={() => {
            if (index + 1 < fields.length) {
              item.position.splice(0, item.position.length);
              item.position.push(index + 2);
              move(index, index + 1);
            }
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButtonStyled>
      </BoxStyled>
    );
  };

  const ComboBox = ({ item, index }) => {
    item.position.splice(0, item.position.length);
    item.position.push(index + 1);
    return (
      <BoxStyled key={item.id}>
        <Controller
          render={({ field }) => (
            <TextField
              label={i18next.t('flow-Modals-form.question')}
              multiline
              fullWidth
              autoFocus
              helperText={i18next.t('buildForm.helperText', { type: `${item.type}` })}
              maxRows={4}
              margin="dense"
              required
              {...{ ...field, value: field.value.trimStart() }}
            />
          )}
          rules={{
            validate: {
              noWhitespace: value => value.trim() !== '',
            }
          }}
          name={`fieldsForm.${index}.question`}
          control={control}
        />

        <Autocomplete
          sx={{ width: '100%' }}
          multiple
          id="tags-outlined"
          options={defaultAnwser.map((option) => option.label)}
          freeSolo
          defaultValue={item.options[0]}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" key={`chip-${item.id}`} label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                required: item.options.length === 0,
              }}
              required
              helperText={i18next.t('buildForm.optionsCheck')}
              variant="outlined"
              label={i18next.t('flow-Modals-form.optionsCheck')}
            />
          )}
          onChange={(event, newValue, action) => {
            if (action === "createOption" && !newValue.slice(-1)[0].trim().length) {
              newValue.pop()
              return newValue;
            }
            item.options.splice(0, item.options.length);
            item.options.push([...newValue]);
            return newValue;
          }}
        />
        <Tooltip title={i18next.t('flow-Modals-form.required')}>
          <Checkbox
            defaultChecked={item.required[0]}
            icon={<LockOpenIcon />}
            checkedIcon={<LockIcon />}
            onChange={(event) => {
              item.required.splice(0, item.required.length);
              item.required.push(event.target.checked);
              return event.target.checked;
            }}
          />
        </Tooltip>
        <Tooltip title={i18next.t('flow-Modals-form.important')}>
          <Checkbox
            defaultChecked={item.important?.[0]}
            icon={<ReportOutlined />}
            checkedIcon={<Report />}
            onChange={(event) => {
              item.important.splice(0, item.important.length);
              item.important.push(event.target.checked);
              return event.target.checked;
            }}
          />
        </Tooltip>
        <IconButtonStyled
          type="button"
          onClick={() => {
            remove(index);
          }}
        >
          <DeleteIcon />
        </IconButtonStyled>
        <IconButtonStyled
          type="button"
          onClick={() => {
            item.position.splice(0, item.position.length);
            item.position.push(index);
            move(index, index - 1);
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButtonStyled>
        <IconButtonStyled
          type="button"
          onClick={() => {
            if (index + 1 < fields.length) {
              item.position.splice(0, item.position.length);
              item.position.push(index + 2);
              move(index, index + 1);
            }
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButtonStyled>
      </BoxStyled>
    );
  };
  const Date = ({ item, index }) => {
    item.position.splice(0, item.position.length);
    item.position.push(index + 1);
    return (
      <BoxStyled key={item.id}>
        <Controller
          render={({ field }) => (
            <TextField
              label={i18next.t('flow-Modals-form.question')}
              multiline
              fullWidth
              autoFocus
              helperText={i18next.t('buildForm.helperText', { type: `${item.type}` })}
              maxRows={4}
              margin="dense"
              required
              {...{ ...field, value: field.value.trimStart() }}
            />
          )}
          name={`fieldsForm.${index}.question`}
          control={control}
        />
        <TextField fullWidth margin="dense" disabled type="date" />

        <Tooltip title={i18next.t('flow-Modals-form.required')}>
          <Checkbox
            defaultChecked={item.required[0]}
            icon={<LockOpenIcon />}
            checkedIcon={<LockIcon />}
            onChange={(event) => {
              item.required.splice(0, item.required.length);
              item.required.push(event.target.checked);
              return event.target.checked;
            }}
          />
        </Tooltip>
        <Tooltip title={i18next.t('flow-Modals-form.important')}>
          <Checkbox
            defaultChecked={item.important?.[0]}
            icon={<ReportOutlined />}
            checkedIcon={<Report />}
            onChange={(event) => {
              item.important.splice(0, item.important.length);
              item.important.push(event.target.checked);
              return event.target.checked;
            }}
          />
        </Tooltip>
        <IconButtonStyled
          type="button"
          onClick={() => {
            remove(index);
          }}
        >
          <DeleteIcon />
        </IconButtonStyled>
        <IconButtonStyled
          type="button"
          onClick={() => {
            item.position.splice(0, item.position.length);
            item.position.push(index);
            move(index, index - 1);
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButtonStyled>
        <IconButtonStyled
          type="button"
          onClick={() => {
            if (index + 1 < fields.length) {
              item.position.splice(0, item.position.length);
              item.position.push(index + 2);
              move(index, index + 1);
            }
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButtonStyled>
      </BoxStyled>
    );
  };

  const ButtonAppendField = ({ question, type, tooltip, fields, children }) => (
    // <Tooltip title="Adicionar campo de textBox">
    <Tooltip title={tooltip} >
      <Badge badgeContent={<b style={{ fontSize: "15px" }}>+</b>} showZero color="primary">
        <IconButtonStyled
          sx={{ backgroundColor: 'background.paper', color: 'text.secondary' }}
          type="button"
          onClick={() => {
            const filterFields = fields.filter((field) => field.type === type);
            console.log('filterFields', filterFields)
            if (type !== 'email') {
              append({
                id: `field-${type}-${fields.length + 1}`,
                type: `${type}`,
                question: `${question}`,
                options: [],
                answer: null,
                important: [false],
                required: [false],
                able: true,
                position: [0],
              });
            }
            if (type === 'email') {
              append({
                id: `field-${type}-${fields.length + 1}`,
                type: `${type}`,
                question: `${question}`,
                options: [],
                answer: null,
                required: [false],
                important: [false],
                able: true,
                position: [0],
              });
            }
          }}
        >
          {children}
        </IconButtonStyled>
      </Badge>
    </Tooltip>
  );

  const submit = async (data) => {
    setSendFormLoad(true)
    try {
      if (data.fieldsForm.length > 0) {
        nodeSelectCurrent.form = data.fieldsForm;
        await integrationsAPI.updateModule(idprojet, nodeSelectCurrent);
        queryClient.invalidateQueries(['formNode', nodeSelectCurrent.id, idprojet]);
        // setArrPropertiesForm([...emptyArray]);
        setData(null);
        remove(allPositionsFieldsForRemoved);
        setOpen(false);
      } else {
        toast.error(`${i18next.t('flow-Modals-form.emptyForm')}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    } finally {

      setSendFormLoad(false)
    }

  };

  return (
    <Box>
      <Tooltip title={i18next.t('flow-Modals-form.tooltip')}>
        <Button
          variant="contained"
          onClick={!isFetching ? handleClickOpen : null}
          sx={{
            marginTop: 2,
            width: 50,
            height: 50,
            backgroundColor: 'background.neutral',
            color: 'text.secondary',
          }}
        >
          {!isFetching ? <Icon icon={fileTextFill} fontSize={60} /> : <CircularProgress size={30} />}
        </Button>
      </Tooltip>

      {sendFormLoad && (
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0%',
            left: '0%',
            zIndex: '999',
            // backgroundColor: 'rgba(0,0,0,0.5)'
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}

      {!isFetching && (
        <Dialog
          onClose={handleClose}
          open={open}
          maxWidth="1200px"
          fullWidth
          sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
        >
          <>
            <IconButton
              onClick={handleClose}
              id="Botão fechar modal"
              sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitleStyled> {i18next.t('flow-Modals-form.tooltip')}</DialogTitleStyled>
            <DialogContentStyled>
              <form onSubmit={handleSubmit((data) => submit(data))}>
                <Box sx={{ display: 'flex', gap: '1rem', margin: '1rem 0' }}>
                  <ButtonAppendField
                    tooltip={i18next.t('buildForm.tooltip', { type: `Textbox` })}
                    question={'Textbox'}
                    type={'textbox'}
                    fields={fields}
                  >
                    <TextSnippetIcon fontSize="2rem" />
                  </ButtonAppendField>
                  <ButtonAppendField
                    tooltip={i18next.t('buildForm.tooltip', { type: `Multiline` })}
                    question={'Multiline'}
                    type={'textboxmultiline'}
                    fields={fields}
                  >
                    <AssignmentIcon fontSize="2rem" />
                  </ButtonAppendField>

                  <ButtonAppendField
                    tooltip={i18next.t('buildForm.tooltip', { type: `Email` })}
                    question={'Email'}
                    type={'email'}
                    fields={fields}
                  >
                    <EmailIcon fontSize="2rem" />
                  </ButtonAppendField>

                  <ButtonAppendField
                    tooltip={i18next.t('buildForm.tooltip', { type: `Date` })}
                    question={'Date'}
                    type={'date'}
                    fields={fields}
                  >
                    <CalendarMonthIcon fontSize="2rem" />
                  </ButtonAppendField>

                  <ButtonAppendField
                    tooltip={i18next.t('buildForm.tooltip', { type: `Multiselect` })}
                    question={'Multiselect'}
                    type={'multiselect'}
                    fields={fields}
                  >
                    <RuleIcon fontSize="2rem" />
                  </ButtonAppendField>

                  <ButtonAppendField
                    tooltip={i18next.t('buildForm.tooltip', { type: `Combobox` })}
                    question={'Combobox'}
                    type={'combobox'}
                    fields={fields}
                  >
                    <FactCheckIcon fontSize="2rem" />
                  </ButtonAppendField>
                </Box>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  {/* {arrPropertiesForm.length>0 && } */}
                  {fields.map((item, index) => {
                    if (item.type === 'textboxmultiline') {
                      return <Textbox item={item} index={index} key={item.id} />;
                    }
                    if (item.type === 'textbox') {
                      return <Textbox item={item} index={index} key={item.id} />;
                    }
                    if (item.type === 'date') {
                      return <Date item={item} index={index} key={item.id} />;
                    }
                    if (item.type === 'multiselect') {
                      return <ComboBox item={item} index={index} key={item.id} />;
                    }
                    if (item.type === 'email') {
                      return <Textbox item={item} index={index} key={item.id} />;
                    }
                    if (item.type === 'combobox') {
                      return <ComboBox item={item} index={index} key={item.id} />;
                    }
                  })}
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', marginTop: '1rem' }}>
                  {/* <Button variant="contained" onClick={handleClose}> */}
                  <Button variant="contained" onClick={handleClose}>
                    {i18next.t('flow-Modals.exit')}
                  </Button>
                  <Button variant="contained" type="submit" color="success" >
                    {i18next.t('flow-Modals.confirm-button')}
                  </Button>
                </Box>
              </form>
            </DialogContentStyled>
          </>
        </Dialog>
      )}
    </Box>
  );
}
