import React, { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Icon } from '@iconify/react';
import tagsIcon from '@iconify/icons-eva/pricetags-outline';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, styled, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';
import { NodeSelect } from '../BasicModal';
import api from '../../../../../service/api';

const TextFieldWithPositionedErrors = styled(TextField)(() => ({
  '.css-16d5wub-MuiFormHelperText-root.Mui-error': { position: 'absolute', top: 15, right: 20 },
}));

const TagBox = styled(Box)(() => ({
  height: '1.3rem',
  width: 50,
  marginLeft: 5,
  borderRadius: 16,
  boxShadow: '2px 1px 8px #808080',
  minWidth: 'max-content',
}));

export default function ModalTag() {
  const nodeSelect = useContext(NodeSelect);
  const { nodeSelectCheck, idprojeto } = nodeSelect;
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [projectTag, setProjectTag] = useState({
    idtag: null,
    tagnome: '',
    tagcolor: '',
    tagstatus: null,
    tagtipo: 'Tag',
  });
  const { t } = useTranslation();

  useEffect(() => {
    requestTags();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    nodeSelectCheck.properties = { TAG: { tag: projectTag } };
    integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
    setOpen(false);
  };

  const requestTags = async () => {
    try {
      const tagsResponse = await api.get(`/tag`);
      setTags(tagsResponse.data.data.tags);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  const autocompleteTagInputHandler = (inputValue) => {
    if (inputValue !== null) {
      setProjectTag({
        tagnome: inputValue.label,
        idtag: inputValue.idtag,
        tagcolor: inputValue.tagcolor,
        tagstatus: inputValue.tagstatus,
        tagtipo: inputValue.tagtipo,
      });
    } else {
      setProjectTag({
        tagnome: '',
        idtag: null,
        tagcolor: '',
        tagstatus: null,
        tagtipo: 'Tag',
      });
    }
  };

  const transformedTags = tags
    .filter((tag) => tag.tagtipo === 'Tag')
    .map((tag) => ({
      label: tag.tagnome,
      idtag: tag.idtag,
      tagcolor: tag.tagcolor,
      tagstatus: tag.tagstatus,
      tagtipo: tag.tagtipo,
    }));

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-tags.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={tagsIcon} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog open={open} maxWidth="1200px" fullWidth sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}>
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('flow-Modals-tags.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{i18next.t('flow-Modals-tags.text')}</DialogContentText>
            <Autocomplete
              onChange={(_, value) => autocompleteTagInputHandler(value)}
              noOptionsText={t('products.modals.add-products.no-tags')}
              id="combo-box-demo"
              options={transformedTags}
              value={projectTag.tagnome || null}
              isOptionEqualToValue={(option, value) => option.label === value}
              renderInput={(params) => {
                let selectedTag;
                if (params.inputProps.value !== '') {
                  [selectedTag] = tags.filter((tag) => tag.tagnome === params.inputProps.value);
                }
                return (
                  <Box sx={{ position: 'relative' }}>
                    <TextFieldWithPositionedErrors {...params} label="Tag" />

                    {tags.map((tag) => {
                      if (params.inputProps.value === tag.tagnome) {
                        return (
                          <TagBox
                            key={tag.idtag}
                            sx={{
                              backgroundColor: selectedTag?.tagcolor,
                              marginLeft: '12px',
                              alignItems: 'center',
                              position: 'absolute',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              paddingX: 1,
                              paddingY: 0.5,
                              borderRadius: '16px',
                              display: 'flex',
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: 'center',
                                color: '#fff',
                                textShadow: '1px 1px 1px #000',
                              }}
                            >
                              {selectedTag.tagnome}
                            </Typography>
                          </TagBox>
                        );
                      }
                      return null;
                    })}
                  </Box>
                );
              }}
              renderOption={(props, option) => (
                <li key={option.idtag} {...props}>
                  <Box style={{ marginRight: 8 }}>
                    <TagBox
                      sx={{
                        backgroundColor: option.tagcolor,
                        marginLeft: '15px',
                        alignItems: 'center',
                        paddingX: 1,
                        paddingY: 0.5,
                        borderRadius: '16px',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          color: '#fff',
                          textShadow: '1px 1px 1px #000',
                        }}
                      >
                        {option.label}
                      </Typography>
                    </TagBox>
                  </Box>
                </li>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button variant="contained" onClick={handleSend} color="success">
              {i18next.t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
