/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext, useEffect } from 'react';
import i18next from 'i18next';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import ConnectAreasAndUsers from '../../../../ConnectAreasAndUsers';
import doc from '../../../assets/doc-text.svg';
import api from '../../../../../service/api';
import { NodeSelectTimeline } from '../BasicModalTimeline';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const drop = {
  border: '1px solid #eaeaea',
  borderRadius: '5px',
  width: '100%',
  padding: '2rem',
  margin: ' 5% auto',
};

export default function DropZone({ handleClose }) {
  const nodeSelectTimeline = useContext(NodeSelectTimeline);
  const { node } = nodeSelectTimeline;
  const { idprojeto, project } = nodeSelectTimeline;
  const [files, setFiles] = useState([]);
  const [thumbs, setThumbs] = useState([]);
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasUsers, setAreasUsers] = useState(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      renderThumbs(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple: true,
  });

  const getAreasAndUsers = async () => {
    setAreas(project.areausuario.area);
    setUsers(project.areausuario.usuario);
  };

  useEffect(() => {
    getAreasAndUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = () => {
    files.forEach(async (file) => {
      const formData = new FormData();
      formData.append('id', node.id);
      formData.append('idprojeto', idprojeto);
      formData.append('privacidade', areasUsers);
      formData.append('moduleanexo', file);
      try {
        if (file.name.length > 100) toast.error(`${t('toast-bigNameFile')}`);
        else {
          const response = await api.post(`/modulesanexo/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          if (response.status === 200 || response.status === 201) {
            toast.success(`${t('flow-Modals-uploadFile.sendSuccess')}`);
          }
          queryClient.invalidateQueries(['projectAnnex', idprojeto]);
          handleClose();
        }
      } catch (e) {
        console.log(e);
        toast.error(`${t('flow-Modals-uploadFile.sendFailed')}`);
        handleClose();
      }
    });
  };

  const renderThumbs = (files) => {
    const thumbsTmp = files.map((file) => {

      const regex = /image/g;
      const imageOrDoc = regex.test(file.type);
      return (
        <Tooltip
          title={file.name}
          key={file.name}
        >
          <div>
            <div style={thumb}>
              <div style={thumbInner}>
                <img
                  src={imageOrDoc === true ? file.preview : doc}
                  style={img}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
              </div>
            </div>
            <p>{file.name.slice(0, 10)}...</p>
          </div>
        </Tooltip>
      );
    });
    setThumbs(thumbsTmp);
  };

  return (
    <>
      <section className="container" style={drop}>
        <div style={{ padding: '2rem' }} {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>{i18next.t('flow-Modals-uploadFile.dropText')}</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
        <ConnectAreasAndUsers
          areas={areas}
          selectedAreas={selectedAreas}
          setSelectedAreas={setSelectedAreas}
          users={users}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          setAreasUsersData={setAreasUsers}
          style={{ marginTop: 3 }}
          label="Privacidade"
          privacidade
        />
      </section>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {i18next.t('flow-Modals.exit')}
        </Button>
        <Button variant="contained" onClick={handleChange} color="success">
          {i18next.t('flow-Modals-uploadFile.send')}
        </Button>
      </DialogActions>
    </>
  );
}

<DropZone />;
