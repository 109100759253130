import React, { useEffect } from 'react';
import { Box, LinearProgress } from '@mui/material/';

const Redirect = (props) => {
  const { url } = props;
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" />
    </Box>
  );
};

export default Redirect;
