// material
import { Stack, Button, Divider, Typography } from '@mui/material';
// component
import { Icon } from '@iconify/react';
import microsoftIcon from '@iconify/icons-mdi/microsoft';
// ----------------------------------------------------------------------

export default function AuthSocial() {
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" sx={{ alignItems: 'center' }}>
          <Icon icon={microsoftIcon} width={40} height={40} />
        </Button>
      </Stack>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OU
        </Typography>
      </Divider>
    </>
  );
}
