import { Box, Button, Card, CircularProgress, Divider, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
//
import { Icon } from '@iconify/react';
import arrowIcon from '@iconify/icons-eva/arrow-ios-forward-fill';
import MyJobsItems from '../../../components/MyJobsItems';

export default function MyJobs({ isLoading, jobs }) {
  const [maxJobsInCard, setMaxJobsInCard] = useState(3);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const down900pxHeight = useMediaQuery('(max-height: 900px)');
  const down800pxHeight = useMediaQuery('(max-height: 800px)');
  const down666pxHeight = useMediaQuery('(max-height: 666px)');

  useEffect(() => {
    if (down900pxHeight) setMaxJobsInCard(2);
    if (down800pxHeight) setMaxJobsInCard(1);
    if (!down900pxHeight && !down800pxHeight) setMaxJobsInCard(3);
  }, [down900pxHeight, down800pxHeight]);

  return (
    <Card
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: down666pxHeight ? 205 : '100%',
        height: '100%',
      }}
    >
      {isLoading && !jobs && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '50%',
            transform: 'translate(50%, -50%)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && jobs && jobs.length > 0 && (
        <>
          <Typography variant="h6" sx={{ padding: '24px 24px 0' }}>
            {t('my-jobs.heading')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, padding: '24px' }}>
            {jobs.map((job, index) => {
              if (index > maxJobsInCard - 1) return null;
              return <MyJobsItems key={job.idmodulo} job={job} />;
            })}
          </Box>
          <Box sx={{ position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
            <Divider />
            <Box
              sx={{
                padding: down800pxHeight ? '5px' : '16px',
                textAlign: 'right',
                height: '100%',
              }}
            >
              <Button
                size="small"
                color="inherit"
                endIcon={<Icon icon={arrowIcon} />}
                onClick={() => navigate('/morejobs')}
              >
                {t('home.update.button')}
              </Button>
            </Box>
          </Box>
        </>
      )}
      {!isLoading && jobs && jobs.length <= 0 && (
        <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h4" color="primary" textAlign="center">
            {t('my-jobs.no-jobs')}
          </Typography>
        </Box>
      )}
    </Card>
  );
}
