import React, { useState } from 'react';
import i18next from 'i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import cloudUploadFill from '@iconify/icons-eva/cloud-upload-fill';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import DropZone from '../Dropzone';

export default function ModalAnexo() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-uploadFile.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50,  backgroundColor: 'background.neutral' , color: 'text.secondary'  }}
        >
          <Icon icon={cloudUploadFill} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog onClose={() => setOpen(false)} open={open} sx={{  width: { md: 900, xl: 1200 }, margin: '0 auto' }}>
        <DialogTitle>{i18next.t('types-modules.attachment')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{i18next.t('flow-Modals-uploadFile.title')}</DialogContentText>
          <DropZone handleClose={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
